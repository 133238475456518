import styled from 'styled-components';
import theme from '../../theme';

export const Wrapper = styled.div``;

export const HotPairsTitleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;

  span:first-child {
    font-size: 0.85rem;
    font-weight: bold;
    color: var(--color-text);
  }

  span:last-child {
    font-size: 0.75rem;
    color: var(--color-help-text);
    letter-spacing: 1px;
  }
`;

export const HotPairsTableWrapper = styled.div`
  [class='ant-table-wrapper'] {
    height: 128px;
    max-height: 128px;
    overflow: hidden;
    display: flex;
    align-items: center;
    width: 100%;

    & > div {
      width: 100%;
    }

    [class=*'ant-table-cell'] {
      padding: 0.5rem 0.25rem !important;
    }
  }

  &&& {
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td,
    .ant-table tfoot > tr > th,
    .ant-table tfoot > tr > td {
      border-bottom: 1px solid transparent;
      background-color: var(--color-background-accent);
      color: var(--color-accent-2);

      @media ${theme.mediaQueries.mobile} {
        font-size: 0.75rem;
      }
    }

    .ant-table-thead > tr > th {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: var(--color-primary);
      background-color: var(--color-background-accent);
    }

    .table-row-custom {
      &:hover {
        filter: brightness(95%);
        cursor: pointer;
      }
    }
  }
`;

export const HotPairsSwapsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.125rem;

  svg {
    width: 1.25em;
    height: 1.25rem;
    color: var(--color-text);
  }
`;
