import React from 'react';
import { Button, Empty } from 'antd';
import { BiChevronRight } from 'react-icons/bi';
import {
  BoxHeader,
  InsightsBox,
  InsightsWrapper,
} from '../../views/TopTokens/styles';

interface Props {
  visible?: boolean;
}

const MoreButton = () => (
  <Button type="link">
    More
    <BiChevronRight />
  </Button>
);

const ExchangeInsights: React.FC<Props> = ({ visible }) => {
  if (!visible) return null;

  return (
    <InsightsWrapper>
      <InsightsBox>
        <BoxHeader>
          <div>DEX 24h Trading Volume</div>
          <MoreButton />
        </BoxHeader>
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          imageStyle={{ height: 40 }}
          description="Show the 24h trading volume"
        />
      </InsightsBox>
      <InsightsBox>
        <BoxHeader>
          <div>
            <span>Defi Volume Dominance</span>
          </div>
          <MoreButton />
        </BoxHeader>
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          imageStyle={{ height: 40 }}
          description="Show the % dominance"
        />
      </InsightsBox>
      <InsightsBox>
        <BoxHeader>
          <div>Monthly DEX Visits</div>
          <MoreButton />
        </BoxHeader>
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          imageStyle={{ height: 40 }}
          description="No. of visits to DEX"
        />
      </InsightsBox>
      <InsightsBox>
        <BoxHeader>
          <div>Exchange Fun Fact #4</div>
          <MoreButton />
        </BoxHeader>
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          imageStyle={{ height: 40 }}
          description="Placeholder value"
        />
      </InsightsBox>
    </InsightsWrapper>
  );
};

export default ExchangeInsights;
