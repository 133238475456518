import React, { useContext } from 'react';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import Login from '../Login';
import { GlobalContext } from '../../context/globalState';
import { invoke } from '@tauri-apps/api';

import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Topbar from '../../components/Topbar';
import Main from '../../components/Main';
import ChartsView from '../ChartsView';

import 'antd/dist/antd.less';
import { ContentWrapper } from './styles';
import useMediaQuery from '../../hooks/useMediaQuery';
import { useDarkMode } from '../../hooks/useDarkMode';
import useYBugApi from '../../hooks/useYBugApi';
import TopTokens from '../TopTokens';
import CurrencyView from '../CurrencyView';
import Pools from '../Pools';
import InsightsView from '../InsightsView/InsightsView';
import Portfolio from '../Portfolio';
import NFT from '../NFT';
import Superswap from '../Superswap';

invoke('greet', { name: 'React' }).then((response) => {
  console.log(response);
});

const router = createBrowserRouter([
  {
    path: '/',
    // always redirect for Superswap demo
    element: <Navigate to="/swap" replace />,
    // element: (
    //   <>
    //     <Header />
    //     <ContentWrapper data-testid="appContainer">
    //       <Topbar />
    //       <Sidebar />
    //       <Main>
    //         <ChartsView />
    //       </Main>
    //     </ContentWrapper>
    //   </>
    // ),
    errorElement: <div>Something went wrong</div>,
  },
  {
    path: 'tokens/ranking',
    element: (
      <>
        <Header />
        <TopTokens />
      </>
    ),
  },
  // {
  //   path: 'tokens/winners-losers',
  //   element: (
  //     <>
  //       <Header />
  //       <WinnersLosers />
  //     </>
  //   ),
  // },
  // {
  //   path: 'tokens/trending',
  //   element: (
  //     <>
  //       <Header />
  //       <Trending />
  //     </>
  //   ),
  // },
  // {
  //   path: 'tokens/new',
  //   element: (
  //     <>
  //       <Header />
  //       <NewTokens />
  //     </>
  //   ),
  // },
  {
    path: 'tokens/token/:symbol',
    element: (
      <>
        <Header />
        <CurrencyView />
      </>
    ),
  },
  {
    path: '/portfolio',
    element: (
      <>
        <Header />
        <Portfolio />
      </>
    ),
  },
  {
    path: '/nft/:id',
    element: (
      <>
        <Header />
        <NFT />
      </>
    ),
  },
  {
    path: 'pools',
    element: (
      <>
        <Header />
        <Pools />
      </>
    ),
  },
  {
    path: 'insights',
    element: (
      <>
        <Header />
        <InsightsView />
      </>
    ),
  },
  {
    path: 'swap',
    element: (
      <>
        <Header />
        <Superswap />
      </>
    ),
  },
]);

const App: React.FC = () => {
  const { user } = useContext(GlobalContext);
  useMediaQuery();
  useYBugApi();
  useDarkMode();

  if (!user) {
    return <Login />;
  }

  return <RouterProvider router={router} />;
};

export default App;
