import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../context/globalState';
import { axios, getFetchInstance } from '../../shared/api';
import { SupportedChains } from '../../shared/constants';
import { useNavigate } from 'react-router-dom';

const _fetchTokenListData = async () => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_BASE_URL}/marketWatch/tokens`
  );

  return data || [];
};

const _fetchTokenDetailsData = async (symbol: string) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_BASE_URL}/marketWatch/tokenDetails/${symbol}`
  );

  return data || [];
};

const _fetchPriceData = async (symbol: string) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_BASE_URL}/marketWatch/price/${symbol}`
  );

  return data || [];
};
export const useFetchData = () => {
  const { selection, setPairsData, setDataIsLoading, setUser } =
    useContext(GlobalContext);
  const { minSwaps, chains } = selection;

  const fetchCollections = async (chain: SupportedChains) => {
    const url = `/api/${chain}/pairs/${minSwaps}`;

    const result = await getFetchInstance({ url, setUser }).catch((err) => {
      return { data: [] };
    });

    setPairsData(result.data, chain);
  };

  useEffect(() => {
    async function fetchAllData() {
      try {
        setDataIsLoading(true);
        await Promise.allSettled(chains.map(fetchCollections));
      } finally {
        setDataIsLoading(false);
      }
    }

    fetchAllData();
  }, [minSwaps, chains]);

  return {};
};

export const useFetchTokenListData = async () => {
  const { setTokensData, setTokensLoading, tokens } = useContext(GlobalContext);
  async function fetchTokenData() {
    setTokensLoading(true);

    try {
      const tokenList = await _fetchTokenListData();

      const extendedTokenList = tokenList.map((token: any) => {
        return {
          ...token,
          symbol: token.symbol || '',
          price: token.usd_price || 0,
          price24h: token.item24h?.token_close_price_all_chains || 0,
          volume24h: token.item24h?.token_volume_all_chains || 0,
          price7d: token.item7d?.token_close_price_all_chains || 0,
        };
      });

      setTokensData({ ...tokens, tokenList: extendedTokenList });
    } catch (err) {
      console.error('Error fetching tokenLstData: ', err);
      setTokensData([]);
    } finally {
      setTokensLoading(false);
    }
  }

  useEffect(() => {
    fetchTokenData();
  }, []);
};

export const useFetchTokenDetailsData = async (symbol: string) => {
  const { setTokensData, setTokensLoading, tokens } = useContext(GlobalContext);
  async function fetchTokenData(s: string) {
    setTokensLoading(true);

    try {
      const tokenDetails = await _fetchTokenDetailsData(s);

      setTokensData({ ...tokens, tokenPrices: tokenDetails });
    } catch (err) {
      console.error('Error fetching tokenLstData: ', err);
      setTokensData([]);
    } finally {
      setTokensLoading(false);
    }
  }

  useEffect(() => {
    fetchTokenData(symbol);
  }, [symbol]);
};

export const useFetchTokenPriceData = async (symbol: string) => {
  const { setTokensData, setTokensLoading, tokens } = useContext(GlobalContext);
  async function fetchTokenData() {
    setTokensLoading(true);

    try {
      const tokenPrices = await _fetchPriceData(symbol);
      setTokensData({ ...tokens, tokenPrices });
      return tokenPrices;
    } catch (err) {
      console.error('Error fetching tokenPriceData: ', err);
    } finally {
      setTokensLoading(false);
    }
  }

  useEffect(() => {
    fetchTokenData();
  }, [symbol]);
};

export const useFetchPoolsPageData = (poolAddress: string) => {
  const [poolsPageData, setPoolsPageData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchData = async (poolAddress: string, version?: string) => {
    const options = {
      chains: 'ETH',
      pool_address__in: poolAddress,
      from_block: 1,
      to_block: 'latest',
      format: 'jsonstream',
      version,
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/pools`,
      {
        params: options,
      }
    );

    return data;
  };

  useEffect(() => {
    fetchData(poolAddress).then(setPoolsPageData);
  }, [poolAddress]);

  return poolsPageData;
};

export const useFetchSingleToken = ({ state }: { state?: { data: any } }) => {
  const [token, setToken] = useState<any>({});
  const navigate = useNavigate();

  useEffect(() => {
    if (state?.data) {
      return setToken(state.data);
    } else {
      navigate('/tokens/ranking');
    }
  }, [state]);

  return token;
};
