import { useContext, useEffect } from 'react';
import { GlobalContext } from '../context/globalState';

export const useDarkMode = () => {
  const { darkMode, setDarkMode } = useContext(GlobalContext);

  // useEffect(() => {
  //   const isDarkMode = window.matchMedia(
  //     '(prefers-color-scheme: dark)'
  //   ).matches;

  //   setDarkMode(isDarkMode);
  // }, []);

  useEffect(() => {
    document.body.setAttribute('data-theme', darkMode ? 'dark' : 'light');
  }, [darkMode]);

  return { darkMode };
};
