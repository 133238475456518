import styled from 'styled-components';
import theme from '../../theme';
import bgImg from '../../assets/images/header-bg.png';
import { LoginButton } from '../../views/Login/styles';

export const Wrapper = styled.header`
  height: var(--header-height);
  width: 100%;
  z-index: 10;
  background: url(${bgImg}) no-repeat center center;
  background-size: cover;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  border-bottom: 0.5px solid var(--color-border);

  @media ${theme.mediaQueries.mobile} {
    height: var(--topbar-height);
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  max-height: var(--header-height);
  max-width: 100rem;
  margin: 0 auto;
  padding: 1rem;

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @media ${theme.mediaQueries.mobile} {
    max-width: 100vw;
  }
`;

export const Logo = styled.img`
  height: 2rem;
  width: auto;
  cursor: pointer;

  @media ${theme.mediaQueries.mobile} {
    height: 2.25rem;
  }
`;

export const HeaderButton = styled(LoginButton)`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 0.75rem;

  svg {
    font-size: 1.25rem;
  }

  & + & {
    margin-left: 1rem;
  }
  @media ${theme.mediaQueries.mobile} {
    margin-right: 1rem;
  }
`;

export const WalletConnectButton = styled<any>(HeaderButton)`
  width: 160px;
  min-width: 160px;
  justify-content: space-between;
  ${({ $connected }) => $connected && `padding: 6.4px 8px;`}
`;

export const IndicatorsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const ChainBlockExplorerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 9rem;

  & > svg,
  img {
    margin-right: 0.5rem;
  }

  &:first-child > svg,
  img {
    margin-right: 0.25rem;
  }

  .name {
    position: relative;
    top: 3px;
    right: -2px;

    font-size: 0.75rem;
    color: var(--color-white);
  }
`;

export const TabsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1.5rem;
  margin-left: 2rem;
  font-size: 1rem;
  cursor: pointer;
  position: relative;
  top: 2px;

  div {
    color: var(--color-white);
    opacity: 0.7;

    &:hover {
      opacity: 1;
    }
  }
`;
