import React, { useCallback, useEffect, useState } from 'react';
import {
  addEdge,
  Background,
  useNodesState,
  useEdgesState,
  ConnectionLineType,
} from 'reactflow';
import { ChartWrapper, ReactFlowStyled } from '../../styles';
import CustomNode from './CustomNode';
import axios, { AxiosResponse } from 'axios';
import dagre from 'dagre';
import { FetchDataProps, PathColorsProps } from '../..';
import CustomEdge from './CustomEdge';
import {
  NODE_HEIGHT,
  NODE_WIDTH,
  addColor,
  addMissingCustomEdgeLabelColors,
  calculateNextIndex,
  massageEdges,
} from './helpers';
import _, { pick } from 'lodash';
import { highlightPath, resetNodeStyles } from '../../helpers/highlightPath';

import 'reactflow/dist/style.css';
import { Edge, Node, TransformedData, UniqueRoutes } from './types';
import { getEnvironmentVariable } from '../../../../shared/helpers';

const nodeTypes = {
  custom: CustomNode,
};

const edgeTypes = {
  custom: CustomEdge,
};

interface Props {
  originNodes: FetchDataProps;
  pathColors: PathColorsProps[];
  setLoading: (loading: boolean) => void;
  setBestPathChartIsReady: (ready: boolean) => void;
  isMobile: boolean;
  show: boolean;
}

const BestPathChart: React.FC<Props> = ({
  originNodes,
  pathColors,
  setLoading,
  setBestPathChartIsReady,
  isMobile,
  show,
}) => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [data, setData] = useState<any>([]);
  const [selectedNode, setSelectedNode] = useState<Node>();
  const [customEdgeLabels, setCustomEdgeLabels] = useState<
    Record<Edge['id'], any>
  >({});

  const onConnect = useCallback((params: any) => {
    return setEdges((eds) => addEdge(params, eds));
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.post(
        `${getEnvironmentVariable('REACT_APP_BASE_URL')}/prices/sorted-data`,
        {
          amount_in_human: originNodes.amount.toString(),
          in_token: originNodes.address0,
          out_token: originNodes.address1,
          block_start: originNodes.startingBlock,
          block_end: originNodes.endingBlock,
          step: originNodes.step,
        },
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );

      return response.data;
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const processRoutes = async (
    unqieRoutes: UniqueRoutes
  ): Promise<UniqueRoutes> => {
    try {
      const response: AxiosResponse<UniqueRoutes> = await axios.post(
        `${getEnvironmentVariable('REACT_APP_BASE_URL')}/prices/process-routes`,
        unqieRoutes
      );
      return response.data;
    } catch (error) {
      setLoading(false);
      console.log(error);
      throw new Error('Failed to process routes');
    }
  };

  function transformData(
    inputData: {
      exchange: string;
      price_out_formatted: number;
      direction: string;
      token0: string;
      token1: string;
      icon: string;
      color?: string;
    }[][],
    startEndData: {
      token0: string;
      token1: string;
      amount: number;
    }
  ): TransformedData {
    const nodes: Node[] = [];
    const edges: Edge[] = [];
    const nodeMap: { [key: string]: Node } = {};
    const g = new dagre.graphlib.Graph();
    // First, add an edgeMap to keep track of edges
    const edgeMap: { [key: string]: Edge } = {};

    g.setGraph({ rankdir: 'LR' });
    g.setDefaultEdgeLabel(() => ({}));

    const { token0: startNode, token1: endNode } = startEndData;

    const bestPath = inputData.sort((a, b) => {
      const aPrice = a[a.length - 1].price_out_formatted;
      const bPrice = b[b.length - 1].price_out_formatted;
      return bPrice - aPrice;
    })[0];

    addColor(inputData, pathColors);

    const bestPathRoutes = bestPath
      ? bestPath.map(({ exchange }) => exchange)
      : [];

    inputData.forEach((exchanges: any, exchangeIndex: number) => {
      [
        {
          exchange: `${startNode}`,
          price_out_formatted: originNodes.amount,
          direction: 'Sell',
        },
        ...exchanges,
        {
          exchange: `${endNode}`,
          price_out_formatted:
            bestPath[bestPath.length - 1].price_out_formatted,
          direction: 'Buy',
        },
      ].forEach((exchange_pair: any, i: number) => {
        let {
          exchange,
          price_out_formatted,
          direction,
          token0,
          token0LogoURI,
          token1,
          token1LogoURI,
          icon,
          color,
        } = exchange_pair;
        let reverseRouteName: string = '';

        // Create correct labels for nodes
        if (direction === 'Sell' && token0 && token1) {
          reverseRouteName = token1 + '/' + token0;
        } else if (direction === 'Buy' && token0 && token1) {
          reverseRouteName = token0 + '/' + token1;
        }

        // Increase the complexity of id if not start/end node
        let nodeId = exchange;
        if (token0 && token1) {
          nodeId = `${token0}-${token1}`;
        }

        // add extra source/target handles
        if (nodeMap[nodeId]) {
          const nextIndex = calculateNextIndex(nodeMap[nodeId], exchange);
          nodeMap[nodeId].data.handleIds.push(`${exchange}-${nextIndex}`);
        }

        // * Create Nodes
        if (!nodeMap[nodeId]) {
          const node: Node = {
            id: nodeId,
            type: 'custom',
            sourcePosition: 'right',
            targetPosition: 'left',
            data: {
              name: reverseRouteName || exchange,
              icon,
              handleIds: [`${exchange}-0`],
            },
            position: { x: 0, y: 0 },
            selected: bestPathRoutes.includes(exchange),
          };
          nodes.push(node);
          nodeMap[nodeId] = node;
          g.setNode(nodeId, {
            label: exchange,
            width: NODE_WIDTH,
            height: NODE_HEIGHT,
          });
        }

        // Decide on source and target based on direction
        let sourceExchange = nodeId;
        let targetExchange = exchanges[i]
          ? `${exchanges[i]?.token0}-${exchanges[i]?.token1}`
          : endNode;

        // No edge if its the last node
        if (sourceExchange === endNode) {
          return;
        }

        // * Create Edges
        const edgeId = `${exchange}-${sourceExchange}-${targetExchange}-${exchangeIndex}`;
        const nextIndexSource = calculateNextIndex(
          nodeMap[sourceExchange],
          exchange,
          true
        );
        const nextIndexTarget = calculateNextIndex(
          nodeMap[targetExchange],
          exchanges[i]?.exchange || endNode,
          !endNode
        );

        const sourceHandle = `${exchange}-${nextIndexSource}`;
        const targetHandle = exchanges[i]?.exchange
          ? `${exchanges[i]?.exchange}-${nextIndexTarget}`
          : `${endNode}-${nextIndexTarget}`;

        const edgeData = {
          name: direction !== 'Sell' ? token1 : token0 || exchange,
          icon: direction !== 'Sell' ? token1LogoURI : token0LogoURI,
          price: price_out_formatted,
          noData: false,
          edgeId,
          sourceHandle,
          targetHandle,
          source: sourceExchange,
          target: targetExchange,
          color,
        };

        // Start node doesn't carry an icon, so it can pull it from next one's source
        if (!edgeData.icon && edgeData.source === startNode) {
          edgeData.icon =
            exchanges[i]?.direction === 'Sell'
              ? exchanges[i]?.token1LogoURI
              : exchanges[i]?.token0LogoURI;
        }

        const customEdgeLabelData = pick(edgeData, [
          'name',
          'icon',
          'price',
          'edgeId',
          'color',
          'source',
          'target',
        ]);

        if (!_.isEqual(customEdgeLabels[edgeId], customEdgeLabelData)) {
          setCustomEdgeLabels((prev) => ({
            ...prev,
            [edgeId]: customEdgeLabelData,
          }));
        }

        const edge = {
          id: edgeId,
          type: 'custom',
          source: sourceExchange,
          target: targetExchange,
          sourceHandle,
          targetHandle,
          animated: true,
          style: { stroke: color },
          data: edgeData.noData
            ? ({} as Edge['data'])
            : { ...edgeData, customEdgeLabels },
        };

        edgeMap[edgeId] = edge;
        edges.push(edge);

        // Set edge with lower weight if it starts from the startNode, higher weight if it ends at endNode
        let weight = 1;
        if (sourceExchange === startNode) {
          weight = 0.1;
        } else if (targetExchange === endNode) {
          weight = 10;
        }
        g.setEdge(sourceExchange, targetExchange, { weight, data: edgeData });
      });
    });

    dagre.layout(g);

    // add positions to nodes
    g.nodes().forEach((nodeId) => {
      const node = nodeMap[nodeId];
      const nodeInfo = g.node(nodeId);
      node.position = {
        x: nodeInfo.x - NODE_WIDTH / 2,
        y: nodeInfo.y - NODE_HEIGHT / 2,
      };
    });

    // removed target/source from start/end node
    if (nodes.length > 0) {
      nodes.forEach((node) => {
        if (node.id === startNode) {
          node.data.noTarget = true;
        } else if (node.id === endNode) {
          node.data.noSource = true;
        }
      });
    }

    let dagreEdges = g.edges().map((e: any, i: number) => {
      const selected =
        bestPathRoutes.includes(e.v) || bestPathRoutes.includes(e.w);
      const { points, data } = g.edge(e);

      return {
        id: data.edgeId,
        type: 'custom',
        points,
        data: data.noData ? ({} as Edge['data']) : data,
        source: e.v,
        target: e.w,
        sourceHandle: data.sourceHandle,
        targetHandle: data.targetHandle,
        style: edges[i].style,
        animated: true,
        // selected,
      } as Edge;
    });

    // various final updates to the edges
    let mergedEdges = massageEdges({
      dagreEdges,
      edges,
      nodes,
      startNode,
      endNode,
    });

    mergedEdges = addMissingCustomEdgeLabelColors({
      customEdgeLabels,
      setCustomEdgeLabels,
      edges: mergedEdges,
    });

    return { nodes, edges: mergedEdges };
  }

  async function sendTransformNodesRequest(data: Node[]) {
    try {
      const response = await axios.post(
        `${getEnvironmentVariable(
          'REACT_APP_BASE_URL'
        )}/prices/transform-nodes`,
        data
      );
      return response.data;
    } catch (error) {
      setLoading(false);
      console.error('Failed to send request:', error);
      return null;
    }
  }

  useEffect(() => {
    fetchData()
      .then(processRoutes)
      .then(async (processedRoutes) => {
        const dagData = Object.entries(processedRoutes);
        const [, lastBlockData] = dagData[dagData.length - 1];
        setData(lastBlockData);
      })
      .catch((error) => {
        console.error('Data processing failed:', error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    const { nodes, edges } = transformData(data, originNodes);

    sendTransformNodesRequest(nodes)
      .then((nodes) => {
        setNodes(nodes);
        setEdges(edges);
        setBestPathChartIsReady(true);
      })
      .catch((error) => {
        console.error('Failed to send transform nodes request:', error);
        setLoading(false);
      });
  }, [data, pathColors, originNodes, edges.length]);

  if (!pathColors.length) return null;

  return (
    <ChartWrapper style={{ display: show ? 'grid' : 'none' }}>
      <h2>Possible swap routes</h2>
      <ReactFlowStyled
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        // onSelectionChange={(selectedElements) => {
        //   const node = selectedElements.nodes[0];
        //   setSelectedNode(node);
        //   highlightPath(setNodes, node, nodes, edges, true);
        // }}
        // onPaneClick={() => {
        //   resetNodeStyles(setNodes);
        //   setSelectedNode(undefined);
        // }}
        // onNodeMouseEnter={(_event, node) =>
        //   !selectedNode &&
        //   highlightPath(setNodes, node, [...nodes], [...edges], false)
        // }
        // onNodeMouseLeave={() => !selectedNode && resetNodeStyles(setNodes)}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        panOnDrag={isMobile}
        zoomOnScroll={isMobile}
        zoomOnPinch={isMobile}
        snapToGrid={true}
        zoomOnDoubleClick={false}
        connectionLineType={ConnectionLineType.SmoothStep}
        fitView
      >
        <Background color="#aaa" gap={16} />
      </ReactFlowStyled>
    </ChartWrapper>
  );
};

export default BestPathChart;
