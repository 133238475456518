import { ColumnsType, TableProps } from 'antd/lib/table';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '../../context/globalState';
import { currencyFormatter } from '../../shared/helpers';
import { Button } from 'antd';
import {
  TableName,
  TableWrapper,
  Title,
  Subtitle,
  TitleActions,
  TitleWrapper,
  Wrapper,
} from './styles';
import axios from 'axios';
import ExchangeFilters from '../../components/Filters/ExchangeFilters';
import ExchangeInsights from '../../components/Insights/ExchangeInsights';
import { useFetchPoolsPageData } from '../App/hooks';

interface DataType {
  key: React.Key;
  name: string;
  numCoins: number;
  numPairs: number;
  marketShare: number;
  volume: number;
}

const Pools: React.FC = () => {
  const { darkMode, isMobile } = useContext(GlobalContext);
  const [loading, setLoading] = useState(false);
  const [tooltipVisibleMCap, setTooltipVisibleMCap] = useState(false);
  const [tooltipVisibleCSupply, setTooltipVisibleCSupply] = useState(false);
  const [showFilters, setShowFilters] = useState(true);
  const [showInsights, setShowInsights] = useState(true);
  const navigate = useNavigate();

  const columns: ColumnsType<DataType> = [
    {
      title: '#',
      dataIndex: 'key',
      sorter: (a, b) => a.volume - b.volume,
      sortDirections: ['ascend', 'descend', 'ascend'],
      defaultSortOrder: 'descend',
      fixed: 'left',
      width: 45,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a, b) => (a.name > b.name ? 1 : -1),
      sortDirections: ['ascend', 'descend', 'ascend'],
      fixed: isMobile ? 'left' : false,
      width: isMobile ? 150 : undefined,
      render: (value, record) => {
        return (
          <TableName onClick={() => navigate(`/markets/${record.name}`)}>
            <span>{value}</span>
          </TableName>
        );
      },
    },
    {
      title: 'Volume (24h)',
      dataIndex: 'volume',
      sorter: (a, b) => a.volume - b.volume,
      sortDirections: ['ascend', 'descend', 'ascend'],
      align: 'right',
      render: (value) => currencyFormatter().format(value),
    },
    {
      title: 'Number of Coins',
      dataIndex: 'numCoins',
      sorter: (a, b) => a.numCoins - b.numCoins,
      sortDirections: ['ascend', 'descend', 'ascend'],
      align: 'right',
    },
    {
      title: 'Number of Pairs',
      dataIndex: 'numPairs',
      sorter: (a, b) => a.numPairs - b.numPairs,
      sortDirections: ['ascend', 'descend', 'ascend'],
      align: 'right',
    },
    {
      title: 'Market Share by Volume (%)',
      dataIndex: 'marketShare',
      sorter: (a, b) => a.marketShare - b.marketShare,
      sortDirections: ['ascend', 'descend', 'ascend'],
      align: 'right',
    },
  ];

  const data = [
    {
      key: '1',
      name: 'Binance',
      numCoins: 905,
      numPairs: 1600,
      marketShare: '29.6%',
      volume: 532417497.185,
    },
    {
      key: '2',
      name: 'Coinbase',
      numCoins: 601,
      numPairs: 2105,
      marketShare: '17.5%',
      volume: 213689857.399,
    },
    {
      key: '3',
      name: 'Uniswap (v3)',
      numCoins: 556,
      numPairs: 1521,
      marketShare: '5.1%',
      volume: 79092817.061,
    },
    {
      key: '4',
      name: 'Uniswap (v4)',
      numCoins: 863,
      numPairs: 1852,
      marketShare: '3.6%',
      volume: 51008414.379,
    },
  ];

  const onChange: TableProps<DataType>['onChange'] = (
    pagination,
    sorter,
    extra
  ) => {
    // console.log('params', pagination, sorter, extra);
  };

  const hideForDemo = true;

  return (
    <Wrapper>
      <TitleWrapper>
        <div>
          <Title>Pools</Title>
          <Subtitle>Ranking based on trading volume and liquidity</Subtitle>
        </div>
        <TitleActions>
          <Button size="large" onClick={() => setShowInsights(!showInsights)}>
            Insights
          </Button>
          <Button size="large" onClick={() => setShowFilters(!showFilters)}>
            Filters
          </Button>
        </TitleActions>
      </TitleWrapper>
      <ExchangeInsights visible={showInsights} />
      <ExchangeFilters visible={showFilters} />
      {hideForDemo ? (
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          fontSize: 48,
          marginTop: 48,
        }}>
          Coming Soon
        </div>
      ) : (
        <TableWrapper
          columns={columns}
          dataSource={data}
          onChange={onChange}
          rowClassName={'table-row-custom'}
          size={'small'}
          showSorterTooltip={!tooltipVisibleCSupply && !tooltipVisibleMCap}
          sorter={{}}
          {...(isMobile && { scroll: { x: 1300 } })}
        />
      )}
    </Wrapper>
  );
};

export default Pools;
