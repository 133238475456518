import React, { useEffect, useMemo, useState } from 'react';
import { Switch } from 'antd';
import { FiSun, FiMoon } from 'react-icons/fi';
import { Wrapper } from './styles';

interface Props {
  darkMode: boolean;
  setDarkMode: (darkMode: boolean) => void;
}

const DarkModeSwitch: React.FC<Props> = ({ darkMode, setDarkMode }) => {
  return useMemo(
    () => (
      <Wrapper title={`Dark Mode: ${darkMode ? 'On' : 'Off'}`}>
        <Switch
          checkedChildren={<FiMoon />}
          unCheckedChildren={<FiSun />}
          checked={darkMode}
          onChange={setDarkMode}
        />
      </Wrapper>
    ),
    [darkMode]
  );
};

export default DarkModeSwitch;
