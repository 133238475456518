import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  row-gap: 1rem;
  padding: 0 1rem;
  overflow-y: auto;
  height: calc(100vh - var(--header-height));
  margin: 0 auto;
  padding-bottom: 2rem;
  position: relative;
  max-width: 100rem;
  width: 100%;
  background-color: var(--color-background);
  padding: 0 1.5rem;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 60rem;
  min-height: 10rem;
  width: 100%;
  margin: 0 auto;
  margin-top: 1rem;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > div:first-child {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1rem;

    img {
      width: 2rem;
      height: 2rem;
    }
  }
`;

export const NFTMedia = styled.img`
  width: 25rem;
  height: 25rem;
  border-radius: 1rem;
  margin: 0 auto;
`;
