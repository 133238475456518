import React from 'react';
import { Collapse } from 'antd';
import { FAQButton, FAQWrapper, Title } from '../styles';
import { SUPERCHAIN_HOMEPAGE } from '../../../shared/constants';

const { Panel } = Collapse;

const goToWebsite = () => {
  window.open(SUPERCHAIN_HOMEPAGE, '_blank', 'noopener,noreferrer');
};

const faqData = [
  {
    key: '1',
    question: 'What is Superswap?',
    answer:
      'Superswap enables DeFi users to compare swap routes over time for any historical block range.',
  },
  {
    key: '2',
    question: 'How does it work?',
    answer:
      'Using an index of swap prices across DeFi, Superswap determines the best routes and charts their performance over time.',
  },
  {
    key: '3',
    question: 'Where is the data coming from?',
    answer: (
      <div>
        Superswap is powered by Superchain - The Open Index Protocol. Find out
        more{' '}
        <FAQButton type="link" onClick={goToWebsite}>
          [here]
        </FAQButton>
      </div>
    ),
  },
];

const FAQ: React.FC = () => {
  return (
    <FAQWrapper>
      <Title>FAQ</Title>
      <Collapse accordion bordered={false}>
        {faqData.map((faq) => (
          <Panel header={faq.question} key={faq.key}>
            <p>{faq.answer}</p>
          </Panel>
        ))}
      </Collapse>
    </FAQWrapper>
  );
};

export default FAQ;
