import React, { FC, memo, useEffect, useState } from 'react';
import {
  EdgeProps,
  getBezierPath,
  EdgeLabelRenderer,
  BaseEdge,
} from 'reactflow';
import { CustomNodeWrapper, EdgeLabelToken } from '../../styles';

const CustomEdge: FC<EdgeProps> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
  selected,
  style,
}) => {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
  });
  const [customEdgeLabel, setCustomEdgeLabel] = useState<EdgeProps['data']>([]);

  const { name, icon, customEdgeLabels } = data;

  useEffect(() => {
    if (data.source && data.target) {
      // find the custom edge label
      const customEdgeLabel = Object.values(customEdgeLabels).filter(
        (label: any) => {
          return label.source === data.source && label.target === data.target;
        }
      );
      setCustomEdgeLabel(customEdgeLabel);
    }
  }, [data.customEdgeLabels]);

  return (
    <>
      <BaseEdge id={id} path={edgePath} style={{ ...style, strokeWidth: 5 }} />
      {customEdgeLabel.length > 0 && (
        <EdgeLabelRenderer>
          <div
            style={{
              position: 'absolute',
              transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px) scale(0.8)`,
              background: '#fff60',
              borderRadius: 12,
              fontSize: 12,
              top: -12,
            }}
            className="nodrag nopan"
          >
            <CustomNodeWrapper>
              <div className="custom-edge-content">
                <EdgeLabelToken>
                  {icon && <img src={icon} alt={name} width={24} height={24} />}
                  <div>{name}</div>
                </EdgeLabelToken>
                <div>
                  <div className="price">
                    {customEdgeLabel.map((label: any) => (
                      <div key={label.edgeId} style={{ color: label.color }}>
                        {label.price.toFixed(2)}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </CustomNodeWrapper>
          </div>
        </EdgeLabelRenderer>
      )}
    </>
  );
};

export default memo(CustomEdge);
