import React from 'react';
import { chartNumberFormatter } from '../../shared/helpers';
import {
  PairBoxExtra,
  ResultsList,
  ResultsListTitle,
  ResultsListWrapper,
} from './styles';

import AVAXLogoSvg from '../../assets/icons/avax-logo.svg';
import BinanceLogoSvg from '../../assets/icons/binance-logo.svg';
import ETHLogoSvg from '../../assets/icons/eth-logo.svg';
import OPTLogoSvg from '../../assets/icons/opt-logo.svg';
import { Empty, Skeleton, Tooltip } from 'antd';
import { AiOutlinePlus } from 'react-icons/ai';
import { RiDeleteBin2Fill } from 'react-icons/ri';
import { ParsedPairList } from '.';

export const buttonIcon = {
  eth: (
    <img src={ETHLogoSvg} style={{ width: 32, height: 32 }} alt="ETH Logo" />
  ),
  avax: (
    <img src={AVAXLogoSvg} style={{ width: 32, height: 32 }} alt="AVAX Logo" />
  ),
  bsc: (
    <img
      src={BinanceLogoSvg}
      style={{ width: 32, height: 32 }}
      alt="Binance Logo"
    />
  ),
  opt: (
    <img
      src={OPTLogoSvg}
      style={{ width: 32, height: 32 }}
      alt="Optimism Logo"
    />
  ),
};

interface ResultListProps {
  resultList: ParsedPairList[];
  selectedList: ParsedPairList[];
  isDataLoading: boolean;
  selectPair: (chain: string, address: string) => void;
  removePair: (address: string) => void;
}

const ResultList: React.FC<ResultListProps> = ({
  resultList,
  isDataLoading,
  selectedList,
  selectPair,
  removePair,
}) => {
  return (
    <ResultsListWrapper>
      <div>
        <ResultsListTitle>Selected ({selectedList.length})</ResultsListTitle>
        <ResultsList>
          {isDataLoading ? (
            <Skeleton active paragraph={{ rows: 3 }} />
          ) : selectedList.length === 0 ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="Nothing selected. Select a pair bellow"
              style={{ margin: '0.5rem 2rem 0 2rem' }}
            />
          ) : (
            selectedList.map((swap, i) => (
              <li key={`${swap[0][0].address[0]}-${i}}`}>
                {buttonIcon[swap[2]]}
                {swap[0][1].symbol[0]}-{swap[0][2].symbol[0]} (
                {chartNumberFormatter(swap[1])})
                <Tooltip title="Remove pair">
                  <PairBoxExtra
                    className="select-pair-box"
                    onClick={() => removePair(swap[0][0].address[0])}
                  >
                    <RiDeleteBin2Fill />
                  </PairBoxExtra>
                </Tooltip>
              </li>
            ))
          )}
        </ResultsList>
      </div>
      <div>
        <ResultsListTitle>Results ({resultList.length})</ResultsListTitle>
        <ResultsList>
          {isDataLoading ? (
            <Skeleton active paragraph={{ rows: 48 }} />
          ) : resultList.length === 0 ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          ) : (
            resultList.map((swap, i) => (
              <li key={`${swap[0][0].address[0]}-${i}}`}>
                {buttonIcon[swap[2]]}
                <div>
                  {swap[0][1].symbol[0]}-{swap[0][2].symbol[0]} (
                  {chartNumberFormatter(swap[1])})
                </div>
                <Tooltip title="Select pair">
                  <PairBoxExtra
                    className="select-pair-box"
                    onClick={() => selectPair(swap[2], swap[0][0].address[0])}
                  >
                    <AiOutlinePlus />
                  </PairBoxExtra>
                </Tooltip>
              </li>
            ))
          )}
        </ResultsList>
      </div>
    </ResultsListWrapper>
  );
};

export default ResultList;
