import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ChainBlockExplorerWrapper,
  ContentWrapper,
  IndicatorsWrapper,
  Logo,
  HeaderButton,
  TabsWrapper,
  Wrapper,
  WalletConnectButton,
} from './styles';
import logoSrc from '../../assets/images/SC_Lockup_White_RGB.png';
import logoSrcMobile from '../../assets/images/SC_Logo_White_RGB.png';
import { GlobalContext } from '../../context/globalState';
import Burger from '../MobileSidebar/Burger';
import Menu from '../MobileSidebar';
import BlockNotification from '../BlockNotification';
import MobileBottomMenu from '../MobileBottomMenu';
import { buttonIcon } from '../Sidebar/ResultList';
import { AvailableChains, SupportedChains } from '../../shared/constants';
import { getChainNameFromShorthand } from '../../shared/helpers';
import DarkModeSwitch from '../DarkModeSwitch';
import loginWithMetamask from './loginWithMetamask';
import { AiOutlineDown, AiOutlineWallet } from 'react-icons/ai';
import { Dropdown, MenuProps, message } from 'antd';
import { ArrowDownOutlined, UserOutlined } from '@ant-design/icons';
import { MdOutlineAccountBalance } from 'react-icons/md';
import { FiCopy } from 'react-icons/fi';
import { RiShareBoxFill } from 'react-icons/ri';
import { BsBoxArrowLeft } from 'react-icons/bs';

const Header: React.FC = () => {
  const navigate = useNavigate();
  const {
    isMobile,
    setDarkMode,
    darkMode,
    setUser,
    user,
    setUserWalletAddress,
  } = useContext(GlobalContext);

  const onLogout = () => {
    setUser(null);
  };

  const onConnectWallet = async () => {
    const address = await loginWithMetamask();

    if (address) {
      setUserWalletAddress(address);

      navigate('/portfolio');
    }
  };

  const items: MenuProps['items'] = [
    {
      label: 'Go to Portfolio',
      key: '1',
      icon: <MdOutlineAccountBalance />,
      onClick: () => {
        navigate('/portfolio');
      },
    },
    {
      label: 'Copy Address',
      key: '2',
      icon: <FiCopy />,
      onClick: async () => {
        await navigator.clipboard.writeText(user?.walletAddress || '');
        message.success('Address copied to clipboard');
      },
    },
    {
      label: 'View in Explorer',
      key: '3',
      icon: <RiShareBoxFill />,
      onClick: () => {
        window.open(
          `https://etherscan.io/address/${user?.walletAddress}`,
          '_blank',
          'noopener noreferrer'
        );
      },
    },
    {
      label: 'Disconnect',
      key: '4',
      icon: <BsBoxArrowLeft />,
      onClick: () => {
        message.success(`Account disconnected!`);
        setUserWalletAddress('');
      },
    },
  ];

  return (
    <Wrapper data-theme={darkMode ? 'dark' : 'light'}>
      <ContentWrapper>
        <div>
          <Logo
            src={isMobile ? logoSrcMobile : logoSrc}
            alt="Superchain Logo"
            onClick={() => navigate('/')}
          />

          {!isMobile && (
            <TabsWrapper>
              <div onClick={() => navigate('/tokens/ranking')}>Tokens</div>
              {/* <div onClick={() => navigate('/pools')}>Pools</div> */}
              <div onClick={() => navigate('/swap')}>Superswap</div>
              <div onClick={() => navigate('/insights')}>Insights</div>
              {/* <div onClick={() => navigate('/trading')}>Trading Data</div> */}
            </TabsWrapper>
          )}
        </div>

        <IndicatorsWrapper>
          {!isMobile && (
            <>
              {Object.values(AvailableChains)
                // Only show ETH
                .filter((c) => c === 'eth')
                .map((c) => {
                  const chain = c as SupportedChains;

                  return (
                    <ChainBlockExplorerWrapper key={chain}>
                      {buttonIcon[chain]}
                      <div>
                        <div className="name">
                          {getChainNameFromShorthand(chain)}
                        </div>
                        <BlockNotification chain={chain} />
                      </div>
                    </ChainBlockExplorerWrapper>
                  );
                })}
            </>
          )}

          <DarkModeSwitch darkMode={darkMode} setDarkMode={setDarkMode} />
          {isMobile ? null : user?.walletAddress ? (
            <Dropdown
              menu={{ items, className: 'wallet-dropdown-menu' }}
              trigger={['click']}
            >
              <WalletConnectButton icon={<AiOutlineWallet />} $connected>
                {user?.walletAddress.slice(0, 6)}...
                {user?.walletAddress.slice(-4)}
                <AiOutlineDown />
              </WalletConnectButton>
            </Dropdown>
          ) : (
            <WalletConnectButton
              onClick={onConnectWallet}
              icon={<AiOutlineWallet />}
            >
              Connect Wallet
            </WalletConnectButton>
          )}
          <HeaderButton onClick={onLogout}>Logout</HeaderButton>

          {isMobile && (
            <>
              <Burger />
              <Menu />
              {/* <MobileBottomMenu /> */}
            </>
          )}
        </IndicatorsWrapper>
      </ContentWrapper>
    </Wrapper>
  );
};

export default Header;
