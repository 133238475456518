import _ from 'lodash';
import { PathColorsProps } from '../..';
import {
  Node,
  Edge,
  MassageEdgesProps,
  AddCustomEdgeLabelMissingColorsProps,
} from './types';

export const NODE_WIDTH = 200;
export const NODE_HEIGHT = 50;

export const calculateNextIndex = (
  node: Node,
  exchange: string,
  isHandle?: boolean
) => {
  let nextHandleIndex = 0;
  if (!node?.data) return 0;

  nextHandleIndex = node.data.handleIds.filter((handleId: string) =>
    handleId.startsWith(exchange)
  ).length;

  if (isHandle) {
    return nextHandleIndex - 1 < 0 ? 0 : nextHandleIndex - 1;
  }

  return nextHandleIndex;
};

export const addColor = (
  inputData: {
    exchange: string;
    price_out_formatted: number;
    direction: string;
    token0: string;
    token1: string;
    icon: string;
    color?: string;
  }[][],
  pathColors: PathColorsProps[]
): void => {
  pathColors.forEach((pathColor) => {
    inputData.forEach((inputs) => {
      const exchangeNames = inputs
        .map((exchange: { exchange: string }) => exchange.exchange)
        .join(',');

      if (exchangeNames === pathColor.name) {
        inputs.forEach((exchange: any) => {
          exchange.color = pathColor.color;
        });
      }
    });
  });
};

export const copyStrokeStyle = (
  edges: Edge[],
  startNode: string,
  endNode: string
): Edge[] => {
  // Create a map for quick access to edges by source and target
  const sourceMap = new Map<string, any>();
  const targetMap = new Map<string, any>();

  edges.forEach((edge) => {
    sourceMap.set(edge.sourceHandle, edge);
    targetMap.set(edge.targetHandle, edge);
  });

  // Loop through the edges again and copy the stroke style
  edges = edges.map((edge) => {
    if (!edge.style?.stroke) {
      const targetEdge = sourceMap.get(edge.targetHandle);
      const sourceEdge = targetMap.get(edge.sourceHandle);

      if (targetEdge?.style?.stroke) {
        edge.style = { ...edge.style, stroke: targetEdge.style.stroke };
      } else if (sourceEdge?.style?.stroke) {
        edge.style = { ...edge.style, stroke: sourceEdge.style.stroke };
      } else {
        edge.style = { ...edge.style, stroke: 'gray' };
      }
    }
    return edge;
  });

  // Loop through the edges again, propagating the stroke style back to startNode and forward to endNode
  edges.forEach((edge) => {
    // Propagate the stroke style back to startNode
    if (edge.target === endNode) {
      let prevEdge = targetMap.get(edge.sourceHandle);
      while (prevEdge) {
        prevEdge.style = { ...prevEdge.style, stroke: edge.style?.stroke };
        if (prevEdge.source !== startNode) {
          prevEdge = targetMap.get(prevEdge.sourceHandle);
        } else {
          break;
        }
      }
    }
  });

  return edges;
};

export const addMissingCustomEdgeLabelColors = ({
  customEdgeLabels,
  setCustomEdgeLabels,
  edges,
}: AddCustomEdgeLabelMissingColorsProps) => {
  const customEdgeLabelsWithoutColor = Object.values(customEdgeLabels).filter(
    (label: any) => !label.color
  );

  if (customEdgeLabelsWithoutColor.length > 0) {
    const newData = { ...customEdgeLabels };
    customEdgeLabelsWithoutColor.forEach((label: any) => {
      newData[label.edgeId] = {
        ...newData[label.edgeId],
        color: edges.find((edge) => edge.id === label.edgeId)?.style?.stroke,
      };

      setCustomEdgeLabels(newData);
      edges = edges.map((edge) => {
        return {
          ...edge,
          data: {
            ...edge.data,
            customEdgeLabels: newData,
          },
        } as Edge;
      });
    });
  }

  return edges;
};

export const massageEdges = ({
  dagreEdges,
  edges,
  nodes,
  startNode,
  endNode,
}: MassageEdgesProps): Edge[] => {
  // add missing edges to Dagre edges
  let allEdgesMerged = _.values(
    _.merge(_.keyBy(dagreEdges, 'id'), _.keyBy(edges, 'id'))
  );

  // show edge label only for first handles
  allEdgesMerged = allEdgesMerged.map((edge) => {
    const { source, target, sourceHandle, targetHandle } = edge;

    const node = nodes.find((node) => node.id === target);
    const prevNode = nodes.find((node) => node.id === source);

    if (node?.data?.handleIds[0] !== targetHandle) {
      if (prevNode?.data?.handleIds[0] !== sourceHandle) {
        edge.data = {} as Edge['data'];
      } else {
        return edge;
      }

      edge.data = {} as Edge['data'];
    }

    return edge;
  });

  allEdgesMerged = copyStrokeStyle(allEdgesMerged, startNode, endNode);

  return allEdgesMerged;
};
