import styled, { css, keyframes } from 'styled-components';
import theme from '../../theme';

interface LiveBlockProps {
  $isLive: boolean;
  $isUpdated: boolean;
  $hasBlock: boolean;
}

const circleZoom = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(2); }
  100% { transform: scale(1); }
`;

export const LiveBlockWrapper = styled.div<LiveBlockProps>`
  height: 1.25rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.8rem;
  color: var(--color-antd-green);
  cursor: pointer;

  div:first-child {
    opacity: ${({ $isUpdated }) => ($isUpdated ? 1 : 0.7)};
    transition: opacity 0.25s ease 0s;

    &:hover {
      opacity: 1;
    }
  }

  div:last-child {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 0.5rem;
    margin-left: ${({ $hasBlock }) => ($hasBlock ? '0.5rem' : '0.125rem')};
    position: relative;
    bottom: 1px;
    background-color: ${({ $isLive }) =>
      $isLive ? theme.colors['antd-green'] : theme.colors.gray};
    animation: ${({ $isUpdated, $isLive }) =>
      $isUpdated && $isLive
        ? css`
            ${circleZoom} 1s 1 ease-in-out
          `
        : 'none'};
  }
`;
