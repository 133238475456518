import { open } from '@tauri-apps/api/shell';
import { SupportedChains } from './constants';
import packageJSON from '../../package.json';
import {
  APP_VERSION_LOCAL_STORAGE_KEY,
  clearLocalStorage,
  getLocalStorage,
  setLocalStorage,
  STATE_LOCAL_STORAGE_KEY,
  STATE_LOCAL_STORAGE_KEY_AUTH,
} from '../context/localStorage';
import { IAuth } from './api';

export interface Error {
  status?: number;
  message?: string;
  response: {
    data: string;
    statusText: string;
  };
}

export type Maybe<T> = T | null | undefined;

export const parseErrorMessage = (err: Error) => {
  return err.response.data || err.message || 'There was an error';
};

export const getWebsocketUrl = (chain: SupportedChains) =>
  `wss://partners.superchain.app/api/${chain}/ws-proto`;

export const getScanUrl = (
  chain: SupportedChains,
  address: string,
  type: 'tx' | 'address' = 'tx'
) => {
  switch (chain) {
    case SupportedChains.ETH:
      return `https://etherscan.io/${type}/${address}`;
    case SupportedChains.AVAX:
      return `https://avascan.info/blockchain/c/${type}/${address}`;
    case SupportedChains.BSC:
      return `https://bscscan.com/${type}/${address}`;
    case SupportedChains.OPT:
      return `https://optimistic.etherscan.io/${type}/${address}`;
  }
};

export const getBlockNumberUrl = (
  chain: SupportedChains,
  blockNumber: number
) => {
  switch (chain) {
    case SupportedChains.ETH:
      return `https://etherscan.io/block/${blockNumber}`;
    case SupportedChains.AVAX:
      return `https://avascan.info/blockchain/c/block/${blockNumber}`;
    case SupportedChains.BSC:
      return `https://bscscan.com/block/${blockNumber}`;
    case SupportedChains.OPT:
      return `https://optimistic.etherscan.io/batch/${blockNumber}`;
  }
};

export function calculateNumberOfDecimalPlaces(value: number, symbol?: string) {
  if (!value) return 0;

  if (
    value < 10e-9 &&
    symbol &&
    ['ETH', 'WETH', 'BTC', 'WBTC'].includes(symbol)
  ) {
    value = value * 1e9;
  }

  let mag = -Math.floor(Math.log10(value) + 1);
  mag = mag < 0 ? 2 : mag + 4;
  let valueParsed =
    value === 0 ? value : Math.round(value * 10 ** mag) / 10 ** mag;

  if (valueParsed > 1) {
    return formatNumberToDecimal(valueParsed);
  }

  return valueParsed;
}

export function chartNumberFormatter(num: number, symbol?: string) {
  if (num <= 0) return num;

  if (
    num < 10e-9 &&
    symbol &&
    ['ETH', 'WETH', 'BTC', 'WBTC'].includes(symbol)
  ) {
    num = num * 1e9;
  }

  if (num > 0 && num < 1) {
    let mag = -Math.floor(Math.log10(num) + 1);
    mag = mag < 0 ? 2 : mag + 4;

    return Math.round(num * 10 ** mag) / 10 ** mag;
  }
  if (num < 999) return num.toFixed(2);
  if (num <= 999999) {
    return (num / 1000).toFixed(2) + 'k';
  }
  if (num <= 999999999) {
    return (num / 1000000).toFixed(2) + 'M';
  }
  if (num <= 999999999999) {
    return (num / 1000000000).toFixed(2) + 'B';
  }
  return (num / 1000000000000).toFixed(2) + 'T';
}

export function formatNumberToDecimal(number: number, decimal: number = 2) {
  const maximumFractionDigits = number > 1 ? 2 : decimal;

  const formatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits,
  });

  return formatter.format(number);
}

export function getCoinGeckoAPIUrl(chain: SupportedChains, address: string) {
  switch (chain) {
    case SupportedChains.ETH:
      return `https://api.coingecko.com/api/v3/simple/token_price/ethereum?contract_addresses=${address}&vs_currencies=usd`;
    case SupportedChains.AVAX:
      return `https://api.coingecko.com/api/v3/simple/token_price/avalanche?contract_addresses=${address}&vs_currencies=usd`;
    case SupportedChains.BSC:
      return `https://api.coingecko.com/api/v3/simple/token_price/binance?contract_addresses=${address}&vs_currencies=usd`;
    case SupportedChains.OPT:
      return `https://api.coingecko.com/api/v3/simple/token_price/optimistic-ethereum?contract_addresses=${address}&vs_currencies=usd`;
  }
}

export function getCoinGeckoTokenUrl(address: string) {
  return `https://www.coingecko.com/en/coins/${address}`;
}

export function renderFractionUnitOfETHOrBTC(value: number, symbol: string) {
  if (value < 10e-9 && ['ETH', 'WETH'].includes(symbol)) {
    return 'GWEI';
  }
  if (value < 10e-9 && ['BTC', 'WBTC'].includes(symbol)) {
    return 'SATS';
  }
  return symbol;
}

export const checkAppVersion = () => {
  const appVersion = packageJSON.version;
  const storedVersion = getLocalStorage(APP_VERSION_LOCAL_STORAGE_KEY, '');

  if (appVersion !== storedVersion) {
    clearLocalStorage(STATE_LOCAL_STORAGE_KEY);
    setLocalStorage(APP_VERSION_LOCAL_STORAGE_KEY, appVersion);

    window.location.reload();
  }
};

export const getChainNameFromShorthand = (chain: SupportedChains) => {
  switch (chain) {
    case SupportedChains.ETH:
      return 'Ethereum';
    case SupportedChains.BSC:
      return 'Binance';
    case SupportedChains.OPT:
      return 'Optimism';
    case SupportedChains.AVAX:
      return 'Avalanche';
    default:
      return '';
  }
};

export const saveUserToLocalStorage = (user: IAuth | null) => {
  // setLocalStorage(STATE_LOCAL_STORAGE_KEY_AUTH, user);
  setLocalStorage(STATE_LOCAL_STORAGE_KEY_AUTH, {});
};

export const getUser = () => {
  return getLocalStorage(STATE_LOCAL_STORAGE_KEY_AUTH, null);
};

export const IsTauri = () => {
  return Boolean(
    typeof window !== 'undefined' &&
      window !== undefined &&
      window.__TAURI_IPC__ !== undefined
  );
};

export const openExternalLink = (url: string) => {
  if (IsTauri()) {
    open(url);
  } else {
    window.open(url, '_blank', 'noopener, noreferrer');
  }
};

export const currencyFormatter = (options: any = {}) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 3,
    ...options,
  });

export const percentChange = (a: number, b: number) => {
  let percent;
  if (b !== 0) {
    if (a !== 0) {
      percent = ((b - a) / a) * 100;
    } else {
      percent = b * 100;
    }
  } else {
    percent = -a * 100;
  }
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'percent',
    maximumFractionDigits: 2,
  });

  return formatter.format(Math.abs(percent / 100));
};

export const getEnvironmentVariable = (key: string) => {
  if (IsTauri()) {
    // @ts-ignore
    return import.meta.env[`VITE_${key}`];
  }
  return process.env[key];
};

export const hex_to_final_corrected_Q64_96 = (hex_str: string) => {
  // Convert the entire hex string to its BigInt representation
  const full_value = BigInt(hex_str);

  // If the leading bit is 1, it's a negative number
  if (full_value >= BigInt(1) << BigInt(255)) {
    // Compute the positive magnitude of the number
    const magnitude = (BigInt(1) << BigInt(256)) - full_value;

    // Split into integer and fractional parts
    const int_value = magnitude >> BigInt(96);
    const frac_value = magnitude & ((BigInt(1) << BigInt(96)) - BigInt(1));

    // Subtract the fractional value from the integer value
    const final_value = int_value - frac_value;

    // Return the negative value
    return -final_value;
  } else {
    // If it's a positive number, simply return the value
    return full_value;
  }
};
