import { Button } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled<any>(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  svg {
    font-size: 1.25rem;
  }
`;
