import { Breadcrumb, Radio, Select } from 'antd';
import moment from 'moment';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import { Link, useLocation } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router-dom';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import Loader from '../../components/Loader';
import {
  ChartTimeFrames,
  ChartViewTypes,
  DataZoomProps,
  ParsedPairData,
  daysToFormat,
  timeFrameToDays,
} from '../../components/PairDetails';
import { GlobalContext } from '../../context/globalState';
import { currencyFormatter, percentChange } from '../../shared/helpers';
import Chart from './Chart';
import {
  Content,
  Header,
  LoadingOverlay,
  Price,
  PriceWrapper,
  Title,
  Wrapper,
  ExtraStatsBlock,
  ExtraStatsWrapper,
  StatsLabel,
  StatsValue,
  Tag,
  NavWrapper,
  HeaderNamePriceContainer,
  ChartContainer,
  ChartControls,
  PoolBar,
  PoolBarTitle,
  PoolOptionWrapper,
  PoolOptionTitle,
} from './styles';
import { useFetchTokenDetailsData } from '../App/hooks';
import SwapTable from '../../components/PairDetails/SwapTable';
import { SupportedChains } from '../../shared/constants';
import { groupBy, uniqBy } from 'lodash';
import ChartCandleStick from './ChartCandlestick';
import Icon from '../../components/Icon';

export interface SelectedLabels {
  Price: boolean;
}

interface Props { }

const CurrencyView: React.FunctionComponent<Props> = () => {
  const { symbol = '' } = useParams<{ symbol: string }>();
  const [loading, setLoading] = useState(false);
  const {
    darkMode,
    tokens: {
      data: { tokenPrices = [] },
    },
  } = useContext(GlobalContext);
  const [dataZoom, setDataZoom] = useState<DataZoomProps>({
    startValue: moment().subtract(1, 'minute').unix() * 1000,
    endValue: moment().unix() * 1000,
  });

  const [selectedLegends, setSelectedLegends] = useState<
    Partial<SelectedLabels>
  >({
    Price: true,
  });
  const [dateAxisLabelFormat, setDateAxisLabelFormat] = useState(
    daysToFormat(7)
  );
  const [chartTimeFrame, setChartTimeFrame] = useState<ChartTimeFrames>(
    ChartTimeFrames.TWELWE_HOURS
  );
  const { state } = useLocation();
  const [selectedPool, setSelectedPool] = useState<string>('all');
  const [wsConnected, setWsConnected] = useState(false);
  const [parsedData, setParsedData] = useState(state?.data?.rawData || []);
  const [tokenPricesData, setTokenPricesData] = useState<any[]>(tokenPrices);
  const [chartData, setChartData] = useState([]);
  const [groupedByPool, setGroupedByPool] = useState<any>({});
  const containerRef = useRef<HTMLDivElement>(null);
  const [offsetTop, setOffsetTop] = useState(0);

  /* Real-time events */
  const [liveWindowsData, setLiveWindowsData] = useState<any[]>([]);

  useFetchTokenDetailsData(symbol);

  const token = state?.data || {};

  useEffect(() => {
    const scrollableContainer = containerRef?.current;
    const handleScroll = () => {
      const offset = containerRef?.current?.scrollTop || 0;
      setOffsetTop(offset);
    };

    scrollableContainer?.addEventListener('scroll', handleScroll);

    return () => {
      scrollableContainer?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const dataPerPool =
      state?.data?.rawData?.filter((item: any) =>
        selectedPool === 'all' ? true : item.pool_address
      ) || [];

    setParsedData(dataPerPool);
  }, [selectedPool]);

  useEffect(() => {
    const chartData = parsedData?.map((token: any) => {
      const {
        pool_address,
        t0_tgt_price,
        t1_tgt_price,
        t0_tgt_volume,
        t1_tgt_volume,
        token0_symbol,
        transaction_hash,
        uniswap_version,
        time_stamp,
        block_number,
      } = token;

      const isInverted = token0_symbol !== symbol;

      return {
        price: isInverted ? t1_tgt_price : t0_tgt_price,
        volume: isInverted ? t1_tgt_volume : t0_tgt_volume,
        timestamp: time_stamp,
        tx: transaction_hash,
        block_number,
        pool: pool_address,
        uniswap_version,
      };
    });

    const chartDataUnique: any = uniqBy(chartData, 'tx');

    setChartData(chartDataUnique);
  }, [parsedData.length]);

  useEffect(() => {
    if (
      tokenPrices.length &&
      tokenPricesData[0]?.window_start_timestamp !==
      tokenPrices[0]?.window_start_timestamp
    ) {
      setTokenPricesData([...tokenPrices, ...liveWindowsData]);
    } else {
      setTokenPricesData([...tokenPricesData, ...liveWindowsData]);
    }
  }, [liveWindowsData, tokenPrices]);

  useEffect(() => {
    const groupedTransactions = groupBy(
      token?.rawData,
      (tx) => tx?.pool_address
    );

    setGroupedByPool(groupedTransactions);
  }, []);

  const value = percentChange(token.price24h, token.price);
  const isPositive = token.price > token.price24h;
  const icon = isPositive ? (
    <AiFillCaretUp size={12} />
  ) : (
    <AiFillCaretDown size={12} />
  );

  const onControlsClick = (e: any) => {
    const timeFrame = e.target.value;

    setChartTimeFrame(timeFrame);
    setZoomOnDaysChange(timeFrame);
  };

  const setZoomOnDaysChange = (timeFrame: string) => {
    const timeSpan = timeFrameToDays[timeFrame as keyof typeof timeFrameToDays];
    const lastElement = token.rawData[token.rawData.length - 1];
    const endValue = lastElement?.time_stamp || 0;
    let startValue = endValue;

    if (timeSpan < 1) {
      startValue = moment(endValue * 1000)
        .subtract(timeSpan * 24, 'hours')
        .unix();
    } else {
      startValue =
        moment(endValue * 1000)
          .subtract(timeSpan, 'days')
          .unix() || 0;
    }

    setDataZoom({
      startValue: startValue * 1000,
      endValue: endValue * 1000,
    });
  };

  const { lastMessage, readyState, sendJsonMessage, sendMessage } =
    useWebSocket(`${process.env.REACT_APP_SERVER_BASE_URL_WS}/connect`);

  useEffect(() => {
    if (lastMessage !== null) {
      const tx = JSON.parse(lastMessage.data);
      if ([tx.token_symbol].includes(symbol)) {
        console.log('NEW TRANSACTION FOR', symbol);
        console.log({ tx });
        setLiveWindowsData((prev) => [...prev, tx]);
      }
    }
  }, [lastMessage]);

  useEffect(() => {
    if (readyState === ReadyState.OPEN && !wsConnected) {
      console.log('CONNECTED ONE TIME');
      sendJsonMessage({
        subscribe: ['PricesData', 'WindowsData'],
      });
      setWsConnected(true);
    }

    return () => {
      if (readyState === ReadyState.OPEN && wsConnected) {
        sendMessage('unsubscribe');
        setWsConnected(false);
      }
    };
  }, [readyState, wsConnected]);

  return useMemo(
    () =>
      loading ? (
        <LoadingOverlay>
          <Loader size="large" />
        </LoadingOverlay>
      ) : (
        <Wrapper ref={containerRef}>
          <Header $isTop={offsetTop === 0}>
            <NavWrapper>
              <Breadcrumb.Item>
                <Link to="/">Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to="/tokens/ranking">Markets</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{token.name}</Breadcrumb.Item>
            </NavWrapper>
            <HeaderNamePriceContainer>
              <Title>
                <Icon
                  name={token.symbol}
                  size="large"
                  style={{ marginBottom: 4 }}
                />
                {token.name} <Tag>{token.symbol}</Tag>
              </Title>
              <PriceWrapper>
                <div>
                  {token.name} Price ({token.symbol})
                </div>
                <Price $isPositive={isPositive}>
                  {currencyFormatter().format(token.price)}
                  <Tag>
                    {icon} {value}
                  </Tag>
                </Price>
              </PriceWrapper>
            </HeaderNamePriceContainer>
          </Header>
          <ExtraStatsWrapper>
            <ExtraStatsBlock>
              <StatsLabel>
                Volume <Tag>24h</Tag>
              </StatsLabel>
              <StatsValue>
                {currencyFormatter().format(token.volume24h * token.price)}
              </StatsValue>
            </ExtraStatsBlock>
            <ExtraStatsBlock />
          </ExtraStatsWrapper>
          <Content>
            <PoolBar>
              <PoolBarTitle>Select Pool:</PoolBarTitle>
              <Select value={selectedPool} onChange={setSelectedPool}>
                <Select.Option value="all">All Pools</Select.Option>
                {Object.keys(groupedByPool).map((poolName) => (
                  <Select.Option value={poolName}>
                    <PoolOptionWrapper className="pool-option-wrapper">
                      <div>
                        <PoolOptionTitle className="pool-option-title">
                          Pool
                        </PoolOptionTitle>
                        <div>{poolName}</div>
                      </div>
                    </PoolOptionWrapper>
                  </Select.Option>
                ))}
              </Select>
            </PoolBar>
            <ChartContainer>
              <ChartControls>
                <Radio.Group
                  value={chartTimeFrame}
                  onChange={onControlsClick}
                  buttonStyle="solid"
                  disabled={false}
                >
                  <Radio.Button value={ChartTimeFrames.HOUR}>1HR</Radio.Button>
                  <Radio.Button value={ChartTimeFrames.TWELWE_HOURS}>
                    12HR
                  </Radio.Button>
                  <Radio.Button value={ChartTimeFrames.DAY}>1D</Radio.Button>
                  <Radio.Button value={ChartTimeFrames.WEEK}>1W</Radio.Button>
                </Radio.Group>
              </ChartControls>
              <Chart
                data={chartData.map((item: any) => ({
                  price: item.price,
                  timestamp: item.timestamp,
                }))}
                extraData={token}
                metadata={[]}
                dataZoom={dataZoom}
                dateAxisLabelFormat={dateAxisLabelFormat}
                priceInverted={false}
                ready={false}
                onChartClick={() => { }}
                onLegendSelect={({ name }) => {
                  setSelectedLegends({
                    Price: false,
                    [name]: true,
                  });
                }}
                selectedLegends={selectedLegends}
                mode={ChartViewTypes.CHART}
                isMobile={false}
                darkMode={darkMode}
              />
            </ChartContainer>
            <ChartContainer>
              <ChartCandleStick
                data={tokenPricesData}
                darkMode={darkMode}
                dataZoom={dataZoom}
                dateAxisLabelFormat={dateAxisLabelFormat}
              />
            </ChartContainer>
            <SwapTable
              data={
                (chartData.map((item: any) => ({
                  price: item.price,
                  priceInverted: 1 / item.price,
                  date: moment(item.timestamp * 1000).format('lll'),
                  txId: item.tx,
                  pool: item.pool,
                  uniswapVersion: item.uniswap_version,
                  blockNumber: item.block_number,
                  volume: item.volume,
                })) as any[]) || []
              }
              singleCurrencyData={token}
              chain={SupportedChains.ETH}
              style={{ marginTop: '2rem' }}
              visible
            />
            {/* <ChartPerspective type={1} darkMode={darkMode} /> */}
          </Content>
        </Wrapper>
      ),
    [
      symbol,
      loading,
      dataZoom,
      selectedLegends,
      dateAxisLabelFormat,
      chartTimeFrame,
      selectedPool,
      parsedData,
      chartData,
      groupedByPool,
      token,
      tokenPricesData,
    ]
  );
};

export default CurrencyView;
