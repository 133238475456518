import moment from 'moment';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ReactEChart, { EChartsInstance } from 'echarts-for-react';
import echartTheme from '../../assets/echartTheme.json';
import echartThemeDark from '../../assets/echartThemeDark.json';
import theme from '../../theme';
import { currencyFormatter } from '../../shared/helpers';
import useWindowSize from '../../hooks/useWindowSize';
import { Wrapper } from './styles';

type ChartType = 'marketCap' | 'volume';

interface ChartProps {
  data: any[];
  ready: boolean;
  isMobile: boolean;
  darkMode: boolean;
  type: ChartType;
}

const Treemap: React.FC<ChartProps> = ({
  data,
  ready,
  isMobile,
  darkMode,
  type,
}) => {
  const chartRef = useRef<EChartsInstance>();
  const { width } = useWindowSize();

  const formattedData = useMemo(() => {
    if (!data) return [];

    if (type === 'volume') {
      return data.map((token) => ({
        name: token.symbol,
        value: token.volume24h,
      }));
    }

    if (type === 'marketCap') {
      return data.map((token) => ({
        name: token.symbol,
        value: token.marketCap,
      }));
    }
  }, [data, type]);

  useEffect(() => {
    const instance: EChartsInstance = chartRef.current.getEchartsInstance();

    instance?.resize();
  }, [width]);

  const tooltipFormater = useCallback((params: any) => {
    const { name, value } = params.data;

    const price = currencyFormatter({
      notation: 'compact',
      maximumFractionDigits: 2,
    }).format(value);

    const label = type === 'marketCap' ? 'Market Cap (Total Supply)' : 'Volume (24h)';
    const volumeByPercentTotal =
      (100 * value) /
      data.reduce((acc: number, curr: any) => {
        if (type === 'volume') {
          return acc + curr.volume24h;
        }
        if (type === 'marketCap') {
          return acc + curr.marketCap;
        }
      }, 0);

    return `
        <div class="custom-tooltip">
          <div class="tooltip-value">${name}</div>
          <div class="tooltip-info">${price}(${volumeByPercentTotal?.toFixed(2)}%)</div>
          <div class="tooltip-date">${label}</div>
          <div class="tooltip-hours"></div>
        </div>
      `;
  }, []);

  const option = {
    series: [
      {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        name:
          type === 'marketCap'
            ? 'Market Cap Dominance'
            : 'Volume Dominance (24h)',
        type: 'treemap',
        visibleMin: 300,
        roam: 'move',
        data: [
          {
            children: formattedData,
          },
        ],
        breadcrumb: {
          show: false,
        },
        labelLayout: function (params: any) {
          if (params.rect.width < 5 || params.rect.height < 5) {
            return { fontSize: 0 };
          }
          return {
            fontSize: Math.min(
              Math.sqrt(params.rect.width * params.rect.height) / 6,
              100
            ),
          };
        },
        levels: [
          {
            itemStyle: {
              borderWidth: 3,
              borderColor: '#333',
              gapWidth: 3,
            },
          },
          {
            color: ['#942e38', '#aaa', '#269f3c'],
            colorMappingBy: 'value',
            itemStyle: {
              gapWidth: 1,
            },
          },
        ],
      },
    ],
    tooltip: {
      trigger: 'item',
      formatter: tooltipFormater,
      axisPointer: {
        type: 'cross',
        snap: true,
        label: {
          show: true,
          color: darkMode ? theme.colors.black : theme.colors.white,
          backgroundColor: darkMode ? theme.colors.creme : theme.colors.primary,
        },
      },
    },
    textStyle: {
      fontFamily: 'PolySans Neutral',
    },
  };

  return useMemo(
    () => (
      <Wrapper>
        <ReactEChart
          option={option}
          showLoading={!ready}
          theme={darkMode ? echartThemeDark : echartTheme}
          style={{ minHeight: 300 }}
          ref={chartRef}
          lazyUpdate
        />
      </Wrapper>
    ),
    [ready, data, darkMode]
  );
};

export default Treemap;
