import React, { useContext, useEffect, useState } from 'react';
import {
  AnchorPriceWrapper,
  AnchorTitle,
  FilterTag,
  FiltersWrapper,
  SegmentedWrapper,
} from '../../views/WinnersLosers/styles';
import { AiFillCaretDown } from 'react-icons/ai';
import { TiDelete } from 'react-icons/ti';
import { Divider, Dropdown, MenuProps, Popover } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { GlobalContext } from '../../context/globalState';

interface Props {
  visible: boolean;
}

const ExchangeFilters: React.FC<Props> = ({ visible }) => {
  const [liquidityChange, setLiquidityChange] = useState('');
  const [centralisation, setCentralisation] = useState('');
  const [anchor, setAnchor] = useState('USD');
  const navigate = useNavigate();
  const { darkMode } = useContext(GlobalContext);

  if (!visible) return null;

  const onClearFilters = (
    e: React.MouseEvent<SVGElement, MouseEvent>,
    name: string
  ) => {
    e.stopPropagation();

    switch (name) {
      case 'liquidityChange':
        setLiquidityChange('');
        break;
      case 'centralisation':
        setCentralisation('');
        break;
      default:
        break;
    }
  };

  const liquidityChanges: MenuProps['items'] = [
    {
      label: '> $1M',
      key: '0',
      onClick: () => setLiquidityChange('> $1M'),
    },
    {
      label: '> $10M',
      key: '1',
      onClick: () => setLiquidityChange('> $10M'),
    },
    {
      label: '> $100M',
      key: '2',
      onClick: () => setLiquidityChange('> $100M'),
    },
  ];

  const centralisations: MenuProps['items'] = [
    {
      label: 'Centralised',
      key: '0',
      onClick: () => setCentralisation('Centralised'),
    },
    {
      label: 'Decentralised',
      key: '1',
      onClick: () => setCentralisation('Decentralised'),
    },
  ];

  const anchors: MenuProps['items'] = [
    {
      label: 'USD',
      key: '0',
      onClick: () => setAnchor('USD'),
    },
    {
      label: 'BTC',
      key: '1',
      onClick: () => setAnchor('BTC'),
    },
    {
      label: 'EUR',
      key: '2',
      onClick: () => setAnchor('EUR'),
    },
  ];

  return (
    <FiltersWrapper $visible={visible}>
      <Dropdown menu={{ items: liquidityChanges }} trigger={['click']}>
        <FilterTag $selected={liquidityChange}>
          {liquidityChange ? (
            <span>
              {liquidityChange} <TiDelete onClick={(e) => onClearFilters(e, 'liquidityChange')} />
            </span>
          ) : (
            <span>
              Liquidity +=2% <AiFillCaretDown />
            </span>
          )}
        </FilterTag>
      </Dropdown>
      <Dropdown menu={{ items: centralisations }} trigger={['click']}>
        <FilterTag $selected={centralisation}>
          {centralisation ? (
            <span>
              {centralisation}{' '}
              <TiDelete onClick={(e) => onClearFilters(e, 'centralisation')} />
            </span>
          ) : (
            <span>
              Marketplace Type <AiFillCaretDown />
            </span>
          )}
        </FilterTag>
      </Dropdown>

      <AnchorPriceWrapper>
        <AnchorTitle>
          <Popover
            title="Anchor Price"
            placement="left"
            overlayStyle={{ width: '300px' }}
            content={
              <div>
                <p>
                  Anchor price is a token or weighted group of tokens used to
                  price all other tokens. Top 3 are USD, BTC, ETH.
                </p>
              </div>
            }
          >
            <InfoCircleOutlined />
          </Popover>
          Anchor Price
        </AnchorTitle>
        <Dropdown menu={{ items: anchors }} trigger={['click']}>
          <FilterTag>
            <span>
              {anchor} <AiFillCaretDown />
            </span>
          </FilterTag>
        </Dropdown>
      </AnchorPriceWrapper>
    </FiltersWrapper>
  );
};

export default ExchangeFilters;
