import React from 'react';
import { FilterInput } from './styles';
import { BiSearch } from 'react-icons/bi';

interface SearchBarProps {
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  isMobile: boolean;
  className?: string;
}

const SearchBar: React.FC<SearchBarProps> = ({
  searchQuery,
  setSearchQuery,
  isMobile,
  className,
}) => {
  return (
    <div>
      <FilterInput
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        placeholder="Search name or paste address"
        prefix={<BiSearch />}
        bordered={isMobile}
        className={className}
        allowClear
      />
    </div>
  );
};

export default SearchBar;
