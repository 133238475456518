import React, { useMemo } from 'react';
import ReactEChart, { EChartsInstance } from 'echarts-for-react';

const DoughnutChart = () => {
  const option = {
    tooltip: {
      trigger: 'item',
    },
    grid: {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    legend: {
      show: false,
    },
    series: [
      {
        name: 'Tokens',
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderColor: '#fff',
          borderRadius: 2,
          borderWidth: 2,
        },
        label: {
          show: false,
          position: 'center',
        },
        labelLine: {
          show: false,
        },
        data: [
          { value: 10.48, name: 'ETH' },
          { value: 0.735, name: 'BTC' },
          { value: 58, name: 'ADA' },
          { value: 321, name: 'CRV' },
          { value: 21, name: 'Others' },
        ],
      },
    ],
  };

  return useMemo(
    () => (
      <ReactEChart
        option={option}
        style={{
          height: '100%',
          width: '100%',
        }}
      />
    ),
    []
  );
};

export default DoughnutChart;
