import React from 'react';
import { createRoot } from 'react-dom/client';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { YbugProvider } from 'ybug-react';
import App from './views/App';
import GlobalStyles from './globalStyles';
import { GlobalProvider } from './context/globalState';
import { yBugId } from './shared/constants';
import { checkAppVersion } from './shared/helpers';

checkAppVersion();

const container = document.getElementById('root') as Element;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <YbugProvider ybugId={yBugId}>
      <DndProvider backend={HTML5Backend}>
        <GlobalProvider>
          <GlobalStyles />
          <App />
        </GlobalProvider>
      </DndProvider>
    </YbugProvider>
  </React.StrictMode>
);
