import React from 'react';
import { useParams } from 'react-router-dom';
import { ContentWrapper, HeaderWrapper, NFTMedia, Wrapper } from './styles';

interface Props { }

const NFT: React.FC<Props> = () => {
  const { id } = useParams();

  return (
    <Wrapper>
      <ContentWrapper>
        <HeaderWrapper>
          <div>
            <img
              src="https://lh3.googleusercontent.com/Z9HJx1h4yv1DoTARZxm0EbJ-yH0WVYuJqiogWXRd3HhajADEF99J8xfyxYOsQJ_IO08ijpyjfdDAAPxn0jnouxGyJud_Joi9gX_H=s250"
              alt="nft-media"
            />
            <span>Random Collection Name Club</span>
          </div>
        </HeaderWrapper>
        <div>NAME</div>
        <div>floor price</div>
        <NFTMedia
          src="https://lh3.googleusercontent.com/Z9HJx1h4yv1DoTARZxm0EbJ-yH0WVYuJqiogWXRd3HhajADEF99J8xfyxYOsQJ_IO08ijpyjfdDAAPxn0jnouxGyJud_Joi9gX_H=s250"
          alt="nft-media"
        />
        <div>attributes grid</div>
        <div>description</div>
      </ContentWrapper>
    </Wrapper>
  );
};

export default NFT;
