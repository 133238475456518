import { ColumnsType, TableProps } from 'antd/lib/table';
import moment from 'moment';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChartViewTypes } from '../../components/PairDetails';
import { GlobalContext } from '../../context/globalState';
import { currencyFormatter, percentChange } from '../../shared/helpers';
import Chart from '../CurrencyView/Chart';
import { AiFillCaretUp, AiFillCaretDown } from 'react-icons/ai';
import { Dropdown, Menu, Switch, Tooltip } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { BsFilter } from 'react-icons/bs';
import {
  ActionsWrapper,
  ChartWrapper,
  InsightsSliderWrapper,
  PriceChangeWrapper,
  RankWrapper,
  TableName,
  TableWrapper,
  TitleWithInfoWrapper,
  Wrapper,
} from './styles';
import Insights from '../../components/Insights/Insights';
import FiltersBar from '../../components/Filters/FilterBar';
import { useFetchTokenListData } from '../App/hooks';
import FilterModal from '../../components/Filters/FilterModal';
import Button from '../../components/Button/Button';
import Header from '../../components/Tokens/Header';
import Loader from '../../components/Loader';
import Icon from '../../components/Icon';
import ErrorBoundary from '../../components/ErrorBoundary';

export interface TokensViewDataType {
  key: string;
  id: number;
  name: string;
  symbol: string;
  token_close_price_all_chains: number;
  token_volume_all_chains: number;
  rawData: any[];
  item24h: any;
  item7d: any;
  usd_price: number;
  eth_price: number;
  address: string;

  // legacy
  marketCap: number;
  price: number;
  price24h: number;
  price7d: number;
  volume: number;
  chain: string;
  volume24h: number;
  totalSupply: number;
  circulatingSupply: number;
}

const TopTokens: React.FC = () => {
  const { darkMode, isMobile, tokens } = useContext(GlobalContext);
  const [tooltipVisibleMCap, setTooltipVisibleMCap] = useState(false);
  const [tooltipVisibleCSupply, setTooltipVisibleCSupply] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [showInsights, setShowInsights] = useState(false);
  const navigate = useNavigate();

  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [parsedData, setParsedData] = useState<any[]>([]);
  const [filters, setFilters] = useState<any>({});
  const [anchor, setAnchor] = useState<string>('USD');
  const [searchQuery, setSearchQuery] = useState<string>('');

  useFetchTokenListData();

  const {
    loading,
    data: { tokenList = [] },
  } = tokens;

  useEffect(() => {
    setFilteredData(tokenList);
  }, [tokenList.length]);

  useEffect(() => {
    const filteredData = tokenList.filter((token: TokensViewDataType) => {
      // if (filters.dilutedMarketCap) {
      //   if (token.price * token.totalSupply > filters.dilutedMarketCap) {
      //     return false;
      //   }
      // }

      if (searchQuery) {
        if (
          !token.name?.toLowerCase().includes(searchQuery?.toLowerCase()) &&
          !token.symbol?.toLowerCase().includes(searchQuery?.toLowerCase()) &&
          !token.address?.toLowerCase().includes(searchQuery?.toLowerCase())
        ) {
          return false;
        }
      }

      if (filters.volume24h) {
        if (token.volume24h * token.price24h >= filters.volume24h) {
          return false;
        }
      }
      return true;
    });

    setFilteredData(filteredData);
  }, [filters, searchQuery]);

  useEffect(() => {
    setParsedData(filteredData);
  }, [filteredData]);

  const parseChartPriceData = (symbol: string) => {
    const chartData = Object.values(tokenList)
      .find((token: any) => token.symbol === symbol)
      ?.rawData.map(
        ({ t0_tgt_price, t1_tgt_price, token0_symbol, time_stamp }: any) => {
          const price = symbol === token0_symbol ? t0_tgt_price : t1_tgt_price;
          return {
            price,
            timestamp: time_stamp,
          };
        }
      );

    return chartData;
  };

  const columns: ColumnsType<TokensViewDataType> = [
    {
      title: '#',
      sorter: (a, b) =>
        a.volume24h * a.price > b.volume24h * b.price ? 1 : -1,
      sortDirections: ['ascend', 'descend', 'ascend'],
      align: 'center',
      fixed: isMobile ? 'left' : false,
      width: isMobile ? 60 : undefined,
      render: (value, record) => {
        const index = parsedData
          .sort((a, b) =>
            b.volume24h * b.price > a.price > a.volume24h ? 1 : -1
          )
          .findIndex((token) => token.symbol === record.symbol);
        return <RankWrapper>{index + 1}</RankWrapper>;
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a, b) => (a.name > b.name ? 1 : -1),
      sortDirections: ['ascend', 'descend', 'ascend'],
      fixed: isMobile ? 'left' : false,
      width: isMobile ? 150 : undefined,
      render: (value, record) => {
        return (
          <TableName>
            <Icon name={record.symbol} />
            <span>{value}</span>
            <span>{record.symbol}</span>
          </TableName>
        );
      },
    },
    {
      title: 'Price',
      dataIndex: 'price',
      sorter: (a, b) => a.price - b.price,
      width: 120,
      sortDirections: ['ascend', 'descend', 'ascend'],
      align: 'right',
      render: (value) => {
        if (loading) return '...';
        let price;

        price = currencyFormatter({
          maximumFractionDigits: 2,
        }).format(value);
        if (anchor !== 'USD') {
          const anchorToken =
            tokenList.find((token) => token.symbol === anchor) || ({} as any);

          price = currencyFormatter({
            notation: 'compact',
            maximumFractionDigits: 10,
          }).format(value / anchorToken.price);

          return `${price.replace('$', '')} ${anchor}`;
        }
        return price;
      },
    },
    {
      title: '24h %',
      dataIndex: 'item24h',
      sorter: (a, b) => a.price24h - b.price24h,
      width: 120,
      sortDirections: ['ascend', 'descend', 'ascend'],
      align: 'right',
      render: (_, record) => {
        const isPositive = record.price > record.price24h;
        const value = percentChange(record.price24h, record.price);

        const icon = isPositive ? (
          <AiFillCaretUp size={12} />
        ) : (
          <AiFillCaretDown size={12} />
        );

        return (
          <PriceChangeWrapper $isPositive={isPositive}>
            {icon}
            {value}
          </PriceChangeWrapper>
        );
      },
    },
    {
      title: '7d %',
      dataIndex: 'price7d',
      sorter: (a, b) => a.price7d - b.price7d,
      width: 120,
      sortDirections: ['ascend', 'descend', 'ascend'],
      align: 'right',
      render: (_, record) => {
        const isPositive = record.price > record.price7d;
        const value = percentChange(record.price7d, record.price);

        const icon = isPositive ? (
          <AiFillCaretUp size={12} />
        ) : (
          <AiFillCaretDown size={12} />
        );

        return (
          <PriceChangeWrapper $isPositive={isPositive}>
            {icon}
            {value}
          </PriceChangeWrapper>
        );
      },
    },
    // {
    //   title: (
    //     <TitleWithInfoWrapper>
    //       Market Cap
    //       <Tooltip
    //         title={MARKET_CAP_TOOLTIP}
    //         open={tooltipVisibleMCap}
    //         onOpenChange={setTooltipVisibleMCap}
    //       >
    //         <InfoCircleOutlined />
    //       </Tooltip>
    //     </TitleWithInfoWrapper>
    //   ),
    //   dataIndex: 'marketCap',
    //   sorter: (a, b) => a.marketCap - b.marketCap,
    //   sortDirections: ['ascend', 'descend', 'ascend'],
    //   align: 'right',
    //   render: (value) => {
    //     if (loading) return '...';
    //     let price = currencyFormatter({ notation: 'compact' }).format(value);

    //     if (anchor !== 'USD') {
    //       const anchorToken = tokenList.find(
    //         (token: TOKEN) => token.token_symbol === anchor
    //       );
    //       if (value / anchorToken.price < 0.001) {
    //         price = '< 0.001';
    //       } else {
    //         price = currencyFormatter().format(value / anchorToken.price);
    //       }
    //       return `${price.replace('$', '')} ${anchor}`;
    //     }

    //     return price;
    //   },
    // },
    {
      title: 'Volume (24h)',
      dataIndex: 'volume24h',
      sorter: (a, b) => a.volume24h * a.price - b.volume24h * b.price,
      sortDirections: ['ascend', 'descend', 'ascend'],
      defaultSortOrder: 'descend',
      align: 'right',
      render: (value, record) => {
        let price: any = value * record.price;

        if (anchor !== 'USD') {
          const anchorToken =
            tokenList.find((token) => token.symbol === anchor) || ({} as any);

          price = price / anchorToken.price;
        }

        const volumeCompact = currencyFormatter({
          notation: 'compact',
          maximumFractionDigits: 1,
        }).format(price);

        if (anchor !== 'USD') {
          return <div>{`${volumeCompact.replace('$', '')} ${anchor}`}</div>;
        }
        return <div>{volumeCompact}</div>;
      },
    },
    // {
    //   title: (
    //     <TitleWithInfoWrapper>
    //       Circulating Supply
    //       <Tooltip
    //         title={CIRCULATING_SUPPLY_TOOLTIP}
    //         open={tooltipVisibleCSupply}
    //         onOpenChange={setTooltipVisibleCSupply}
    //       >
    //         <InfoCircleOutlined />
    //       </Tooltip>
    //     </TitleWithInfoWrapper>
    //   ),
    //   // until we calculate the circulating supply, we will use the total supply
    //   dataIndex: 'circulatingSupply',
    //   sorter: (a, b) => a.totalSupply - b.totalSupply,
    //   sortDirections: ['ascend', 'descend', 'ascend'],
    //   align: 'right',
    //   render: (value, record) => {
    //     value = record.totalSupply;
    //     return (
    //       <span>
    //         <span>
    //           {' '}
    //           {new Intl.NumberFormat('en-US', {
    //             notation: value > 10 ** 9 ? 'compact' : 'standard',
    //             maximumFractionDigits: 3,
    //           }).format(value)}
    //         </span>
    //         <span style={{ marginLeft: 4 }}>{record.symbol}</span>
    //       </span>
    //     );
    //   },
    // },
    // {
    //   title: <TitleWithInfoWrapper>Total Supply</TitleWithInfoWrapper>,
    //   dataIndex: 'totalSupply',
    //   sorter: (a, b) => a.totalSupply - b.totalSupply,
    //   sortDirections: ['ascend', 'descend', 'ascend'],
    //   align: 'right',
    //   render: (value, record) => {
    //     return (
    //       <span>
    //         <span>
    //           {new Intl.NumberFormat('en-US', {
    //             notation: value > 10 ** 9 ? 'compact' : 'standard',
    //             maximumFractionDigits: 3,
    //           }).format(value)}
    //         </span>
    //         <span style={{ marginLeft: 4 }}>{record.symbol}</span>
    //       </span>
    //     );
    //   },
    // },
    {
      title: 'Last 24 hr',
      dataIndex: 'last24hr',
      align: 'right',
      width: 200,
      render: (value, record) => {
        return (
          <ErrorBoundary>
            <ChartWrapper>
              <Chart
                renderAsImage
                isPositive={record.price > record.price24h}
                data={parseChartPriceData(record.symbol)}
                metadata={[]}
                dataZoom={{
                  startValue: 0,
                  endValue: moment().unix() * 1000,
                }}
                dateAxisLabelFormat={''}
                priceInverted={false}
                ready={true}
                onChartClick={() => { }}
                onLegendSelect={() => { }}
                selectedLegends={{ Price: true }}
                mode={ChartViewTypes.CHART}
                isMobile={false}
                darkMode={darkMode}
                grid={{ left: 8, right: 8, bottom: 8, top: 8 }}
              />
            </ChartWrapper>
          </ErrorBoundary>
        );
      },
    },
    {
      title: '',
      dataIndex: 'actions',
      width: 48,
      render: (_, record) => {
        return (
          <ActionsWrapper>
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item key="1">
                    <span>Option 1</span>
                  </Menu.Item>
                  <Menu.Item key="2">
                    <span>Option 2</span>
                  </Menu.Item>
                  <Menu.Item key="3">
                    <span>Option 3</span>
                  </Menu.Item>
                </Menu>
              }
              trigger={['click']}
              placement="bottomRight"
            >
              <Button
                type="text"
                shape="circle"
                icon={<MoreOutlined />}
                size="small"
              />
            </Dropdown>
          </ActionsWrapper>
        );
      },
    },
  ];

  const onChange: TableProps<TokensViewDataType>['onChange'] = (
    pagination,
    sorter,
    extra
  ) => { };

  return useMemo(
    () => (
      <Wrapper>
        <Header
          title="Market Watch"
          subtitle="Top 10 tokens by market capitalization, price, and circulating supply"
          actions={[
            <React.Fragment key="actions">
              {/* <InsightsSliderWrapper>
                {!isMobile ? 'Insights' : ''}
              </InsightsSliderWrapper>
              <Switch
                checked={showInsights}
                onChange={() => setShowInsights(!showInsights)}
                disabled={loading}
              /> */}
              <Button
                size="large"
                onClick={() => setShowFilters(!showFilters)}
                icon={<BsFilter />}
                disabled={loading}
              >
                {!isMobile ? 'Filters' : ''}
              </Button>
            </React.Fragment>,
          ]}
        />
        <Insights visible={showInsights} />
        <FiltersBar
          visible={true}
          showFilters={showFilters}
          setShowFilters={setShowFilters}
          anchor={anchor}
          setAnchor={setAnchor}
          loading={loading}
          query={searchQuery}
          setQuery={setSearchQuery}
        />
        <FilterModal
          visible={showFilters}
          onClose={() => setShowFilters(false)}
          onApply={setFilters}
        />
        <TableWrapper
          columns={columns}
          dataSource={parsedData}
          onChange={onChange}
          rowClassName={'table-row-custom'}
          size={'small'}
          showSorterTooltip={!tooltipVisibleCSupply && !tooltipVisibleMCap}
          sorter={{}}
          onRow={(record: TokensViewDataType) => {
            return {
              onClick: () =>
                navigate(`/tokens/token/${record.symbol}`, {
                  state: { data: record },
                }),
            };
          }}
          loading={{
            indicator: (
              <Loader
                size="medium"
                style={{ height: '100%', margin: '0 auto' }}
              />
            ),
            spinning: loading,
          }}
          {...(isMobile && { scroll: { x: 1300 } })}
        />
      </Wrapper>
    ),
    [
      loading,
      parsedData.length,
      tooltipVisibleCSupply,
      tooltipVisibleMCap,
      showFilters,
      showInsights,
      anchor,
      isMobile,
      searchQuery,
    ]
  );
};

export default TopTokens;
