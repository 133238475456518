import axios from 'axios';
import packageJSON from '../../package.json';
import {
  getLocalStorage,
  STATE_LOCAL_STORAGE_KEY_AUTH,
} from '../context/localStorage';
import { IsTauri } from './helpers';

export interface IAuth {
  username: string;
  password: string;
}

const defaultOptions: any = {
  method: 'get',
  headers: {
    'Content-Type': 'application/json',
  },
};

const createAuthHeader = (user: IAuth, Buffer: any) => {
  const token = Buffer.from(`${user.username}:${user.password}`).toString(
    'base64'
  );

  return `Basic ${token}`;
};

interface Props {
  url: string;
  auth?: IAuth;
  options?: { [key: string]: any };
  setUser?: (user: IAuth | null) => void;
}

const isDesktop = IsTauri();

export const getFetchInstance = async ({
  url,
  auth,
  options,
  setUser,
}: Props) => {
  const fetch = isDesktop
    ? (await import('@tauri-apps/api/http')).fetch
    : axios;
  const { Buffer } = await import('buffer');

  url = `${isDesktop ? packageJSON.proxy : ''}` + url?.toLowerCase();

  const params = {
    ...defaultOptions,
    ...options,
  };

  const credentials = auth;

  if (credentials) {
    params.headers.Authorization = createAuthHeader(credentials, Buffer);
  } else {
    setUser?.(null);
  }

  return await fetch(url, params);
};

export { axios };
export default fetch;
