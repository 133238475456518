import moment from 'moment';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ReactEChart, { EChartsInstance } from 'echarts-for-react';
import echartTheme from '../../assets/echartTheme.json';
import echartThemeDark from '../../assets/echartThemeDark.json';
import theme from '../../theme';
import {
  calculateNumberOfDecimalPlaces,
  chartNumberFormatter,
  renderFractionUnitOfETHOrBTC,
} from '../../shared/helpers';
import useWindowSize from '../../hooks/useWindowSize';
import { ChartViewTypes, DataZoomProps } from '../../components/PairDetails';
import { SelectedLabels } from '.';
import { ChartAsImageWrapper } from './styles';

interface GridProps {
  left: number;
  right: number;
  top: number;
  bottom: number;
}

interface ChartProps {
  data: any[];
  dateAxisLabelFormat: string;
  dataZoom: DataZoomProps;
  priceInverted: boolean;
  metadata: any[];
  extraData?: any;
  ready: boolean;
  onChartClick: (params: any) => void;
  onLegendSelect: ({ name }: { name: string }) => void;
  mode: ChartViewTypes;
  selectedLegends: Partial<SelectedLabels>;
  isMobile: boolean;
  darkMode: boolean;
  grid?: GridProps;
  renderAsImage?: boolean;
  isPositive?: boolean;
}

const Chart: React.FC<ChartProps> = ({
  data,
  dateAxisLabelFormat,
  priceInverted,
  metadata,
  extraData,
  dataZoom,
  ready,
  onChartClick,
  onLegendSelect,
  mode,
  selectedLegends,
  isMobile,
  darkMode,
  grid: gridProps = {
    left: 18,
    right: 52,
    top: 68,
    bottom: 68,
  },
  renderAsImage = false,
  isPositive,
}) => {
  const chartRef = useRef<EChartsInstance>();
  const { width } = useWindowSize();
  const [batchData, setBatchData] = useState(new Float64Array({ length: 0 }));
  const [grid, setGrid] = useState<GridProps>(gridProps);
  const [chartImagePreview, setChartImagePreview] = useState<string>('');

  useEffect(() => {
    if (data) {
      const batchData = new Float64Array({ length: data.length * 2 });

      for (let i = 0; i < data.length; i++) {
        if (!Number.isFinite(data[i].price)) continue;

        batchData[i * 2] = data[i].timestamp * 1000;
        batchData[i * 2 + 1] = data[i].price;
      }

      setBatchData(batchData);
    }
  }, [data]);

  useEffect(() => {
    const instance: EChartsInstance = chartRef.current?.getEchartsInstance();

    instance?.resize();
  }, [width]);

  useEffect(() => {
    if (renderAsImage && batchData.length > 0 && !chartImagePreview) {
      const instance: EChartsInstance = chartRef.current?.getEchartsInstance();

      const chartImage = instance?.getDataURL({
        pixelRatio: 1,
        excludeComponents: ['toolbox', 'legend', 'xAxis', 'yAxis', 'dataZoom'],
      });

      setChartImagePreview(chartImage);
    }
  }, [batchData, darkMode]);

  const tooltipFormater = useCallback(
    (params: any) => {
      const { data, seriesIndex } = params;

      const timestamp = data[0];
      const value = data[seriesIndex + 1];

      const date = moment(new Date(timestamp)).format('L');
      const hours = moment(new Date(timestamp)).format('h:mma');
      const symbol = 'USD';

      const valueParsed = new Intl.NumberFormat('en-US', {
        notation: value > 10 ** 9 ? 'compact' : 'standard',
        maximumFractionDigits: 2,
      }).format(value);

      return `
        <div class="custom-tooltip">
          <div class="tooltip-value">${valueParsed} ${symbol ? symbol : ''
        }</div>
          <div class="tooltip-date">${date}</div>
          <div class="tooltip-hours">${hours}</div>
        </div>
      `;
    },
    [priceInverted, metadata, mode, extraData]
  );

  const option = {
    grid: {
      ...grid,
    },
    legend: {
      data: ['Price'],
      icon: 'circle',
      selected: { ...selectedLegends },
      left: 0,
      itemGap: 16,
      textStyle: {
        fontSize: 14,
        color: darkMode ? theme.colors.creme : theme.colors.primary,
      },
      itemStyle: {
        opacity: 1,
      },
    },
    xAxis: {
      type: 'time',
      min: 'dataMin',
      max: 'dataMax',
      axisLabel: {
        formatter: {
          year: '{yyyy}',
          month: '{monthDate|{MMM} {yy}}',
          day: '{dayDate|{ee} {d}}',
          hour: '{hourDate|{HH}:{mm}}',
          minute: '{hourDate|{HH}:{mm}}',
          second: '{mm}:{ss}',
          none: '{yyyy}-{MM}-{dd}',
        },
        rich: {
          monthDate: {
            fontWeight: 'bold',
          },
          dayDate: {},
          hourDate: {
            color: darkMode ? theme.colors.creme : 'rgba(0,0,0,0.57)',
          },
        },
        textStyle: {
          color: darkMode ? theme.colors.creme : theme.colors.primary,
        },
        hideOverlap: true,
      },
      axisPointer: {
        snap: true,
      },
      splitLine: {
        show: false,
      },
      ...(isMobile ? { splitNumber: 3 } : {}),
    },
    yAxis: [
      {
        type: 'value',
        min: 'dataMin',
        max: 'dataMax',
        boundaryGap: false,
        axisLabel: {
          formatter: function (value: any) {
            return chartNumberFormatter(value, 'BTC');
          },
          textStyle: {
            color: darkMode ? theme.colors.creme : theme.colors.primary,
          },
        },
        splitLine: {
          show: false,
        },
      },
      {
        type: 'value',
        min: 'dataMin',
        max: 'dataMax',
        boundaryGap: false,
        axisLabel: {
          formatter: function (value: any) {
            return chartNumberFormatter(value, 'BTC');
          },
          textStyle: {
            color: darkMode ? theme.colors.creme : theme.colors.primary,
          },
        },
        splitLine: {
          show: false,
          lineStyle: {
            color: theme.colors.gray,
          },
        },
      },
    ],
    dataset: {
      source: batchData,
      dimensions: [
        {
          name: 'timestamp',
          type: 'time',
        },
        {
          name: 'Price',
          type: 'float',
        },
      ],
    },
    series: [
      {
        name: 'Price',
        type: 'line',
        encode: {
          x: 'timestamp',
          y: 'Price',
        },
        yAxisIndex: 1,
      },
    ].map((s) => ({
      ...s,
      ...{
        animation: false,
        smooth: false,
        symbolSize: 8,
        showAllSymbol: true,
        itemStyle: {
          borderWidth: 3,
          opacity: 0,
        },
        emphasis: {
          itemStyle: {
            opacity: 1,
          },
        },
        lineStyle: {
          normal: {
            width: 2,
          },
        },
      },
    })),
    tooltip: {
      trigger: 'item',
      formatter: tooltipFormater,
      axisPointer: {
        type: 'cross',
        snap: true,
        label: {
          show: true,
          formatter: function ({
            value,
            axisDimension,
          }: {
            value: number;
            axisDimension: 'x' | 'y';
          }) {
            if (axisDimension === 'y') {
              return chartNumberFormatter(value);
            }
            return moment(new Date(value)).format(dateAxisLabelFormat);
          },
          color: darkMode ? theme.colors.black : theme.colors.white,
          backgroundColor: darkMode ? theme.colors.creme : theme.colors.primary,
        },
      },
    },
    dataZoom: [
      {
        type: 'inside',
        minValueSpan: 1000 * 1000,
        ...dataZoom,
      },
      {
        type: 'slider',
        minValueSpan: 1000 * 1000,
        ...dataZoom,
        labelFormatter: function (timestamp: number) {
          return moment(new Date(timestamp)).format(dateAxisLabelFormat);
        },
        selectedDataBackground: {
          lineStyle: {
            color: darkMode ? theme.colors.gray2 : theme.colors.secondary,
            width: 1,
          },
          areaStyle: {
            color: darkMode ? theme.colors.gray2 : theme.colors.secondary,
            opacity: darkMode ? 0.8 : 0.4,
          },
        },
        fillerColor: darkMode
          ? 'rgba(247, 230, 208, 0.1)'
          : 'rgb(58, 6, 35, 0.2)',
        handleStyle: {
          color: darkMode ? theme.colors.gray2 : theme.colors.secondary,
        },
        moveHandleStyle: {
          color: darkMode ? theme.colors.gray2 : theme.colors.secondary,
          borderColor: darkMode ? theme.colors.gray2 : theme.colors.black,
        },
        handleColor: darkMode
          ? 'rgba(247, 230, 208, 0.6)'
          : 'rgb(58, 6, 35, 0.6)',
        emphasis: {
          handleStyle: {
            color: darkMode
              ? 'rgba(247, 230, 208, 0.6)'
              : 'rgb(58, 6, 35, 0.6)',
            borderColor: darkMode
              ? 'rgba(247, 230, 208, 0.6)'
              : 'rgb(58, 6, 35, 0.6)',
          },
          moveHandleStyle: {
            color: darkMode
              ? 'rgba(247, 230, 208, 0.6)'
              : 'rgb(58, 6, 35, 0.6)',
          },
        },
      },
    ],
    textStyle: {
      fontFamily: 'PolySans Neutral',
    },
    ...(renderAsImage && {
      color: [isPositive ? theme.colors['antd-green'] : theme.colors.secondary],
    }),
  };

  return useMemo(
    () => (
      <>
        <ReactEChart
          option={option}
          theme={darkMode ? echartThemeDark : echartTheme}
          style={{
            height: renderAsImage ? 50 : 300,
            visibility: renderAsImage ? 'hidden' : 'visible',
            padding: 4,
          }}
          ref={chartRef}
          onEvents={{
            click: onChartClick,
            legendSelectChanged: onLegendSelect,
          }}
          lazyUpdate
        />
        {renderAsImage && (
          <ChartAsImageWrapper
            src={chartImagePreview}
            alt="chart-preview"
            $darkMode={darkMode}
            style={{ visibility: chartImagePreview ? 'visible' : 'hidden' }}
          />
        )}
      </>
    ),
    [
      ready,
      data,
      batchData,
      mode,
      chartImagePreview,
      dateAxisLabelFormat,
      dataZoom,
      metadata,
      priceInverted,
      darkMode,
    ]
  );
};

export default Chart;
