import styled from 'styled-components';
import theme from '../../theme';
import { Button, Input, Modal } from 'antd';
import { ReactFlow } from 'reactflow';

export const Wrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  padding: 0 1rem;
  overflow-y: auto;
  margin: 0 auto;
  position: relative;
  max-width: 100rem;
  height: 100%;
  width: 100%;
  background-color: var(--color-background);

  @media ${theme.mediaQueries.mobile} {
    overflow-x: hidden;
    max-width: 100vw;
    height: 100%;
    /* height: calc(100% - var(--topbar-height)); */
  }

  .custom-tooltip {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 0rem 1rem;
    margin: 0rem;

    .tooltip-value {
      font-weight: bold;
      color: var(--color-black);
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
    }

    .tooltip-date,
    .tooltip-hours {
      color: gray;
    }
`;

export const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
  color: var(--color-text);

  @media ${theme.mediaQueries.mobile} {
    font-size: 1.2rem;
  }
`;

export const Subtitle = styled.h2`
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
  color: var(--color-help-text);
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2rem 0;
`;

export const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;

  @media ${theme.mediaQueries.mobile} {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
`;

export const ChartsContainer = styled.div<{ $visible: boolean }>`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  position: relative;
  place-items: center;
  align-items: start;
  justify-content: center;
  gap: 4rem;
  width: 100%;
  height: 100%;
  max-height: ${({ $visible }) => ($visible ? '100%' : '0')};
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;

  .ant-radio-group {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;

    label {
      text-align: center;
    }
  }

  @media ${theme.mediaQueries.mobile} {
    gap: 2rem;
  }
`;

export const TokenInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* width: 37rem; */
  max-width: 100%;

  @media ${theme.mediaQueries.mobile} {
    width: 100%;
  }
`;

export const TokenInputContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TokenInputHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
  line-height: 20px;
`;

export const TokenInputBox = styled.div`
  display: block;
  width: 10rem;
  height: 100%;
  padding: 0.75rem 1rem;
  border-radius: 2px;
  background-color: var(--color-background);

  & > div {
    display: flex;
    flex-direction: row;
    justify-items: baseline;
    justify-content: space-between;
    align-items: center;
    min-height: 1rem;

    &:first-child {
      font-size: 13px;
      color: var(--color-text);
      opacity: 0.8;
    }

    &:last-child {
      height: 2rem;
      margin: 0.5rem 0;
    }
  }

  @media ${theme.mediaQueries.mobile} {
    width: 100%;
  }
`;

export const TokenInputSeparator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -8px 0;

  button {
    width: 2rem !important;
    height: 2rem !important;
    border-radius: 50% !important;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 4px rgba(71, 73, 79, 0.1), 0 1px 2px rgba(71, 73, 79, 0.25);
  }

  svg {
    width: 1.75rem;
    height: 1.75rem;
    transition: transform 0.2s ease-in-out;
    transform: rotate(-90deg);

    &:hover {
      transform: rotate(90deg);
    }

    @media ${theme.mediaQueries.mobile} {
      transform: rotate(180deg);

      &:hover {
        transform: rotate(360deg);
      }
    }
  }
`;

export const TokenInputButton = styled(Button)`
  border: none;
  background-color: var(--color-primary);
  color: var(--color-white);
  width: 100%;
  height: 3rem;
  margin-top: 1rem;
  font-size: 1.25rem;
  font-weight: normal;
`;

export const TokenInputSelect = styled.div`
  display: flex;
  align-items: center;
  font-size: 2rem;
  width: 100%;

  .ant-select-selector {
    font-size: 1.5rem;
    padding-left: 0 !important;
    color: var(--color-text);
  }

  .ant-select-arrow {
    color: var(--color-text);
  }

  .ant-select-selection-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    img {
      position: relative;
      bottom: 1px;
    }
  }

  @media ${theme.mediaQueries.mobile} {
    justify-content: flex-end;
  }
`;

export const TokenInputValue = styled(Input)`
  font-size: 1.25rem;
  min-height: 1.5rem;
  min-width: 8rem;
  padding: 0;
  text-align: right;
  background: none;
  outline: none;
  height: 100%;
  color: var(--color-text);
`;

export const TokenInputContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 2rem;
  margin-top: 1rem;

  @media ${theme.mediaQueries.mobile} {
    gap: 0.75rem;
    flex-direction: column;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.25rem;
  font-weight: bold;
`;

export const ModalWrapper = styled(Modal)`
  && {
    .ant-modal-body {
      padding: 0;
      padding-top: 1rem;
    }

    .ant-modal-content {
    }

    .ant-list {
      height: 300px;
      overflow: auto;
      border-top: 1px solid var(--color-gray-2);
    }

    .ant-list-item {
      padding: 0rem;
    }

    .search-input {
      margin: 0 1rem;
      margin-bottom: 1rem;
      width: calc(100% - 2rem);

      background-color: var(--color-gray);

      input {
        background-color: var(--color-gray);
      }
    }
  }
`;

export const ListItem = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  padding: 1rem;
  font-weight: bold;
  font-size: 1.25rem;
  width: 100%;

  &:hover {
    background-color: var(--color-gray);
  }
`;

export const CommonTokenOffer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 1rem;
  padding: 0 1rem;

  & > div {
    border: 1px solid var(--color-gray-2);
    border-radius: 0.5rem;
    padding: 0.25rem 0.5rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;

    &:hover {
      background-color: var(--color-gray);
    }
  }
`;

export const ReactFlowStyled = styled(ReactFlow)`
  min-height: 400px;
  max-width: 1200px;
  pointer-events: none;

  .react-flow__edge.selected .react-flow__edge-path,
  .react-flow__edge:focus .react-flow__edge-path,
  .react-flow__edge:focus-visible .react-flow__edge-path {
    stroke: var(--color-primary) !important;
    stroke-width: 2px !important;
  }

  @media ${theme.mediaQueries.mobile} {
    min-height: 300px;
    max-width: 100%;
    pointer-events: all;
  }
`;

export const CustomNodeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  gap: 0.25rem;
  font-size: 0.75rem;
  font-weight: bold;
  margin: 0 0.5rem;
  height: 100%;

  .price {
    font-weight: normal;
    font-size: 0.875rem;
    color: var(--color-black-2);
    margin-top: -0.25rem;
  }

  .custom-edge-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border-radius: 4px;
    padding: 4px;
    min-width: 120px;
    min-height: 40px;
    background: var(--color-background-3);
    pointer-events: all;
    cursor: pointer;

    &:hover {
      background: rgba(255, 255, 255, 1);
      transform: scale(1.2);

      .price {
        font-weight: bold;
      }
    }
  }
`;

export const ChartWrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  width: 100%;
  place-items: center;

  h2 {
    font-weight: bold;
    margin: 0;
    color: var(--color-text);
    margin-left: 2rem;
  }
`;

export const ProgressWrapper = styled.div`
  width: 37rem;
  display: flex;
  justify-content: center;

  @media ${theme.mediaQueries.mobile} {
    width: 100%;
  }
`;

export const EdgeLabelToken = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 2px;
`;

export const SectionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-background-3);
  height: 600px;
  padding: 2rem;
  margin: 0 1rem;
  border-radius: 2px;

  @media ${theme.mediaQueries.mobile} {
    height: 100%;
  }
`;

export const SwapDescriptionWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: var(--color-background-3);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 1rem;

  svg {
    width: 4rem;
    height: 4rem;
    margin-bottom: 1rem;
  }
`;

export const SwapDescriptionTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--color-text);
  margin: 0;
`;

export const SwapDescriptionSubtitle = styled.h3`
  font-size: 1rem;
  font-weight: normal;
  color: var(--color-text);
  margin: 0;
`;

export const FAQWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
  margin-bottom: 2rem;

  .ant-collapse {
    width: 100%;
    max-width: 600px;

    @media ${theme.mediaQueries.mobile} {
      max-width: 100%;
    }
  }

  .ant-collapse-borderless {
    background-color: transparent;
  }
  .ant-collapse-borderless > .ant-collapse-item {
    border-bottom-color: var(--color-text);
  }

  .ant-collapse-header,
  .ant-collapse-content {
    color: var(--color-text) !important;
    font-size: 1.25rem;
  }
`;

export const FAQButton = styled(Button)`
  padding: 0 !important;
  font-size: 1.25rem;
  color: var(--color-accent-2);
`;
