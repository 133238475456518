import { message } from 'antd';

const ETH_CHAIN_ID = '0x1';

const loginWithMetamask = async (): Promise<string> => {
  const { ethereum } = window as any;

  if (ethereum !== 'undefined' && ethereum.isMetaMask) {
    const chainId = await ethereum.request({ method: 'eth_chainId' });

    if (chainId !== ETH_CHAIN_ID) {
      try {
        await ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: ETH_CHAIN_ID }],
        });
      } catch (err: any) {
        if (err.code === 4902) {
          await ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: ETH_CHAIN_ID,
                chainName: 'Ethereum Mainnet',
                nativeCurrency: {
                  name: 'ETH',
                  symbol: 'ETH',
                  decimals: 18,
                },
                rpcUrls: ['https://mainnet.infura.io/v3/'],
                blockExplorerUrls: ['https://etherscan.io/'],
              },
            ],
          });
        }
      }
    }

    await new Promise((res) => setTimeout(res, 500));

    if ((await ethereum.request({ method: 'eth_chainId' })) === ETH_CHAIN_ID) {
      const accounts = await ethereum.request({
        method: 'eth_requestAccounts',
      });
      const account = accounts[0];
      const sliceStartAndEndAccount = `${account.slice(0, 6)}...${account.slice(
        account.length - 4,
        account.length
      )}`;

      message.success(`Account ${sliceStartAndEndAccount} - connected!`);
      return account;
    }

    return '';
  }

  return '';
};

export default loginWithMetamask;
