import React, { useContext, useEffect, useState } from 'react';

import {
  CommonTokenOffer,
  ListItem,
  ModalHeader,
  ModalWrapper,
  TokenInputBox,
  TokenInputButton,
  TokenInputContainer,
  TokenInputContent,
  TokenInputHeader,
  TokenInputSelect,
  TokenInputSeparator,
  TokenInputValue,
  TokenInputWrapper,
} from '../styles';
import { BiDownArrowAlt } from 'react-icons/bi';
import { Button, List, Select } from 'antd';

import { FetchDataProps } from '..';
import SearchBar from '../../../components/Sidebar/SearchBar';
import { GlobalContext } from '../../../context/globalState';
import tokenList from './tokenList.json';

export interface TokenDataProps {
  symbol: string;
  logoURI?: string;
  address: string;
  chainId: number;
}

interface Props {
  onFetchData: (data: FetchDataProps) => void;
  loading: boolean;
}

const COMMON_TOKENS = tokenList.tokens.filter(
  ({ symbol, chainId }) =>
    ['WETH', 'USDC', 'DAI', 'CRV', 'USDT', 'WBTC'].includes(symbol) &&
    chainId === 1
);

const TokenInput: React.FC<Props> = ({ onFetchData, loading }) => {
  const [token0, setToken0] = useState('WETH');
  const [token1, setToken1] = useState('USDC');
  const [address0, setAddress0] = useState(
    '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'
  );
  const [address1, setAddress1] = useState(
    '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48'
  );
  const [startingBlock, setStartingBlock] = useState(17550000);
  const [endingBlock, setEndingBlock] = useState(17551000);
  const [step, setStep] = useState(100);
  const [amount, setAmount] = useState(1);
  const [selectedToken, setSelectedToken] = useState<'token0' | 'token1'>();
  const [searchQuery, setSearchQuery] = useState('');
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const { isMobile } = useContext(GlobalContext);
  const [tokens, setTokens] = useState<TokenDataProps[]>(
    tokenList.tokens.filter(({ chainId }) => chainId === 1)
  );

  useEffect(() => {
    if (searchQuery) {
      const filtered = tokenList.tokens.filter(
        ({ symbol, chainId }) =>
          symbol.toLowerCase().includes(searchQuery.toLowerCase()) &&
          chainId === 1
      );
      setTokens(filtered);
    } else {
      setTokens(tokenList.tokens.filter(({ chainId }) => chainId === 1));
    }
  }, [searchQuery]);

  const updateToken0 = (token: string) => {
    if (token === token1) {
      setToken1(token0);
    }
    setToken0(token);
  };

  const updateToken1 = (token: string) => {
    if (token === token0) {
      setToken0(token1);
    }
    setToken1(token);
  };

  const onTokenSelect = (token: 'token0' | 'token1') => {
    setModalIsVisible(true);
    setSelectedToken(token);
  };

  const swapTokens = () => {
    const temp = token0;
    setToken0(token1);
    setToken1(temp);
  };

  const onEstimate = () => {
    onFetchData({
      token0,
      token1,
      amount,
      address0,
      address1,
      startingBlock,
      endingBlock,
      step,
    });
  };

  const onCancelModal = () => {
    setModalIsVisible(false);
    setSearchQuery('');
    setSelectedToken(undefined);
  };

  return (
    <TokenInputWrapper>
      <TokenInputContent>
        <TokenInputHeader>Swap</TokenInputHeader>
        <TokenInputContainer>
          <TokenInputBox>
            <div>You sell</div>
            <div>
              <TokenInputSelect>
                <Select
                  value={token0}
                  onChange={updateToken0}
                  bordered={false}
                  size="large"
                  popupClassName="token-input-popup"
                  onClick={() => onTokenSelect('token0')}
                  open={false}
                >
                  {tokens.map(({ symbol, logoURI, address }) => (
                    <Select.Option key={address} value={symbol}>
                      <img src={logoURI} alt={symbol} width={24} height={24} />
                      {symbol}
                    </Select.Option>
                  ))}
                </Select>
              </TokenInputSelect>
            </div>
          </TokenInputBox>
          <div style={{ width: isMobile ? '100%' : 'auto' }}>
            <TokenInputBox>
              <div>Amount</div>
              <TokenInputValue
                value={amount || 0}
                onChange={(e) => setAmount(parseInt(e.target.value))}
                placeholder="1"
                bordered={false}
                size="small"
                autoFocus
              />
            </TokenInputBox>
            <TokenInputSeparator>
              <Button
                type="default"
                icon={<BiDownArrowAlt />}
                size="small"
                onClick={swapTokens}
              />
            </TokenInputSeparator>
          </div>
          <TokenInputBox>
            <div>You buy</div>
            <div>
              <TokenInputSelect>
                <Select
                  value={token1}
                  onChange={updateToken1}
                  bordered={false}
                  size="large"
                  popupClassName="token-input-popup"
                  onClick={() => onTokenSelect('token1')}
                  open={false}
                >
                  {tokens.map(({ symbol, logoURI, address }) => (
                    <Select.Option key={address} value={symbol}>
                      <img src={logoURI} alt={symbol} width={24} height={24} />
                      {symbol}
                    </Select.Option>
                  ))}
                </Select>
              </TokenInputSelect>
            </div>
          </TokenInputBox>
        </TokenInputContainer>
        <TokenInputContainer>
          <TokenInputBox>
            <div>Starting block</div>
            <TokenInputValue
              value={startingBlock || 0}
              onChange={(e) => setStartingBlock(parseInt(e.target.value))}
              placeholder="1"
              bordered={false}
              size="small"
              autoFocus
            />
          </TokenInputBox>
          <TokenInputBox>
            <div>Ending block</div>
            <TokenInputValue
              value={endingBlock || 0}
              onChange={(e) => setEndingBlock(parseInt(e.target.value))}
              placeholder="1"
              bordered={false}
              size="small"
              autoFocus
            />
          </TokenInputBox>
          <TokenInputBox>
            <div>Step</div>
            <TokenInputValue
              value={step || 0}
              onChange={(e) => setStep(parseInt(e.target.value))}
              placeholder="1"
              bordered={false}
              size="small"
              autoFocus
            />
          </TokenInputBox>
        </TokenInputContainer>
        <TokenInputButton
          onClick={onEstimate}
          loading={loading}
          disabled={!amount}
        >
          Request for Quote
        </TokenInputButton>
      </TokenInputContent>

      <ModalWrapper
        open={modalIsVisible}
        onCancel={onCancelModal}
        title={<ModalHeader>Select a Token</ModalHeader>}
        centered
        footer={null}
        width={400}
      >
        <div>
          <SearchBar
            {...{ isMobile, searchQuery, setSearchQuery }}
            className="search-input"
          />
          <CommonTokenOffer>
            {COMMON_TOKENS.map(({ symbol, logoURI, address }) => (
              <div
                key={address}
                onClick={() => {
                  if (selectedToken === 'token0') {
                    updateToken0(symbol);
                    setAddress0(address);
                  } else if (selectedToken === 'token1') {
                    updateToken1(symbol);
                    setAddress1(address);
                  }

                  onCancelModal();
                }}
              >
                <img src={logoURI} alt={symbol} width={36} height={36} />
                <span>{symbol}</span>
              </div>
            ))}
          </CommonTokenOffer>
          <List
            dataSource={tokens}
            renderItem={({ symbol, logoURI, address }) => (
              <List.Item
                key={address}
                onClick={() => {
                  if (selectedToken === 'token0') {
                    updateToken0(symbol);
                    setAddress0(address);
                  } else if (selectedToken === 'token1') {
                    updateToken1(symbol);
                    setAddress1(address);
                  }

                  onCancelModal();
                }}
              >
                <ListItem>
                  <img src={logoURI} alt={symbol} width={36} height={36} />
                  <span>{symbol}</span>
                </ListItem>
              </List.Item>
            )}
          />
        </div>
      </ModalWrapper>
    </TokenInputWrapper>
  );
};

export default TokenInput;
