import React, { useMemo } from 'react';
import ReactEChart, { EChartsInstance } from 'echarts-for-react';
import theme from '../../../theme';

interface Props {
  darkMode: boolean;
  timeRange: string;
}

const LineChart: React.FC<Props> = ({ darkMode, timeRange }) => {
  const option = {
    tooltip: {
      trigger: 'item',
      axisPointer: {
        type: 'cross',
        snap: true,
        label: {
          show: false,
          color: darkMode ? theme.colors.black : theme.colors.white,
          backgroundColor: darkMode ? theme.colors.creme : theme.colors.primary,
        },
      },
    },
    grid: {
      top: 16,
      left: 0,
      right: 16,
      bottom: 32,
    },
    legend: {
      show: false,
    },
    xAxis: {
      type: 'category',
      data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      show: false,
    },
    yAxis: {
      type: 'value',
      show: false,
    },
    series: [
      {
        data: [820, 932, 901, 934, 1290, 1330, 1320],
        type: 'line',
        smooth: true,
      },
    ],
  };

  return useMemo(
    () => (
      <ReactEChart
        option={option}
        style={{
          height: '100%',
          width: '100%',
        }}
      />
    ),
    [darkMode, timeRange]
  );
};

export default LineChart;
