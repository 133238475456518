import React, { useContext } from 'react';
import { GlobalContext } from '../../context/globalState';
import { StyledBurger } from './styles';

const Burger: React.FC = () => {
  const { sidebarOpen, setSidebarOpen } = useContext(GlobalContext);

  return (
    <StyledBurger
      $open={sidebarOpen}
      onClick={() => setSidebarOpen(!sidebarOpen)}
    >
      <div />
      <div />
      <div />
    </StyledBurger>
  );
};

export default Burger;
