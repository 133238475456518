import { Alert, Button } from 'antd';
import React from 'react';

interface State {
  hasError: boolean;
  message: string;
}

interface Props {
  children: React.ReactNode;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, message: '' };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, message: error.message };
  }

  componentDidCatch() {
    // logErrorToMyService(error, errorInfo);
  }

  onPageRefresh = () => {
    window.location.reload();
  };

  render() {
    if (this.state.hasError) {
      return (
        <Alert
          message="Ooops! Something went wrong."
          showIcon
          description={this.state.message}
          type="error"
          action={
            <Button
              size="small"
              type="default"
              onClick={this.onPageRefresh}
            >
              Refresh
            </Button>
          }
        />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
