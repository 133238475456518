import styled from 'styled-components';
import { GenericInput } from '../../shared/styles';
import theme from '../../theme';

export const Wrapper = styled.aside`
  width: 100%;
  height: 100%;
  grid-area: sidebar;
`;

export const ContentWrapper = styled.nav`
  position: fixed;
  height: 100%;
  width: inherit;
  max-width: 20rem;
  display: flex;
  flex-direction: column;
  z-index: 10;
  padding: 1rem;
  gap: 1rem;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media ${theme.mediaQueries.mobile} {
    max-width: 100%;
    bottom: var(--topbar-height);
    height: calc(100% - var(--topbar-height));
    padding-bottom: 0;
  }
`;

export const FilterInput = styled(GenericInput)`
  max-width: inherit;
  font-size: 1.2rem;
  background-color: var(--color-background);
  border: none;

  .ant-input-prefix {
    margin-right: 0.5rem;
  }

  svg {
    color: var(--color-text);
  }

  input {
    font-size: 1rem;
    color: var(--color-text);
    background-color: var(--color-background);
  }

  &:hover &:focus {
    filter: brightness(95%);
  }

  @media ${theme.mediaQueries.mobile} {
    font-size: 0.875rem;
    padding: 0.25rem 0.5rem;
    border-bottom: 1px solid var(--color-border);

    &.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
      border-color: var(--color-border);
      box-shadow: none;
    }
  }
`;

export const FiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;

  .ant-checkbox-group-item,
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-right: 0;
    margin-left: 0;
  }

  .ant-checkbox-wrapper {
    color: var(--color-text);

    .ant-checkbox-disabled + span {
      color: var(--color-disabled);
    }

    .ant-checkbox-inner {
      border-color: transparent !important;
    }

    &.ant-checkbox-wrapper-checked:not(.ant-checkbox-wrapper-disabled) {
      .ant-checkbox-inner {
        &.ant-checkbox-inner {
          background-color: var(--color-background-accent);

          &:after {
            border-color: var(--color-text);
          }
        }
      }
    }

    [data-theme='dark']
      &.ant-checkbox-wrapper-checked.ant-checkbox-wrapper-disabled {
      .ant-checkbox-inner {
        background-color: var(--color-background-accent);

        &:after {
          border-color: var(--color-gray-3);
        }
      }
    }
  }

  .ant-select {
    min-width: 5.5rem;

    .ant-select-selector {
      border-color: transparent;
    }
  }

  .ant-divider-horizontal {
    margin-bottom: 0;
    margin-top: 0.5rem;
  }
`;

export const ResultsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 1rem;
  overflow-y: auto;
`;

export const ResultsListTitle = styled.h4`
  color: var(--color-text);
  margin-left: 0.5rem;
  margin-bottom: 0;
`;

export const ResultsList = styled.ul`
  list-style: none;

  li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    margin-bottom: 0.5rem;
    padding: 0.5rem;
    border-radius: 0.25rem;
    background-color: var(--color-background-accent);
    color: var(--color-accent-2);
    transition: filter, border 0.3s ease;
    border: 1px solid transparent;

    & > svg,
    img {
      margin-right: 0.5rem;
    }

    & > div {
      max-width: 12.5rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:hover {
      filter: brightness(75%);

      .select-pair-box {
        display: flex;
        cursor: pointer;
        color: var(--color-accent-2);
      }
    }
  }

  .ant-empty-normal {
    margin: 0.5rem 2rem;
  }

  .ant-empty-description {
    color: var(--color-help-text);
  }
`;

export const PairBoxExtra = styled.div`
  display: none;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  right: 0;
  z-index: 10;
  padding: 0.25rem 0.5rem;
  color: var(--color-primary);
  text-transform: uppercase;
  font-size: 1.5rem;

  svg:hover {
    transition: transform 0.3s ease;
    transform: scale(1.1);
  }
`;
