import styled from 'styled-components';
import { GenericButton } from '../../shared/styles';
import theme from '../../theme';

export const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;

  form {
    max-width: 600px;

    @media ${theme.mediaQueries.mobile} {
      max-width: 100%;
    }
  }

  .ant-form-item {
    min-width: 300px;
    width: 100%;

    input,
    button {
      width: 100%;
    }

    input:hover,
    .ant-input:focus,
    .ant-input-focused,
    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper-focused {
      border-color: var(--color-secondary);
    }
  }
`;

export const LoginButton = styled(GenericButton)`
  && {
    padding-top: 6.4px !important;
    line-height: inherit;
    font-weight: normal;
    background-color: var(--color-secondary);
    color: var(--color-white);
    border: none !important;

    @media ${theme.mediaQueries.mobile} {
      font-size: 0.75rem;
      padding: 0.25rem 0.5rem;

      svg {
        font-size: 1rem;
      }
    }
  }
`;
