import { useState, useEffect, useContext } from 'react';
import { GlobalContext } from '../context/globalState';
import theme from '../theme';

const useMediaQuery = (query: string = theme.mediaQueries.mobile) => {
  const [matches, setMatches] = useState(false);
  const { setIsMobile } = useContext(GlobalContext);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);
    window.addEventListener('resize', listener);

    setIsMobile(media.matches);
    return () => window.removeEventListener('resize', listener);
  }, [matches, query]);

  return matches;
};

export default useMediaQuery;
