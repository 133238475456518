import React, { useState } from 'react';
import {
  FilterModalFooter,
  FilterModalSection,
  FilterModalTitle,
  FilterModalWrapper,
  HeaderSectionExtra,
  SliderContainer,
  TagContainer,
} from './styles';
import Button from '../Button/Button';
import CheckableTag from 'antd/lib/tag/CheckableTag';
import { HiLink } from 'react-icons/hi';
import { SlEqualizer } from 'react-icons/sl';
import Slider, { SliderMarks } from 'antd/lib/slider';
import { FiBarChart } from 'react-icons/fi';
import { BiReset } from 'react-icons/bi';
import { Divider, Steps, Tabs, TabsProps } from 'antd';

export interface FiltersModal {
  selectedChains: string[];
  selectedProtocols: string[];
  volume24h: number;
  dilutedMarketCap: number;
}

interface Props {
  visible: boolean;
  onClose: () => void;
  onApply: (filters: FiltersModal) => void;
}

const chainData = ['Ethereum', 'Optimism', 'Avalanche', 'Binance Smart Chain'];
const protocolData = ['Uniswap V3', 'Uniswap V2', 'Sushiswap', 'Balancer'];
const volume24hMarks: SliderMarks = {
  0: '<100k',
  15: '1M',
  30: '10M',
  50: '100M',
  70: '500M',
  90: '1B',
  100: '>1B',
};

const dilutedMarketCapMarks: SliderMarks = {
  0: '<100k',
  15: '1M',
  30: '10M',
  50: '100M',
  70: '500M',
  90: '1B',
  100: '>10B',
};

const dilutedMarketCapToValue = (dilutedMarketCap: string) => {
  switch (dilutedMarketCap) {
    case '<100k':
      return 100000;
    case '1M':
      return 1000000;
    case '10M':
      return 10000000;
    case '100M':
      return 100000000;
    case '500M':
      return 500000000;
    case '1B':
      return 1000000000;
    case '>10B':
      return 0;
    default:
      return 1000000000;
  }
};

const volume24hToValue = (volume24h: string) => {
  switch (volume24h) {
    case '<100k':
      return 100000;
    case '1M':
      return 1000000;
    case '10M':
      return 10000000;
    case '100M':
      return 100000000;
    case '500M':
      return 500000000;
    case '1B':
      return 1000000000;
    case '>1B':
      return 0;
    default:
      return 1000000000;
  }
};

const FilterModal: React.FC<Props> = ({ visible, onClose, onApply }) => {
  const [filters, setFilters] = useState<FiltersModal>({
    selectedChains: ['Ethereum'],
    selectedProtocols: ['Uniswap V3'],
    volume24h: 100,
    dilutedMarketCap: 100,
  });

  const onApplyFilters = () => {
    onApply(prepareFilterValues(filters));
    onClose();
  };

  const prepareFilterValues = (filters: FiltersModal) => {
    const preparedFilters = { ...filters };
    preparedFilters.dilutedMarketCap = dilutedMarketCapToValue(
      dilutedMarketCapMarks[filters.dilutedMarketCap] as string
    );

    preparedFilters.volume24h = volume24hToValue(
      volume24hMarks[filters.volume24h] as string
    );

    return preparedFilters;
  };

  const onClearFilters = () => {
    setFilters({
      selectedChains: ['Ethereum'],
      selectedProtocols: [],
      volume24h: 100,
      dilutedMarketCap: 100,
    });
  };

  const handleChange = (
    chain: string,
    checked: boolean,
    name: 'chain' | 'protocol'
  ) => {
    if (name === 'chain') {
      const nextSelectedChains = checked
        ? [...filters.selectedChains, chain]
        : filters.selectedChains.filter((c) => c !== chain);

      setFilters({ ...filters, selectedChains: nextSelectedChains });
    }
    if (name === 'protocol') {
      const nextSelectedProtocols = checked
        ? [...filters.selectedProtocols, chain]
        : filters.selectedProtocols.filter((c) => c !== chain);

      setFilters({ ...filters, selectedProtocols: nextSelectedProtocols });
    }
  };

  const onSliderChange = (value: number, type: 'volume' | 'marketCap') => {
    if (type === 'volume') setFilters({ ...filters, volume24h: value });
    if (type === 'marketCap')
      setFilters({ ...filters, dilutedMarketCap: value });
  };

  const onSliderReset = (type: 'volume' | 'marketCap') => {
    if (type === 'volume') setFilters({ ...filters, volume24h: 15 });
    if (type === 'marketCap') setFilters({ ...filters, dilutedMarketCap: 100 });
  };

  const { selectedChains, selectedProtocols, volume24h, dilutedMarketCap } =
    filters;

  const tabs: TabsProps['items'] = [
    {
      key: '1',
      label: `General`,
      children: (
        <div>
          <FilterModalSection>
            <h3>
              <HiLink />
              <span>Chains</span>
            </h3>
            <TagContainer>
              {chainData.map((tag) => (
                <CheckableTag
                  key={tag}
                  checked={selectedChains.includes(tag)}
                  onChange={(checked) => handleChange(tag, checked, 'chain')}
                >
                  {tag}
                </CheckableTag>
              ))}
            </TagContainer>
          </FilterModalSection>
          <FilterModalSection>
            <h3>
              <SlEqualizer />
              <span>Protocols</span>
            </h3>
            <TagContainer>
              {protocolData.map((tag) => (
                <CheckableTag
                  key={tag}
                  checked={selectedProtocols.includes(tag)}
                  onChange={(checked) => handleChange(tag, checked, 'protocol')}
                >
                  {tag}
                </CheckableTag>
              ))}
            </TagContainer>
          </FilterModalSection>
          <FilterModalSection>
            <h3>
              <FiBarChart />
              <span>Volume (24h)</span>
              <HeaderSectionExtra>
                {volume24hMarks[volume24h] as string} USD
                <Divider type="vertical" />
                <BiReset onClick={() => onSliderReset('volume')} />
              </HeaderSectionExtra>
            </h3>
            <SliderContainer>
              <Slider
                marks={volume24hMarks}
                onChange={(value) => onSliderChange(value, 'volume')}
                value={volume24h}
                step={null}
                defaultValue={15}
                tooltip={{ open: false }}
              />
            </SliderContainer>
          </FilterModalSection>
          {/* <FilterModalSection>
            <h3>
              <FiBarChart />
              <span>Fully Diluted Market Cap</span>
              <HeaderSectionExtra>
                {dilutedMarketCapMarks[dilutedMarketCap] as string} USD
                <Divider type="vertical" />
                <BiReset onClick={() => onSliderReset('marketCap')} />
              </HeaderSectionExtra>
            </h3>
            <SliderContainer>
              <Slider
                marks={dilutedMarketCapMarks}
                onChange={(value) => onSliderChange(value, 'marketCap')}
                value={dilutedMarketCap}
                step={null}
                defaultValue={50}
                tooltip={{ open: false }}
              />
            </SliderContainer>
          </FilterModalSection> */}
        </div>
      ),
    },
    {
      key: '2',
      label: `Wizard`,
      children: (
        <div>
          <FilterModalSection>
            <Steps
              direction="vertical"
              current={1}
              items={[
                {
                  title: 'Filter 1',
                  description: 'Filter added',
                },
                {
                  title: 'Filter 2',
                  description: 'Filter in progress',
                },
                {
                  title: 'Filter 3',
                  description: 'More additional filters if needed',
                },
              ]}
            />
          </FilterModalSection>
        </div>
      ),
    },
    {
      key: '3',
      label: `Advanced`,
      children: (
        <div>
          <FilterModalSection>Advanced Settings</FilterModalSection>
        </div>
      ),
    },
  ];

  return (
    <FilterModalWrapper
      title={<FilterModalTitle>Filters</FilterModalTitle>}
      open={visible}
      onOk={() => { }}
      onCancel={onClose}
      centered
      transitionName=""
      maskTransitionName=""
      width={600}
      footer={
        <FilterModalFooter>
          <Button danger onClick={onClearFilters}>
            Clear All Filters
          </Button>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="primary" onClick={onApplyFilters}>
            Apply
          </Button>
        </FilterModalFooter>
      }
    >
      <Tabs items={[tabs[0]]} />
    </FilterModalWrapper>
  );
};

export default FilterModal;
