import { Segmented, Table, Tag } from 'antd';
import styled from 'styled-components';
import theme from '../../theme';

export const Wrapper = styled.div`
  display: grid;
  grid-template-rows: auto auto auto 1fr;
  padding: 0 1rem;
  overflow-y: auto;
  height: calc(100vh - var(--header-height));
  margin: 0 auto;
  position: relative;
  max-width: 100rem;
  width: 100%;
  background-color: var(--color-background);

  @media ${theme.mediaQueries.mobile} {
    overflow-x: hidden;
    max-width: 100vw;
    height: 100%;
  }
`;

export const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
  color: var(--color-text);

  @media ${theme.mediaQueries.mobile} {
    font-size: 1.2rem;
  }
`;

export const Subtitle = styled.h2`
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
  color: var(--color-help-text);

  @media ${theme.mediaQueries.mobile} {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 0;
`;

export const TitleActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
`;

export const TableWrapper = styled<any>(Table)`
  @media ${theme.mediaQueries.mobile} {
    max-width: 100vw;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &&& {
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td,
    .ant-table tfoot > tr > th,
    .ant-table tfoot > tr > td {
      padding: 0.5rem;
      background-color: var(--color-background-accent);
      color: var(--color-accent-2);
      line-height: 3.125rem;

      @media ${theme.mediaQueries.mobile} {
        padding: 0.375rem;
        font-size: 0.75rem;
        line-height: 1.375rem;
      }
    }

    .ant-table-thead > tr > th {
      max-width: 10rem;
      line-height: 1.375rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: var(--color-primary);
      background-color: var(--color-creme);
    }

    .table-row-custom {
      &:hover {
        filter: brightness(95%);
      }
    }
  }
`;

export const TableName = styled.span`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 6px;
  line-height: 1rem;
  font-size: 1rem;
  font-weight: 600;
  color: var(--color-text);
  cursor: pointer;

  span:last-child {
    color: var(--color-help-text);
    font-weight: normal;
  }
`;

export const ChartWrapper = styled.span`
  cursor: pointer;
`;

export const PriceChangeWrapper = styled.span<any>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: ${({ $isPositive }) =>
    $isPositive ? theme.colors['antd-green'] : theme.colors.secondary};
`;

export const TitleWithInfoWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 0.25rem;
  gap: 0.25rem;
`;

export const InsightsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.5rem;
  margin-bottom: 1.5rem;
  width: 100%;
`;

export const InsightsBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: rgba(88, 102, 126, 0.08) 0px 4px 24px,
    rgba(88, 102, 126, 0.12) 0px 1px 2px;
  height: 12rem;
  background-color: var(--color-background-accent);
`;

export const BoxHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  // title
  div:first-child {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    font-size: 1.25rem;
  }

  button {
    display: flex;
    align-items: center;
    color: var(--color-accent);
    padding-left: 0;
    padding-right: 0;

    svg {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
`;

export const FiltersWrapper = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
  transition: opacity 0.15s ease-in-out;
  position: relative;
  min-height: 2.5rem;

  .search-input {
    font-size: 1rem;
    min-width: 18rem;
  }
`;

export const FilterTag = styled<any>(Tag)`
  font-size: 18px;
  font-weight: bold;
  background-color: var(--color-white);
  border-color: var(--color-white);
  border-radius: 4px;
  color: ${({ $selected }) =>
    $selected ? 'var(--color-tercery)' : 'var(--color-black-2)'};
  padding: 8px 12px;
  cursor: pointer;
  min-width: 100px;

  & > span {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
  }

  &:hover {
    filter: brightness(95%);
  }

  @media ${theme.mediaQueries.mobile} {
    font-size: 0.75rem;
    padding: 0.25rem;
    width: 70px;
    min-width: 70px;
    margin: 0;
  }
`;

export const SegmentedWrapper = styled<any>(Segmented)`
  .ant-segmented-item:not(.ant-segmented-item-selected) {
    background-color: ${({ $isDarkMode }) =>
      $isDarkMode ? 'var(--color-gray-3)' : 'initial'};
    color: ${({ $isDarkMode }) =>
      $isDarkMode ? 'var(--color-gray-2)' : 'initial'};
  }

  @media ${theme.mediaQueries.mobile} {
    .ant-segmented-item-label {
      font-size: 0.75rem !important;
      padding: 0rem 0.5rem !important;
    }
  }
`;

export const AnchorPriceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  position: absolute;
  right: 0;
`;

export const AnchorTitle = styled.span`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  font-size: 1rem;

  svg {
    position: relative;
    bottom: 2px;
    cursor: pointer;
  }
`;
