import React, { useContext, useEffect, useState } from 'react';
import {
  AnchorPriceWrapper,
  AnchorTitle,
  FilterTag,
  FiltersWrapper,
  SegmentedWrapper,
} from '../../views/WinnersLosers/styles';
import { AiFillCaretDown } from 'react-icons/ai';
import { Divider, Dropdown, MenuProps, Popover, Segmented } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { GlobalContext } from '../../context/globalState';
import SearchBar from '../Sidebar/SearchBar';

const pathnameToSegment = (pathname: string) => {
  pathname = pathname.replace('/tokens', '');

  switch (pathname) {
    case '/tokens':
      return 'Ranking';
    case '/winners-losers':
      return 'Winners & Losers';
    case '/trending':
      return 'Trending';
    case '/new':
      return 'Recently Added';
    default:
      return 'Ranking';
  }
};

const segmentToPathname = (segment: string | number) => {
  switch (segment) {
    case 'Ranking':
      return '/ranking';
    case 'Winners & Losers':
      return '/winners-losers';
    case 'Trending':
      return '/trending';
    case 'Recently Added':
      return '/new';
    default:
      return '/tokens/ranking';
  }
};

interface Props {
  visible: boolean;
  showFilters: boolean;
  setShowFilters: (show: boolean) => void;
  anchor: string;
  setAnchor: (anchor: string) => void;
  loading?: boolean;
  query: string;
  setQuery: (query: string) => void;
}

const FilterBar: React.FC<Props> = ({
  visible = true,
  showFilters = false,
  setShowFilters = () => null,
  anchor,
  setAnchor,
  loading,
  query,
  setQuery,
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { darkMode, isMobile } = useContext(GlobalContext);
  const [segment, setSegment] = useState<string | number>(
    pathnameToSegment(pathname)
  );

  if (!visible) return null;

  const onChangeSegment = (value: string | number) => {
    setSegment(value);
    navigate(`/tokens${segmentToPathname(value)}`);
  };

  const anchors: MenuProps['items'] = [
    {
      label: 'USD',
      key: '0',
      onClick: () => setAnchor('USD'),
    },
    {
      label: 'WBTC',
      key: '1',
      onClick: () => setAnchor('WBTC'),
    },
    {
      label: 'WETH',
      key: '2',
      onClick: () => setAnchor('WETH'),
    },
  ];

  const segments = ['Ranking'];

  if (isMobile) {
    segments.splice(1, 1);
  }

  return (
    <FiltersWrapper $visible={visible}>
      {/* <SegmentedWrapper
        options={segments}
        value={segment}
        onChange={onChangeSegment}
        $isDarkMode={darkMode}
        size="large"
      /> */}
      <SearchBar
        {...{ isMobile, searchQuery: query, setSearchQuery: setQuery }}
        className="search-input"
      />

      {!isMobile && <Divider type="vertical" />}
      <AnchorPriceWrapper>
        {!isMobile && (
          <AnchorTitle>
            <Popover
              title="Anchor Price"
              placement="left"
              overlayStyle={{ width: '300px' }}
              content={
                <div>
                  <p>
                    Anchor price is a token or weighted group of tokens used to
                    price all other tokens. Top 3 are USD, BTC, ETH.
                  </p>
                </div>
              }
            >
              <InfoCircleOutlined />
            </Popover>
            Anchor Price
          </AnchorTitle>
        )}
        <Dropdown
          menu={{ items: anchors }}
          trigger={['click']}
          disabled={loading}
        >
          <FilterTag>
            <span>
              {anchor} <AiFillCaretDown />
            </span>
          </FilterTag>
        </Dropdown>
      </AnchorPriceWrapper>
    </FiltersWrapper>
  );
};

export default FilterBar;
