import { createGlobalStyle } from 'styled-components';
import PolySansNeutralOtf from './assets/fonts/PolySans-Neutral.otf';
import PolySansSlimOtf from './assets/fonts/PolySans-Slim.otf';

import theme from './theme';

export default createGlobalStyle`
  @font-face {
    font-family: 'PolySans Neutral';
    src: local('PolySans Neutral'), url(${PolySansNeutralOtf}) format('opentype');
    font-style: normal;
  };

  @font-face {
    font-family: 'PolySans Slim';
    src: local('PolySans Slim'), url(${PolySansSlimOtf}) format('opentype');
    font-style: normal;
  };

  :root {
    --header-height: 5.625rem;
    --topbar-height: 3.75rem;

    --color-primary: ${theme.colors.primary};
    --color-secondary: ${theme.colors.secondary};
    --color-tercery: ${theme.colors.tercery};
    --color-black: ${theme.colors.black};
    --color-black-2: ${theme.colors.black2};
    --color-creme: ${theme.colors.creme};
    --color-gray: ${theme.colors.gray};
    --color-gray-2: ${theme.colors.gray2};
    --color-gray-3: ${theme.colors.gray3};
    --color-blue: ${theme.colors.blue};
    --color-light-gray: ${theme.colors['light-gray']};
    --color-antd-green: ${theme.colors['antd-green']};
    --color-white: ${theme.colors.white};

    --color-text: ${theme.colors.black};
    --color-help-text: ${theme.colors['light-gray']};
    --color-background: ${theme.colors.gray};
    --color-background-2: ${theme.colors.white};
    --color-background-3: ${theme.colors.gray2};
    --color-background-accent: ${theme.colors.white};
    --color-border: ${theme.colors['light-gray']};
    --color-accent: ${theme.colors.secondary};
    --color-accent-2: ${theme.colors.primary};
    --color-disabled: ${theme.colors.gray2};
  }

  [data-theme='dark'] {
    --color-text: ${theme.colors.white};
    --color-help-text: ${theme.colors.creme};
    --color-background: ${theme.colors.black};
    --color-background-2: ${theme.colors.black};
    --color-background-3: ${theme.colors.black2};
    --color-background-accent: ${theme.colors.black2};
    --color-border: ${theme.colors.creme};
    --color-accent: ${theme.colors.secondary};
    --color-accent-2: ${theme.colors.creme};
    --color-disabled: ${theme.colors.gray3};
}

  body {
    margin: 0;
    font-family: 'PolySans Neutral', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--color-text);
    background-color: var(--color-background);
    overflow: hidden;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  #root {
    display: grid;
    grid-template-rows: auto 1fr;
    min-height: 100vh;
    height: 100vh;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  .hide {
    display: none;
  }

  perspective-viewer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .ant-popover-title {
    font-weight: bold;
  }

  // Antd overrides header connect to metamask dropdown
  .wallet-dropdown-menu {
    svg {
      font-size: 1rem;
    }
  }

  // Antd overrides for superswap token select dropdown
  .token-input-popup {
    min-width: 10rem !important;

    .ant-select-item {
      min-width: 180px;
      font-size: 1.5rem;
      min-height: 3rem;
      display: flex;
      align-items: center;
    }

    .ant-select-item-option-content {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      
      img {
        position: relative;
        bottom: 1px;
      }
    }
  }
`;
