import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { Metadata, ParsedPairData } from '.';
import { PoolWrapper, ShareIconWrapper, TableWrapper } from './styles';
import { GrShare } from 'react-icons/gr';
import { GlobalContext } from '../../context/globalState';
import {
  currencyFormatter,
  formatNumberToDecimal,
  getScanUrl,
  openExternalLink,
} from '../../shared/helpers';
import { Tooltip } from 'antd';
import { SupportedChains } from '../../shared/constants';

import uniswapV2Icon from '../../assets/icons/uniswap-v2-logo.svg';
import uniswapV3Icon from '../../assets/icons/uniswap-v3-logo.png';

interface SwapTableProps {
  token0?: Metadata;
  token1?: Metadata;
  data: ParsedPairData[];
  chain: SupportedChains;
  visible: boolean;
  singleCurrencyData?: any;
  style?: React.CSSProperties;
}

interface TableRowProps {
  token: number | string;
  tokenInverted: number | string;
  date: string;
  key: number;
  txId: string;
  blockNumber: number;
  uniswapVersion?: string;
  volume?: number | string;
  pool?: string;
  price?: number;
}

interface ColumnProps {
  title?: string;
  dataIndex?: string;
  key?: string;
  render?: (param: any, record: any) => JSX.Element | string;
}

const poolToIcon = {
  2: uniswapV2Icon,
  3: uniswapV3Icon,
};

const SwapTable: React.FC<SwapTableProps> = ({
  token0,
  token1,
  data,
  chain,
  visible,
  singleCurrencyData,
  style,
}) => {
  const [page, setPage] = useState(1);
  const { isMobile } = useContext(GlobalContext);
  const [tableData, setTableData] = useState<TableRowProps[]>([]);
  const [columns, setColumns] = useState<ColumnProps[]>([]);
  const [dataParsed, setDataParsed] = useState<ParsedPairData[]>(
    [...data]
      .reverse()
      .filter((swap: ParsedPairData) => Number.isFinite(swap.price))
  );

  const onChange = (page: number) => {
    setPage(page);
  };

  const onRowClick = (record: TableRowProps) => {
    const url = getScanUrl(chain, record.txId);
    if (url) {
      openExternalLink(url);
    }
  };

  useEffect(() => {
    setDataParsed(
      [...data]
        .reverse()
        .filter((swap: ParsedPairData) => Number.isFinite(swap.price))
    );
  }, [data]);

  useEffect(() => {
    let tableData = [];

    if (isMobile) {
      tableData = dataParsed.map((pair, i) => ({
        token: pair.price,
        tokenInverted: 1 / pair.price,
        date: moment(pair.date).format('l'),
        txId: pair.txId,
        ...(!singleCurrencyData && {
          volume: formatNumberToDecimal(pair.volume, 2),
        }),
        pool: pair.pool,
        volume: pair.volume,
        blockNumber: pair.blockNumber,
        uniswapVersion: pair.uniswapVersion,
        key: i,
      }));
    } else {
      tableData = dataParsed.map((pair, i) => ({
        token: pair.price,
        tokenInverted: 1 / pair.price,
        date: moment(pair.date).format('lll'),
        pool: pair.pool,
        txId: pair.txId,
        ...(!singleCurrencyData && { volume: pair.volume }),
        blockNumber: pair.blockNumber,
        uniswapVersion: pair.uniswapVersion,
        volume: pair.volume,
        key: i,
      }));
    }

    setTableData(tableData);
  }, [dataParsed, isMobile]);

  useEffect(() => {
    const title = singleCurrencyData
      ? `${singleCurrencyData.name || singleCurrencyData.symbol || ''} Price`
      : token1?.name
        ? `${token1?.name} (for 1 ${token0?.symbol})`
        : 'Loading...';
    let columns = [];

    if (isMobile) {
      columns = [
        { title: 'Block', dataIndex: 'blockNumber' },
        {
          title: 'Price',
          dataIndex: 'token',
          render: (value: number) =>
            currencyFormatter({
              maximumFractionDigits: 2,
            }).format(value),
        },
        ...(!singleCurrencyData
          ? [
            { title: 'Volume', dataIndex: 'volume' },
            {
              title: 'Pool',
              dataIndex: 'pool',
              render: (pool: string, token: any) => (
                <PoolWrapper>
                  <img
                    src={(poolToIcon as any)[token.uniswapVersion]}
                    alt={pool}
                    style={{ width: 24, height: 24 }}
                  />
                  {pool}
                </PoolWrapper>
              ),
            },
          ]
          : []),
        {
          title: 'Date',
          dataIndex: 'date',
          render: (value: number) => `${moment(value).fromNow()}`,
        },
      ];
    } else {
      columns = [
        { title: 'Block Number', dataIndex: 'blockNumber' },
        {
          title: title,
          dataIndex: 'token',
          render: (value: number) =>
            currencyFormatter({
              maximumFractionDigits: 2,
            }).format(value),
        },
        {
          title: 'Volume',
          dataIndex: 'volume',
          render: (volume: number) =>
            `${volume.toFixed(2)} ${singleCurrencyData.symbol}`,
        },
        {
          title: 'Pool',
          dataIndex: 'pool',
          render: (pool: string, token: any) => (
            <PoolWrapper>
              <img
                src={(poolToIcon as any)[token.uniswapVersion]}
                alt={pool}
                style={{ width: 24, height: 24 }}
              />
              {`${pool.slice(0, 12)} ... ${pool.slice(pool.length - 8)}`}
            </PoolWrapper>
          ),
        },
        {
          title: 'Date',
          dataIndex: 'date',
          render: (value: number) =>
            `${moment(value).fromNow()} - (${moment(value).format('lll')})`,
        },
        {
          render: () => (
            <ShareIconWrapper className="blockexplorer-share-icon">
              <Tooltip title="view on block explorer">
                <GrShare />
              </Tooltip>
            </ShareIconWrapper>
          ),
        },
      ];
    }
    setColumns(columns);
  }, [isMobile, token1, token0, singleCurrencyData]);

  const pagination = {
    total: tableData.length,
    current: page,
    onChange,
    simple: isMobile,
  };

  return (
    <TableWrapper
      columns={columns}
      dataSource={tableData}
      bordered={false}
      style={style}
      pagination={pagination}
      onRow={(record: any) => ({
        onClick: () => onRowClick(record),
      })}
      rowClassName={'table-row-custom'}
      $visible={visible}
    />
  );
};

export default SwapTable;
