import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-right: 1rem;

  button {
    height: 1.75rem;
    line-height: 1.75rem;
  }

  .ant-switch-inner {
    display: flex;
  }

  .ant-switch {
    background: var(--color-gray);

    &.ant-switch-checked {
      background: var(--color-gray);
    }
  }

  .ant-switch-handle {
    top: 5px;

    &::before {
      background-color: var(--color-secondary);
    }
  }

  svg {
    width: 1.125rem;
    height: 1.125rem;
    color: var(--color-light-gray);
    fill: var(--color-light-gray);
  }
`;
