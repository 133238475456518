import { SelectedPair } from './globalState';

export const SET_CHAINS = 'SET_CHAINS';
export const SET_PAIRS_DATA = 'SET_PAIRS_DATA';
export const SET_MIN_SWAPS = 'SET_MIN_SWAPS';
export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY';
export const SET_IS_MOBILE = 'SET_IS_MOBILE';
export const SET_SIDEBAR_OPEN = 'SET_SIDEBAR_OPEN';
export const SET_DATA_IS_LOADING = 'SET_DATA_IS_LOADING';
export const SELECT_PAIR = 'SELECT_PAIR';
export const REMOVE_PAIR = 'REMOVE_PAIR';
export const SORT_PAIRS = 'SORT_PAIRS';
export const SET_WALLET_ADDRESS = 'SET_WALLET_ADDRESS';
export const SET_PAIR_VIEW_STATUS = 'SET_PAIR_VIEW_STATUS';
export const SET_PAIR_VIEW_DATA = 'SET_PAIR_VIEW_DATA';
export const SET_DARK_MODE = 'SET_DARK_MODE';
export const SET_USER = 'SET_USER';
export const SET_TOKENS_DATA = 'SET_TOKENS_DATA';
export const SET_TOKENS_LOADING = 'SET_TOKENS_LOADING';

const reducer = (state: any = {}, action: { type: string; payload?: any }) => {
  switch (action.type) {
    case SET_CHAINS:
      return {
        ...state,
        selection: { ...state.selection, chains: action.payload },
      };
    case SET_MIN_SWAPS:
      return {
        ...state,
        selection: { ...state.selection, minSwaps: action.payload },
      };
    case SET_SEARCH_QUERY:
      return {
        ...state,
        selection: { ...state.selection, searchQuery: action.payload },
      };
    case SET_PAIRS_DATA:
      const { pairs, chain } = action.payload;
      return {
        ...state,
        pairs: {
          ...state.pairs,
          [chain]: pairs,
        },
      };
    case SET_IS_MOBILE:
      return { ...state, isMobile: action.payload };
    case SET_DATA_IS_LOADING:
      return { ...state, isDataLoading: action.payload };
    case SET_SIDEBAR_OPEN:
      return { ...state, sidebarOpen: action.payload };
    case SELECT_PAIR:
      return {
        ...state,
        selection: {
          ...state.selection,
          selectedPairs: [...state.selection.selectedPairs, action.payload],
        },
      };
    case REMOVE_PAIR:
      return {
        ...state,
        selection: {
          ...state.selection,
          selectedPairs: state.selection.selectedPairs.filter(
            (pair: SelectedPair) => pair.address !== action.payload
          ),
        },
      };
    case SORT_PAIRS:
      return {
        ...state,
        selection: {
          ...state.selection,
          selectedPairs: action.payload,
        },
      };

    case SET_WALLET_ADDRESS:
      return {
        ...state,
        user: {
          ...state.user,
          walletAddress: action.payload,
        },
      };
    case SET_PAIR_VIEW_STATUS:
      return {
        ...state,
        pairViewGlobalState: {
          ...state.pairViewGlobalState,
          active: action.payload,
        },
      };
    case SET_PAIR_VIEW_DATA:
      return {
        ...state,
        pairViewGlobalState: {
          ...state.pairViewGlobalState,
          ...action.payload,
        },
      };
    case SET_DARK_MODE:
      return {
        ...state,
        darkMode: action.payload,
      };
    case SET_USER:
      if (action.payload === null) {
        return {
          ...state,
          user: null,
        };
      }
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };

    case SET_TOKENS_DATA:
      return {
        ...state,
        tokens: {
          ...state.tokens,
          data: action.payload,
        },
      };

    case SET_TOKENS_LOADING:
      return {
        ...state,
        tokens: {
          ...state.tokens,
          loading: action.payload,
        },
      };

    default:
      return state;
  }
};

export default reducer;
