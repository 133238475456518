import { memo } from 'react';
import { CustomNodeWrapper } from '../../styles';
import { Handle, Position } from 'reactflow';
import { currencyFormatter } from '../../../../shared/helpers';

interface Props {
  data: {
    name: string;
    icon: string;
    handleIds: string[];
    price?: number;
    noTarget?: boolean;
    noSource?: boolean;
  };
}

const CustomNode = ({
  data: { name, icon, noTarget, noSource, price, handleIds },
}: Props) => (
  <CustomNodeWrapper key={`${handleIds?.[0]}-${name}-${price}`} className='nodrag nowheel'>
    {icon && <img src={icon} alt={name} width={24} height={24} />}
    <div>{name}</div>
    <div className="price">{price?.toFixed(2)}</div>

    {!noTarget &&
      handleIds.map((id: string, i: number) => (
        <Handle
          type="target"
          position={Position.Left}
          style={{ top: 10 + i * 6 }}
          id={id}
        />
      ))}
    {!noSource &&
      handleIds.map((id: string, i: number) => (
        <Handle
          type="source"
          position={Position.Right}
          style={{ top: 10 + i * 6 }}
          id={id}
        />
      ))}
  </CustomNodeWrapper>
);

export default memo(CustomNode);
