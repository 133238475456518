import { Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React from 'react';
import { currencyFormatter, percentChange } from '../../../shared/helpers';
import {
  HotPairsSwapsWrapper,
  HotPairsTableWrapper,
  HotPairsTitleWrapper,
} from '../styles';
import { MdSwapVert } from 'react-icons/md';
import Icon from '../../Icon';
import { groupBy } from 'lodash';

interface Props {
  data: any;
  ready: boolean;
  isMobile: boolean;
  darkMode: boolean;
}

interface TableProps {
  rank: number;
  pair: string;
  price: number;
  price24h: number;
  numberOfSwaps: number;
  symbol: string;
  volume: number;
}

const HotPairsTable: React.FC<Props> = ({
  data,
  ready,
  isMobile,
  darkMode,
}) => {
  const groupedTx = groupBy(
    data.map((d: any) => d.rawData.flat()).flat(),
    (tx) => `${tx.token0_symbol}-${tx.token1_symbol}`
  );

  const sortedData = Object.entries(groupedTx)
    .map(([key, value]) => {
      let volumeTotal = 0;

      value.map((tx: any) => {
        const isInverted = tx.token0_symbol === key.split('-')[1];
        const price = isInverted ? tx.t0_tgt_price : tx.t1_tgt_price;
        const volume = isInverted ? tx.t0_tgt_volume : tx.t1_tgt_volume;

        volumeTotal += price * volume;
      });

      return {
        pair: key,
        numberOfSwaps: value.length,
        volume: volumeTotal,
      };
    })
    .sort((a: any, b: any) => b.numberOfSwaps - a.numberOfSwaps)
    .slice(0, 3)
    .map((d: any, i: number) => {
      return {
        ...d,
        rank: i + 1,
      };
    });

  const columns: ColumnsType<TableProps> = [
    {
      title: '',
      dataIndex: 'rank',
      sorter: (a, b) => (a.rank > b.rank ? 1 : -1),
      sortDirections: ['ascend', 'descend', 'ascend'],
      fixed: isMobile ? 'left' : false,
      width: isMobile ? 50 : undefined,
      render: (value, record) => {
        return <div>#{value}</div>;
      },
    },
    {
      title: 'Pair',
      dataIndex: 'pair',
      sorter: (a, b) => (a.pair > b.pair ? 1 : -1),
      sortDirections: ['ascend', 'descend', 'ascend'],
      fixed: isMobile ? 'left' : false,
      width: isMobile ? 150 : undefined,
      render: (pair, record) => {
        const [token0, token1] = pair.split('-');
        return (
          <HotPairsTitleWrapper>
            <Icon name={token0} />
            <span>{token0}</span>
            <span>/{token1}</span>
          </HotPairsTitleWrapper>
        );
      },
    },
    {
      title: 'Volume (24h)',
      dataIndex: 'volume',
      sorter: (a, b) => (a.volume > b.volume ? 1 : -1),
      sortDirections: ['ascend', 'descend', 'ascend'],
      width: isMobile ? 150 : undefined,
      align: 'right',
      render: (volume) => {
        const value = currencyFormatter({
          notation: 'compact',
          maximumFractionDigits: 2,
        }).format(volume);

        return <div>{value}</div>;
      },
    },
    {
      title: 'Number of Swaps',
      dataIndex: 'numberOfSwaps',
      sorter: (a, b) => (a.numberOfSwaps > b.numberOfSwaps ? 1 : -1),
      sortDirections: ['ascend', 'descend', 'ascend'],
      align: 'right',
      width: isMobile ? 150 : undefined,
      render: (value) => {
        return (
          <Tooltip
            title="Number of swaps in the last 24hr"
            overlayStyle={{ width: 130 }}
          >
            <HotPairsSwapsWrapper>
              <MdSwapVert />
              <span>{value}</span>
            </HotPairsSwapsWrapper>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <HotPairsTableWrapper>
      <Table
        columns={columns}
        dataSource={sortedData}
        showHeader={false}
        pagination={false}
        rowKey="rank"
        size="small"
        rowClassName={'table-row-custom'}
      />
    </HotPairsTableWrapper>
  );
};

export default HotPairsTable;
