import styled from 'styled-components';

export const Wrapper = styled.div`
  .custom-tooltip {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 0rem 1rem;
    margin: 0rem;

    .tooltip-value {
      font-weight: bold;
      color: var(--color-black);
      font-size: 1.5rem;
      margin-bottom: 0.75rem;
    }

    .tooltip-info {
      color: var(--color-black);
      font-size: 1.25rem;
      margin-bottom: 0.25rem;
    }

    .tooltip-date,
    .tooltip-hours {
      color: gray;
    }
  }
`;
