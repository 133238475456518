import React, { useContext } from 'react';
import { Button, Empty } from 'antd';
import { BiChevronRight } from 'react-icons/bi';
import {
  BoxHeader,
  InsightsBox,
  InsightsWrapper,
} from '../../views/TopTokens/styles';
import { GlobalContext } from '../../context/globalState';
import VolumeDominanceChart from './charts/VolumeDominanceChart';
import Volume24hrChart from './charts/Volume24hChart';
import MarketCapChart from './charts/MarketCapChart';
import { currencyFormatter } from '../../shared/helpers';
import HotPairsTable from './charts/HotPairsTable';
import { AiFillFire } from 'react-icons/ai';
import { WINDOW_TYPE_DECIMAL_TO_TIMESPAN } from '../../shared/constants';
import fireIcon from '../../assets/icons/fire-icon.png';

interface Props {
  visible?: boolean;
}

const MoreButton = () => (
  <Button type="link">
    More
    <BiChevronRight />
  </Button>
);

const Insights: React.FC<Props> = ({ visible }) => {
  const { tokens, isMobile, darkMode } = useContext(GlobalContext);

  const MostVolume = tokens?.data?.tokenList?.sort((a: any, b: any) =>
    a.price24h * a.volume24h > b.price24h * b.volume24h ? -1 : 1
  )?.[0];

  const VolumeDominance = {
    data: MostVolume || { rawData: [] },
    ready: MostVolume?.rawData.length > 0,
    symbol: MostVolume?.symbol,
    volumeByPercentTotal: !MostVolume
      ? 0
      : (100 * MostVolume?.price24h * MostVolume?.volume24h) /
      tokens.data?.tokenList?.reduce((acc: number, curr: any) => {
        return acc + curr.price24h * curr.volume24h;
      }, 0),
  };

  const Volume = {
    data: tokens?.data?.tokenList || [],
    ready: tokens?.data?.tokenList?.length > 0,
    volumeTotal: currencyFormatter({
      notation: 'compact',
      maximumFractionDigits: 2,
    }).format(
      tokens?.data?.tokenList?.reduce((acc: number, curr: any) => {
        return acc + curr.volume24h * curr.price24h;
      }, 0)
    ),
  };

  const HotPairs = {
    data: tokens?.data?.tokenList || [],
    ready: tokens?.data?.tokenList?.length > 0,
  };

  if (!visible) return null;

  return (
    <InsightsWrapper>
      <InsightsBox>
        <BoxHeader>
          <div>
            <img src={fireIcon} alt="fire icon" className="icon-hot" />
            <div>Hot Pairs (24hr)</div>
          </div>
          {/* <MoreButton /> */}
        </BoxHeader>
        <HotPairsTable
          {...{
            isMobile,
            darkMode,
            data: HotPairs.data,
            ready: HotPairs?.ready,
          }}
        />
      </InsightsBox>
      <InsightsBox>
        <BoxHeader>
          <div>Volume (24hr)</div>
          <span>{Volume.volumeTotal}</span>
        </BoxHeader>
        <Volume24hrChart
          {...{
            isMobile,
            darkMode,
            data: Volume.data,
            ready: Volume?.ready,
          }}
        />
      </InsightsBox>
      <InsightsBox>
        <BoxHeader>
          <div>{VolumeDominance.symbol} Volume Dominance (24h)</div>
          <span>{VolumeDominance.volumeByPercentTotal?.toFixed(2)}%</span>
        </BoxHeader>
        <VolumeDominanceChart
          {...{
            isMobile,
            darkMode,
            data: VolumeDominance.data,
            ready: VolumeDominance?.ready,
          }}
        />
      </InsightsBox>
      {/* <InsightsBox>
        <BoxHeader>
          <div>Market Cap</div>
          <span>{MarketCap.marketCapTotal}</span>
        </BoxHeader>
        <MarketCapChart
          {...{
            isMobile,
            darkMode,
            data: Volume.data,
            ready: Volume?.ready,
          }}
        />
      </InsightsBox> */}
    </InsightsWrapper>
  );
};

export default Insights;
