import { Button, Input } from 'antd';
import styled, { css } from 'styled-components';
import theme from '../theme';

export const SectionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  height: 4rem;
  opacity: 0.9;

  @media ${theme.mediaQueries.mobile} {
    padding: 0.25rem 0rem;
    height: 3.25rem;

    .ant-divider {
      margin: 0.5rem 0;
    }

    .ant-divider-inner-text {
      padding: 0 0.25rem;
    }
  }
`;

export const SectionTitle = styled.div`
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  color: var(--color-primary);

  @media ${theme.mediaQueries.mobile} {
    font-size: 0.875rem;
  }
`;

export const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const GenericInput = styled(Input)`
  max-width: 200px;
  padding: 6.4px 12px;
`;

export const GenericButton = styled(Button)`
  text-transform: uppercase;
  font-weight: bold;
  padding: 6.4px 15px;
  height: auto;
`;

export const backgroundImageMixin = css`
  background-size: 100%;
  margin: -1rem;
  padding: 1rem;
`;
