import styled from 'styled-components';

export const IconWrapper = styled.div<{ $size: string }>`
  display: flex;
  align-items: center;
  justify-content: center;

  svg,
  img {
    width: 1.25rem;
    height: 1.25rem;

    ${({ $size }) => $size === 'medium' && 'width: 1.5rem; height: 1.5rem;'};
    ${({ $size }) => $size === 'large' && 'width: 2rem; height: 2rem;'};
  }
`;
