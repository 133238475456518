import { Modal } from 'antd';
import styled from 'styled-components';

export const FilterModalWrapper = styled(Modal)`
  .ant-modal-body {
    min-height: 500px;
    max-height: 70vh;
    overflow-y: auto;
    padding: 0;

    background:
		/* Shadow covers */ linear-gradient(
        white 30%,
        rgba(255, 255, 255, 0)
      ),
      linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
      /* Shadows */
        radial-gradient(
          50% 0,
          farthest-side,
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0)
        ),
      radial-gradient(
          50% 100%,
          farthest-side,
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0)
        )
        0 100%;
    background:
		/* Shadow covers */ linear-gradient(
        white 30%,
        rgba(255, 255, 255, 0)
      ),
      linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
      /* Shadows */
        radial-gradient(
          farthest-side at 50% 0,
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0)
        ),
      radial-gradient(
          farthest-side at 50% 100%,
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0)
        )
        0 100%;
    background-repeat: no-repeat;
    background-color: white;
    background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;

    /* Opera doesn't support this in the shorthand */
    background-attachment: local, local, scroll, scroll;

    [class='ant-tabs-nav-wrap'] {
      padding: 0 1.5rem;
    }
    [class='ant-tabs-nav'] {
      margin-bottom: 0;
    }
  }
`;

export const FilterModalTitle = styled.h2`
  font-size: 1.5rem;
  margin: 0;
`;

export const FilterModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;

  button:first-child {
    margin-right: auto;
  }
`;

export const FilterModalSection = styled.div`
  padding: 1.5rem;
  border-bottom: 1px solid var(--color-gray-2);

  h3 {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1rem;
    margin: 0;
    margin-bottom: 1rem;
    color: var(--color-primary);

    svg {
      font-size: 1.25rem;
      position: relative;
      bottom: 1px;
    }
  }
`;

export const TagContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  overflow-x: auto;

  .ant-tag {
    font-size: 14px;
    border-radius: 0.5rem;
  }

  .ant-tag-checkable {
    border-color: var(--color-gray-2);
  }

  .ant-tag-checkable-checked {
    background-color: var(--color-secondary);
    border-color: transparent;
  }
`;

export const SliderContainer = styled.div`
  padding: 0 0.5rem;
  width: 100%;
`;

export const HeaderSectionExtra = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  margin-left: auto;

  .ant-divider-vertical {
    border-left-color: var(--color-gray-2);
  }

  svg {
    color: var(--color-secondary);
    font-size: 1.5rem !important;
    cursor: pointer;

    &:hover {
      transform: scale(1.1);
    }
  }
`;
