import React from 'react';
import { IconWrapper } from './styles';

type IconProps = {
  name: string;
  size?: 'small' | 'medium' | 'large';
  style?: React.CSSProperties;
};

const Icon: React.FC<IconProps> = ({ name, size = 'small', style }) => {
  return (
    <IconWrapper $size={size} style={style}>
      <img
        src={`https://assets.coincap.io/assets/icons/${name.toLowerCase()}@2x.png`}
        alt={name}
        loading='lazy'
      />
    </IconWrapper>
  );
};

export default Icon;
