import { Form, Input, message } from 'antd';
import React, { useContext, useState } from 'react';
import { GlobalContext } from '../../context/globalState';
import { getFetchInstance, IAuth } from '../../shared/api';
import { LoginButton, Wrapper } from './styles';

const Login: React.FC = () => {
  const { setUser, isMobile } = useContext(GlobalContext);
  const [loading, setLoading] = useState(false);

  const onFinish = async (user: IAuth) => {
    setLoading(true);

    try {
      /* credential test */
      const { status } = await getFetchInstance({
        url: `/api/eth/pairs/1000000`,
        auth: user,
        setUser,
      });

      if (status === 200) {
        setUser(user);
        message.success('Login successful');
      } else {
        message.error('Invalid credentials');
      }

    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Wrapper>
      <Form
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical"
      >
        <Form.Item
          label="Username"
          name="username"
          rules={[{ required: true, message: 'Please input your username!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item wrapperCol={!isMobile ? { offset: 16, span: 8 } : {}}>
          <LoginButton type="primary" htmlType="submit" loading={loading}>
            Login
          </LoginButton>
        </Form.Item>
      </Form>
    </Wrapper>
  );
};

export default Login;
