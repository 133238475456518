import styled from 'styled-components';
import theme from '../../theme';

export const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
  color: var(--color-text);

  @media ${theme.mediaQueries.mobile} {
    font-size: 1.2rem;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 2rem 0;

  & > div:first-child {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  @media ${theme.mediaQueries.mobile} {
    padding: 1rem 0;
  }
`;

export const TitleActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
`;
