import React from 'react';
import { Wrapper } from './styles';
import Overview from './components/Overview';
import Header from './components/Header';

interface Props { }

const Portfolio: React.FC<Props> = () => {
  return (
    <Wrapper>
      <Header />
      <Overview />
    </Wrapper>
  );
};

export default Portfolio;
