import React from 'react';
import { Title, TitleActions, TitleWrapper } from './styles';
import { Subtitle } from '../../views/WinnersLosers/styles';

interface Props {
  title: string;
  subtitle?: string;
  actions?: React.ReactNode[];
}

const Header: React.FC<Props> = ({ title, subtitle, actions }) => {
  return (
    <TitleWrapper>
      <div>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </div>
      <TitleActions>{actions}</TitleActions>
    </TitleWrapper>
  );
};

export default Header;
