import React from 'react';
import { Wrapper } from './styles';

interface Props {
  children?: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  danger?: boolean;
  type?: 'primary' | 'secondary' | 'default' | 'link' | 'text';
  size?: 'small' | 'medium' | 'large';
  shape?: 'circle' | 'round';
  className?: string;
  style?: React.CSSProperties;
  icon?: React.ReactNode;
}

const Button: React.FC<Props> = ({ children, ...props }) => {
  return <Wrapper {...props}>{children}</Wrapper>;
};

export default Button;
