import { Row, Button, Checkbox, Table } from 'antd';
import { AiOutlineCopy, AiOutlineSelect, AiOutlineCheck } from 'react-icons/ai';
import styled from 'styled-components';
import { GenericButton, SectionContainer } from '../../shared/styles';
import theme from '../../theme';

interface DragAndDropContainerProps {
  $isDragging: boolean;
  $isOver: boolean;
}

export const Wrapper = styled.div<DragAndDropContainerProps>`
  display: grid;
  gap: 2rem;
  padding: 0 2rem;
  width: 100%;
  background-color: ${({ $isOver }) =>
    $isOver ? theme.colors.creme : 'inherit'};
  opacity: ${({ $isDragging }) => ($isDragging ? 0.4 : 1)};
  height: 45rem;

  @media ${theme.mediaQueries.mobile} {
    gap: 1rem;
    padding: 0 1rem;
    height: auto;
  }
`;

export const GalleryWrapper = styled(Row)`
  margin: 2rem 0;
`;

export const ActionsWrapper = styled(Row)`
  margin-top: 2rem;
`;

export const PlaceholderButton = styled(Button)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  width: 100%;
  height: 100%;
`;

export const PageTitle = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 1.5rem;
  line-height: 1.5rem;
  color: var(--color-text);
  max-width: 60rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  & > div {
    display: flex;
    align-items: center;
    margin-right: 0.25rem;
  }

  svg,
  img {
    height: 1.5rem;
    width: 1.5rem;
  }

  .ant-divider {
    margin: 0 1rem;
    border-left-color: var(--color-border);
  }

  @media ${theme.mediaQueries.mobile} {
    font-size: 1.2rem;
    line-height: 1.4rem;
    white-space: normal;
  }
`;

export const DownloadCSVButton = styled(GenericButton)`
  && {
    padding-top: 6.4px !important;
    line-height: inherit;
    font-weight: normal;
    background-color: var(--color-secondary);
    color: var(--color-white);
    border: none !important;

    @media ${theme.mediaQueries.mobile} {
      font-size: 0.75rem;
      padding: 0.25rem 0.5rem;

      svg {
        font-size: 1rem;
      }
    }
  }
`;

export const ChartSectionContainer = styled<any>(SectionContainer)`
  display: ${({ $visible }) => ($visible ? 'block' : 'none')};
  height: auto;

  .custom-tooltip {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 0rem 1rem;
    margin: 0rem;

    .tooltip-value {
      font-weight: bold;
      color: var(--color-black);
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
    }

    .tooltip-date,
    .tooltip-hours {
      color: gray;
    }
  }

  @media ${theme.mediaQueries.mobile} {
    .custom-tooltip {
      padding: 0 0.5rem;

      .tooltip-value {
        font-size: 1rem;
      }
      .tooltip-date,
      .tooltip-hours {
        font-size: 0.8rem;
      }
    }
  }
`;

export const CheckboxInvert = styled(Checkbox)`
  font-weight: bold;
  text-transform: uppercase;
  color: var(--color-text);
  margin-right: 1rem;

  .ant-checkbox {
    transform: scale(1.2);
    margin-right: 0.25rem;
  }

  .ant-checkbox-inner {
    border-color: var(--color-background-accent);
    background: var(--color-background-accent);

    &:after {
      border-color: var(--color-text);
    }
  }

  @media ${theme.mediaQueries.mobile} {
    font-weight: normal;
    font-size: 0.75rem;
    margin-right: 0.25rem;
  }
`;

export const ChartControlsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;

  @media ${theme.mediaQueries.mobile} {
    flex-wrap: wrap;
    margin: 0;
    margin-bottom: 0.75rem;

    .ant-radio-group {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      text-align: center;
      width: 100%;
      place-items: stretch;
      gap: 0.5rem;
    }
  }
`;

export const ChartDateControlsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-right: 0.5rem;
  font-weight: bold;

  && {
    .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked) {
      color: var(--color-text);
    }

    .ant-radio-group-solid
      .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      color: var(--color-white);
      background: var(--color-secondary);
      border-color: var(--color-secondary);

      &:focus-within {
        box-shadow: none !important;
        border-color: var(--color-secondary);
      }
    }

    .ant-radio-button-wrapper {
      margin-right: 0.5rem;
      padding: 0 0.5rem;
      background: var(--color-background-accent);
      border-color: var(--color-background-accent);

      @media ${theme.mediaQueries.mobile} {
        margin-right: 0;
      }
    }

    .ant-radio-button-wrapper:not(:first-child)::before {
      display: none;
    }
  }

  @media ${theme.mediaQueries.mobile} {
    width: 100%;
    padding: 0;
    margin-top: 0.75rem;
    justify-content: center;
    font-weight: normal;
  }
`;

export const ChartViewTypeContainer = styled(ChartDateControlsWrapper)`
  && {
    margin-bottom: 2rem;

    .ant-radio-button-wrapper {
      font-size: 0.75rem;
      margin-right: 0rem;
      padding: 0 0.75rem;
      opacity: 0.8;
    }

    @media ${theme.mediaQueries.mobile} {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
      margin-top: 0;

      .ant-radio-button-wrapper {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 1.25rem;
        padding: 0 0.5rem;

        & > span {
          display: flex;
          align-items: center;
        }
      }
    }
  }
`;

export const TableWrapper = styled<any>(Table)`
  display: ${({ $visible }) => ($visible ? 'block' : 'none')};

  &&& {
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td,
    .ant-table tfoot > tr > th,
    .ant-table tfoot > tr > td {
      padding: 0.35rem 1rem;
      border-bottom: 1px solid transparent;
      background-color: var(--color-background-accent);
      color: var(--color-accent-2);

      @media ${theme.mediaQueries.mobile} {
        padding: 0.35rem 0.375rem;
        font-size: 0.75rem;
      }
    }

    .ant-table-thead > tr > th {
      max-width: 10rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: var(--color-text);
      background-color: var(--color-background-3);
      font-weight: bold;
    }

    .table-row-custom {
      cursor: pointer;

      &:hover {
        filter: brightness(75%);

        .blockexplorer-share-icon {
          visibility: visible;
        }
      }
    }

    .ant-table-pagination.ant-pagination {
      padding: 0 1rem;

      @media ${theme.mediaQueries.mobile} {
        padding: 0 0.5rem;
        margin: 0.5rem 0;
        font-size: clamp(0.5rem, 0.75rem, 0.75rem);

        input {
          min-width: 3rem;
        }
      }

      .ant-pagination-options {
        display: none;
      }

      .ant-pagination-item {
        background-color: var(--color-background-accent);
        border: none;

        a {
          color: var(--color-text);
        }

        &:hover {
          filter: brightness(75%);
        }

        &-active {
          background-color: var(--color-secondary);
          a {
            color: var(--color-white);
          }
        }
      }

      .ant-pagination-item-link {
        background-color: var(--color-background-accent);
        border: none;
        color: var(--color-text);
      }

      .ant-pagination-simple-pager {
        padding-right: 0.5rem;
        background-color: var(--color-background-accent);
        color: var(--color-text);

        input {
          color: var(--color-accent-2);
          background-color: var(--color-background-accent);
          border: none;
          margin-right: 0;
        }
      }

      .ant-pagination-item-ellipsis {
        color: var(--color-text);
      }
    }
  }
`;

export const LoadingOverlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`;

export const ShareIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;

  svg {
    width: 1rem;
    height: 1rem;

    path {
      stroke: var(--color-accent-2);
    }
  }
`;

export const InvertPriceContainer = styled.div`
  @media ${theme.mediaQueries.mobile} {
    flex: 100%;
    margin-left: 0.375rem;
  }
`;

export const PairContainer = styled.div<{ $isChartView: boolean }>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ $isChartView }) => ($isChartView ? '0rem' : '-1rem')};
  padding: 1rem 0;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const ActionBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    color: var(--color-text);
    cursor: pointer;

    &:hover {
      transform: scale(1.1);
      transition: transform 0.2s ease-in-out;
    }
  }

  & > div {
    display: flex;
    align-items: center;
  }

  button {
    margin-right: 0.75rem;
  }
`;

export const ChartExtraActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const PageTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PairContractAddressWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const PairContractAddressLabel = styled.div`
  font-size: 0.75rem;
  color: var(--color-secondary);
  width: fit-content;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const PairContractAddressActions = styled.div`
  display: flex;
  align-items: center;

  & > div {
    display: flex;
    align-items: center;
    margin-left: 0.5rem;
    position: relative;
    bottom: 0.0625rem;
    color: var(--color-secondary);
  }
`;

export const CopyIcon = styled(AiOutlineCopy)`
  display: flex;
  align-items: center;
  font-size: 1rem;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
    transition: transform 0.2s ease-in-out;
  }
`;

export const VisitIcon = styled(AiOutlineSelect)`
  font-size: 1rem;
  margin-left: 0.5rem;
`;

export const ConfirmedIcon = styled(AiOutlineCheck)`
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: var(--color-ant-green);
`;

export const PairLabelTooltipWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const ExchangeRateWrapper = styled.div`
  flex: 100%;
  display: flex;
  align-items: center;
`;

export const TokenPrice = styled.div`
  && {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    font-size: 0.75rem;
    color: var(--color-help-text);
    width: fit-content;
    border-bottom: 1px solid transparent;
    line-height: 0.625rem;
    margin: 0.5rem 0;

    .price {
      margin-left: 0.5rem;
      color: var(--color-tercery);
    }

    &:hover {
      cursor: pointer;
      border-color: var(--color-border);
    }

    &:last-child {
      margin-left: 0.75rem;
    }

    @media ${theme.mediaQueries.mobile} {
      flex-direction: column;
      align-items: flex-start;
      margin-top: 0.25rem;
      line-height: 1rem;

      .price {
        margin: 0;
      }

      &:hover {
        line-height: 1rem;
        border-color: transparent;
      }
    }
  }
`;

export const ExtraStatsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  border-top: 0.5px solid var(--color-border);
  border-bottom: 0.5px solid var(--color-border);
  padding: 1rem 0;
  margin-bottom: 2rem;
  gap: 1rem;
  max-width: 100vw;
`;

export const ExtraStatsBlock = styled.div`
  grid-column: span 1 / auto;

  &:not(:last-child) {
    border-right: 0.5px solid var(--color-border);
  }

  @media ${theme.mediaQueries.mobile} {
    border-right: 0.5px solid var(--color-border);
  }
`;

export const StatsLabel = styled.div`
  font-size: 0.75rem;
  color: var(--color-help-text);

  .ant-tag {
    margin-left: 0.125rem;
    border-color: #dfe1e4;
    color: var(--color-white);
    border-color: var(--color-primary);
    background-color: var(--color-primary);
    border-radius: 0.25rem;
    white-space: nowrap;
    padding: 0 0.25rem;
  }
`;

export const StatsValue = styled.div`
  font-size: 1rem;
  color: var(--color-tercery);
`;

export const ToggleChartTypeGlobalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  margin-left: 1rem;
  font-size: 0.75rem;
  font-weight: normal;
  color: var(--color-text);

  .ant-switch-checked {
    background-color: var(--color-secondary);
  }

  button {
    margin-left: 0.5rem;
    width: 3.5rem;
  }
`;

export const FactoryLabel = styled(PairContractAddressLabel)`
  font-size: 1.125rem;
  color: var(--color-text);
`;

export const PoolWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
`;
