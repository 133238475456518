import React, { useContext, useState } from 'react';
import { Divider, Select, Table, TabsProps, Timeline, Tooltip } from 'antd';
import {
  HeaderPreviewInfo,
  NFTBox,
  NFTBoxInfo,
  PortfolioFilterWrapper,
  NFTGrid,
  NFTSorterWrapper,
  OverviewContentGrid,
  OverviewPortfolioBox,
  OverviewTabsWrapper,
  OverviewWrapper,
  SegmentedWrapper,
  TableActionsWrapper,
  TableWrapper,
  TokenAllocationTokenList,
  TokenAllocationWrapper,
  ActivityTimelineWrapper,
  PerformanceWrapper,
  PerformanceTimeRange,
} from '../styles';
import {
  BsArrowDown,
  BsDownload,
  BsQuestionCircleFill,
  BsThreeDots,
} from 'react-icons/bs';
import Button from '../../../components/Button/Button';
import DoughnutChart from './DoughnutChart';
import { ColumnsType } from 'antd/lib/table';
import { currencyFormatter } from '../../../shared/helpers';
import { GlobalContext } from '../../../context/globalState';
import SearchBar from '../../../components/Sidebar/SearchBar';
import { RiExchangeDollarFill } from 'react-icons/ri';
import { AiOutlineFileProtect } from 'react-icons/ai';

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import theme from '../../../theme';
import LineChart from './LineChart';
import CheckableTag from 'antd/lib/tag/CheckableTag';
import { useNavigate } from 'react-router-dom';

interface Props { }

interface DataType {
  asset: string;
  price: number;
  balance: number;
  value: number;
  symbol: string;
}

type NFTSort = 'highToLow' | 'lowToHigh' | 'oldest' | 'recentlyAdded';
type Chains = 'ethereum' | 'avax' | 'optimism' | 'all';
type TimeRange = '1h' | '1d' | '1w' | '1m' | '1y' | 'max';

type ActivityTypes = 'trade' | 'deposit' | 'receive' | 'execute';
const ActivityIcons: Record<ActivityTypes, React.ReactNode> = {
  trade: <RiExchangeDollarFill />,
  deposit: <BsDownload />,
  receive: <BsArrowDown />,
  execute: <AiOutlineFileProtect />,
};

const Overview: React.FC<Props> = () => {
  const [segment, setSegment] = useState<'Collection' | 'List'>('List');
  const [nftFilter, setNftFilter] = useState<NFTSort>('highToLow');
  const [portfolioChain, setPortfolioChain] = useState<Chains>('all');
  const [performanceTimeRange, setPerformanceTimeRange] =
    useState<TimeRange>('1d');
  const [searchQuery, setSearchQuery] = useState<string>('');
  const { darkMode, isMobile } = useContext(GlobalContext);
  const navigate = useNavigate();

  const columns: ColumnsType<DataType> = [
    {
      title: 'Asset',
      dataIndex: 'asset',
      sorter: (a, b) => (a.asset > b.asset ? 1 : -1),
      sortDirections: ['ascend', 'descend', 'ascend'],
      // fixed: isMobile ? 'left' : false,
      // width: isMobile ? 150 : undefined,
      render: (value, record) => {
        return <div>{value}</div>;
      },
    },
    {
      title: 'Price',
      dataIndex: 'price',
      sorter: (a, b) => a.price - b.price,
      width: 120,
      sortDirections: ['ascend', 'descend', 'ascend'],
      align: 'right',
      render: (value) => currencyFormatter().format(value),
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      sorter: (a, b) => a.balance - b.balance,
      sortDirections: ['ascend', 'descend', 'ascend'],
      align: 'right',
      render: (value, record) => {
        return (
          <span>
            <span>{value.toLocaleString()}</span>
            <span style={{ marginLeft: 4 }}>{record.symbol}</span>
          </span>
        );
      },
    },
    {
      title: 'Value',
      dataIndex: 'value',
      sorter: (a, b) => a.value - b.value,
      sortDirections: ['ascend', 'descend', 'ascend'],
      align: 'right',
      render: (value) => currencyFormatter().format(value),
    },
    {
      title: '',
      dataIndex: 'actions',
      width: 60,
      align: 'right',
      render: (value) => (
        <TableActionsWrapper>
          <BsThreeDots />
        </TableActionsWrapper>
      ),
    },
  ];

  const dataSource = [
    {
      key: '1',
      asset: 'ETH',
      price: 3200,
      balance: 1.2,
      value: 3840,
      symbol: 'ETH',
    },
    {
      key: '2',
      asset: 'AVAX',
      price: 50,
      balance: 10,
      value: 500,
      symbol: 'AVAX',
    },
    {
      key: '3',
      asset: 'BTC',
      price: 50000,
      balance: 0.5,
      value: 25000,
      symbol: 'BTC',
    },
    {
      key: '4',
      asset: 'CRV',
      price: 3,
      balance: 100,
      value: 300,
      symbol: 'CRV',
    },
    {
      key: '5',
      asset: 'USDT',
      price: 1,
      balance: 1000,
      value: 1000,
      symbol: 'USDT',
    },
  ];

  const onNFTClick = () => {
    navigate('/nft/12345');
  };

  const PortfolioTab = () => {
    return (
      <div>
        <HeaderPreviewInfo>
          <span />
          <span>$14.20</span>
          <span>+2% ($1.02)</span>
        </HeaderPreviewInfo>
        <PortfolioFilterWrapper>
          <NFTSorterWrapper value={portfolioChain} onChange={setPortfolioChain}>
            <Select.Option value="all">All</Select.Option>
            <Select.Option value="ethereum">Ethereum</Select.Option>
            <Select.Option value="avax">Avax</Select.Option>
            <Select.Option value="optimism">Optimism</Select.Option>
          </NFTSorterWrapper>
        </PortfolioFilterWrapper>
        <OverviewContentGrid>
          <OverviewPortfolioBox $fullWidth>
            <h3>Wallet</h3>
            <TableWrapper>
              <Table
                columns={columns}
                dataSource={dataSource}
                rowClassName={'table-row-custom'}
                pagination={false}
              />
            </TableWrapper>
          </OverviewPortfolioBox>
          <OverviewPortfolioBox>
            <div>
              <h3>Token Allocation</h3>
              <Button type="text">View All</Button>
            </div>
            <div>
              <TokenAllocationWrapper>
                <DoughnutChart />
                <TokenAllocationTokenList>
                  <div>
                    <span>ETH</span>
                    <span>85.93%</span>
                  </div>
                  <div>
                    <span>AVAX</span>
                    <span>12.29%</span>
                  </div>
                  <div>
                    <span>WBTC</span>
                    <span>1.77%</span>
                  </div>
                  <div>
                    <span>CRV</span>
                    <span>0.98%</span>
                  </div>
                  <div>
                    <span>Others</span>
                    <span>0.00%</span>
                  </div>
                </TokenAllocationTokenList>
              </TokenAllocationWrapper>
            </div>
          </OverviewPortfolioBox>
          <OverviewPortfolioBox>
            <h3>Performance</h3>
            <div>
              <PerformanceWrapper>
                <HeaderPreviewInfo>
                  <span />
                  <span>$2151.20</span>
                  <span>+26% ($512.12)</span>
                </HeaderPreviewInfo>
                <LineChart
                  darkMode={darkMode}
                  timeRange={performanceTimeRange}
                />
                <PerformanceTimeRange>
                  {['1h', '1d', '1w', '1m', '1y', 'max'].map((tag) => (
                    <CheckableTag
                      key={tag}
                      checked={performanceTimeRange === (tag as TimeRange)}
                      onChange={(checked) =>
                        setPerformanceTimeRange(tag as TimeRange)
                      }
                    >
                      {tag}
                    </CheckableTag>
                  ))}
                </PerformanceTimeRange>
              </PerformanceWrapper>
            </div>
          </OverviewPortfolioBox>
        </OverviewContentGrid>
      </div>
    );
  };

  const NFTsTab = () => {
    return (
      <div>
        <HeaderPreviewInfo>
          <span />
          <span>
            $2139.20{' '}
            <Tooltip title="Based on Floor Price">
              <BsQuestionCircleFill />
            </Tooltip>{' '}
          </span>
          <span>+9% ($198.02)</span>
        </HeaderPreviewInfo>
        <PortfolioFilterWrapper>
          <NFTSorterWrapper value={nftFilter} onChange={setNftFilter}>
            <Select.Option value="recentlyAdded">Recently added</Select.Option>
            <Select.Option value="oldest">Oldest</Select.Option>
            <Select.Option value="lowToHigh">Price: low to high</Select.Option>
            <Select.Option value="highToLow">Price: high to low</Select.Option>
          </NFTSorterWrapper>
          <SegmentedWrapper
            options={['Collection', 'List']}
            value={segment}
            onChange={setSegment}
            $isDarkMode={darkMode}
            size="large"
          />
        </PortfolioFilterWrapper>
        <NFTGrid>
          <NFTBox onClick={onNFTClick}>
            <img
              src="https://lh3.googleusercontent.com/Z9HJx1h4yv1DoTARZxm0EbJ-yH0WVYuJqiogWXRd3HhajADEF99J8xfyxYOsQJ_IO08ijpyjfdDAAPxn0jnouxGyJud_Joi9gX_H=s250"
              alt="nft-media"
            />
            <NFTBoxInfo>
              <span>Collection Name</span>
              <span>Item #1521</span>
              <div>
                <span>Floor price</span>
                <span>0.53 ETH</span>
              </div>
            </NFTBoxInfo>
          </NFTBox>
          <NFTBox onClick={onNFTClick}>
            <img
              src="https://lh3.googleusercontent.com/Z9HJx1h4yv1DoTARZxm0EbJ-yH0WVYuJqiogWXRd3HhajADEF99J8xfyxYOsQJ_IO08ijpyjfdDAAPxn0jnouxGyJud_Joi9gX_H=s250"
              alt="nft-media"
            />
            <NFTBoxInfo>
              <span>Collection Name</span>
              <span>Item #1521</span>
              <div>
                <span>Floor price</span>
                <span>0.53 ETH</span>
              </div>
            </NFTBoxInfo>
          </NFTBox>
          <NFTBox onClick={onNFTClick}>
            <img
              src="https://lh3.googleusercontent.com/Z9HJx1h4yv1DoTARZxm0EbJ-yH0WVYuJqiogWXRd3HhajADEF99J8xfyxYOsQJ_IO08ijpyjfdDAAPxn0jnouxGyJud_Joi9gX_H=s250"
              alt="nft-media"
            />
            <NFTBoxInfo>
              <span>Collection Name</span>
              <span>Item #1521</span>
              <div>
                <span>Floor price</span>
                <span>0.53 ETH</span>
              </div>
            </NFTBoxInfo>
          </NFTBox>
          <NFTBox onClick={onNFTClick}>
            <img
              src="https://lh3.googleusercontent.com/Z9HJx1h4yv1DoTARZxm0EbJ-yH0WVYuJqiogWXRd3HhajADEF99J8xfyxYOsQJ_IO08ijpyjfdDAAPxn0jnouxGyJud_Joi9gX_H=s250"
              alt="nft-media"
            />
            <NFTBoxInfo>
              <span>Collection Name</span>
              <span>Item #1521</span>
              <div>
                <span>Floor price</span>
                <span>0.53 ETH</span>
              </div>
            </NFTBoxInfo>
          </NFTBox>
          <NFTBox onClick={onNFTClick}>
            <img
              src="https://lh3.googleusercontent.com/Z9HJx1h4yv1DoTARZxm0EbJ-yH0WVYuJqiogWXRd3HhajADEF99J8xfyxYOsQJ_IO08ijpyjfdDAAPxn0jnouxGyJud_Joi9gX_H=s250"
              alt="nft-media"
            />
            <NFTBoxInfo>
              <span>Collection Name</span>
              <span>Item #1521</span>
              <div>
                <span>Floor price</span>
                <span>0.53 ETH</span>
              </div>
            </NFTBoxInfo>
          </NFTBox>
          <NFTBox onClick={onNFTClick}>
            <img
              src="https://lh3.googleusercontent.com/Z9HJx1h4yv1DoTARZxm0EbJ-yH0WVYuJqiogWXRd3HhajADEF99J8xfyxYOsQJ_IO08ijpyjfdDAAPxn0jnouxGyJud_Joi9gX_H=s250"
              alt="nft-media"
            />
            <NFTBoxInfo>
              <span>Collection Name</span>
              <span>Item #1521</span>
              <div>
                <span>Floor price</span>
                <span>0.53 ETH</span>
              </div>
            </NFTBoxInfo>
          </NFTBox>
        </NFTGrid>
      </div>
    );
  };

  const ActivityTab = () => {
    return (
      <div>
        <PortfolioFilterWrapper>
          <SearchBar {...{ searchQuery, setSearchQuery, isMobile }} />
          <Button type="primary">Download CSV</Button>
        </PortfolioFilterWrapper>
        show in a more interesting way (maybe a timeline) ins and outs of the
        wallet clickable that opens a modal or new page with more info
      </div>
    );
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: `Portfolio`,
      children: <PortfolioTab />,
    },
    {
      key: '2',
      label: `NFTs`,
      children: <NFTsTab />,
    },
    {
      key: '3',
      label: `Activity`,
      children: (
        <div>
          <PortfolioFilterWrapper>
            <SearchBar
              {...{ searchQuery, setSearchQuery, isMobile }}
              className="activity-search-bar"
            />
            <Button type="primary">Download CSV</Button>
          </PortfolioFilterWrapper>
          {/* <ActivityTimelineWrapper>
            <Divider>June 5, 2023</Divider>
            <Timeline mode="alternate">
              <Timeline.Item color="green" dot={ActivityIcons.trade}>
                Trade: 0.95 ETH for 1754 USDT
              </Timeline.Item>
              <Timeline.Item color="green" dot={ActivityIcons.trade}>
                Trade: 0.52 ETH for 1011 DAI
              </Timeline.Item>
            </Timeline>
            <Divider>June 4, 2023</Divider>
            <Timeline mode="alternate">
              <Timeline.Item color="red" dot={ActivityIcons.execute}>
                Execute: 0xb3cd6b20bf3c76c80156c685d2ceec92a54295cf520b82c9ec5dfcee9f31bd33
              </Timeline.Item>
              <Timeline.Item color="green" dot={ActivityIcons.deposit}>
                Deposit: 10.52 ETH
              </Timeline.Item>
            </Timeline>
          </ActivityTimelineWrapper> */}
          <VerticalTimeline lineColor={theme.colors.white} layout="2-columns">
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: theme.colors.blue, color: '#fff' }}
              contentArrowStyle={{
                borderRight: '7px solid  rgb(33, 150, 243)',
              }}
              date="June 5, 2023 12:27 PM"
              iconStyle={{ background: theme.colors.blue, color: '#fff' }}
              icon={ActivityIcons.trade}
            >
              <h3 className="vertical-timeline-element-title">
                +0.4872 ETH ($916.26)
              </h3>
              <h4 className="vertical-timeline-element-subtitle">Ethereum</h4>
              <p>Trade: 0.95 ETH for 1754 USDT</p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              date="June 4, 2023, 9:30 AM"
              iconStyle={{ background: theme.colors.secondary, color: '#fff' }}
              icon={ActivityIcons.execute}
            >
              <h3 className="vertical-timeline-element-title">
                Factory contract: (contract)
              </h3>
              <h4 className="vertical-timeline-element-subtitle">Execute</h4>
              <p>Execute: 0xb3c....bd33</p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              date="June 2, 2023, 11:30 PM"
              iconStyle={{ background: theme.colors.blue, color: '#fff' }}
              icon={ActivityIcons.trade}
            >
              <h3 className="vertical-timeline-element-title">
                +2512 USDT ($2511.78)
              </h3>
              <h4 className="vertical-timeline-element-subtitle">Arbitrum</h4>
              <p>Trade: 0.1 WBTC for 2512 USDT</p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              date="June 1, 2023, 9:30 AM"
              iconStyle={{
                background: theme.colors['antd-green'],
                color: '#fff',
              }}
              icon={ActivityIcons.deposit}
            >
              <h3 className="vertical-timeline-element-title">
                +0.25 ETH ($464.22)
              </h3>
              <h4 className="vertical-timeline-element-subtitle">Optimism</h4>
              <p>Deposited: 0.25 ETH to the wallet</p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              iconStyle={{
                background: theme.colors['antd-green'],
                color: '#fff',
              }}
              icon={ActivityIcons.receive}
            />
          </VerticalTimeline>
        </div>
      ),
    },
  ];

  return (
    <OverviewWrapper>
      <OverviewTabsWrapper items={items} defaultActiveKey="1" />
    </OverviewWrapper>
  );
};

export default Overview;
