import React from 'react';
import SCLogoSvg from '../../assets/icons/SC_Logo_Red_RGB.svg';
import { Wrapper } from './styles';

export interface LoaderProps {
  style?: React.CSSProperties;
  size?: Size;
}

type Size = 'small' | 'medium' | 'large';

const Loader: React.FC<LoaderProps> = ({ style, size = 'medium' }) => {
  return (
    <Wrapper {...{ style, size }}>
      <img
        src={SCLogoSvg}
        style={{ width: '100%', height: 'auto' }}
        alt="SC Logo"
      />
    </Wrapper>
  );
};

export default Loader;
