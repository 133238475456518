import { useCallback, useEffect, useState } from 'react';
import { ReadyState, default as useWS } from 'react-use-websocket';
import { SupportedChains } from '../shared/constants';
import { getWebsocketUrl } from '../shared/helpers';

interface UseWebSocketProps {
  chain: SupportedChains;
  address?: string;
}

interface MessageProps {
  data?: {
    full: [
      {
        blockNumber: number;
        price: number;
        timestamp: number;
        txId: string;
        volume: number;
        reserve0: number;
        reserve1: number;
      }
    ];
  };
  subscription?: {
    address: string;
  };
  from?: number;
  to?: number;
}

const useWebSocket = ({ chain, address = '' }: UseWebSocketProps) => {
  const [socketUrl, setSocketUrl] = useState(getWebsocketUrl(chain));
  const { sendMessage, lastMessage, readyState } = useWS(socketUrl);
  const [lastMessageParsed, setLastMessageParsed] = useState<MessageProps>({});
  const [messageHistory, setMessageHistory] = useState<MessageProps[]>([]);

  useEffect(() => {
    setSocketUrl(getWebsocketUrl(chain));
  }, []);

  useEffect(() => {
    if (address) {
      subscribeToPriceUpdates();
    }

    return () => {
      if (address) {
        unsubscribeToPriceUpdates();
      }
    };
  }, [address]);

  useEffect(() => {
    if (lastMessage !== null) {
      const message: MessageProps = JSON.parse(lastMessage.data);

      setLastMessageParsed(message);
      setMessageHistory((prev) => prev.concat(message));
    }
  }, [lastMessage]);

  const subscribeToPriceUpdates = useCallback(
    () =>
      sendMessage(
        JSON.stringify({
          address,
          command: 'subscribe',
          dataType: 'full',
        })
      ),
    [address]
  );

  const unsubscribeToPriceUpdates = useCallback(
    () =>
      sendMessage(
        JSON.stringify({
          address,
          command: 'unsubscribe',
          dataType: 'full',
        })
      ),
    [address]
  );

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  return { messageHistory, lastMessage: lastMessageParsed, connectionStatus };
};

export default useWebSocket;
