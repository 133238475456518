import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '../../context/globalState';
import { SidebarTitle, SidebarWrapper, StyledMenu } from './styles';
import { SUPERCHAIN_HOMEPAGE } from '../../shared/constants';
import { openExternalLink } from '../../shared/helpers';
import { Divider } from 'antd';

const Menu: React.FC = () => {
  const { sidebarOpen, setSidebarOpen } = useContext(GlobalContext);
  const navigate = useNavigate();

  const onClick = (url: string, externalLink?: boolean) => {
    if (externalLink) {
      openExternalLink(url);
    } else {
      navigate(url);
    }
    setSidebarOpen(false);
  };

  return (
    <StyledMenu $open={sidebarOpen}>
      <SidebarWrapper>
        <SidebarTitle onClick={() => onClick('/tokens/ranking')}>
          Tokens
        </SidebarTitle>
        <SidebarTitle onClick={() => onClick('/swap')}>Superswap</SidebarTitle>
        <Divider />
        <SidebarTitle onClick={() => onClick(SUPERCHAIN_HOMEPAGE, true)}>
          About
        </SidebarTitle>
        <SidebarTitle
          onClick={() => onClick(`${SUPERCHAIN_HOMEPAGE}/get-access`, true)}
        >
          Get Access
        </SidebarTitle>
      </SidebarWrapper>
    </StyledMenu>
  );
};
export default Menu;
