import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactEChart, { EChartsInstance } from 'echarts-for-react';
import echartTheme from '../../assets/echartTheme.json';
import echartThemeDark from '../../assets/echartThemeDark.json';
import theme from '../../theme';
import useWindowSize from '../../hooks/useWindowSize';
import moment, { Moment } from 'moment';
import { DataZoomProps } from '../../components/PairDetails';

const upColor = '#00da3c';
const downColor = '#ec0000';

function splitData(rawData: any[]) {
  let categoryData = [];
  let values = [];
  let volumes = [];
  for (let i = 0; i < rawData.length; i++) {
    categoryData.push(rawData[i].splice(0, 1)[0]);
    values.push(rawData[i]);
    volumes.push([i, rawData[i][4], rawData[i][0] > rawData[i][1] ? 1 : -1]);
  }
  return {
    categoryData: categoryData,
    values: values,
    volumes: volumes,
  };
}

function calculateMA(dayCount: any, data: any) {
  var result = [];
  for (var i = 0, len = data.values.length; i < len; i++) {
    if (i < dayCount) {
      result.push('-');
      continue;
    }
    var sum = 0;
    for (var j = 0; j < dayCount; j++) {
      sum += data.values[i - j][1];
    }
    result.push(+(sum / dayCount).toFixed(3));
  }
  return result;
}

interface ChartProps {
  data: any[];
  darkMode?: boolean;
  dateAxisLabelFormat: string;
  dataZoom: DataZoomProps;
}

const ChartCandleStick: React.FC<ChartProps> = ({ data = [], darkMode, dataZoom, dateAxisLabelFormat }) => {
  const chartRef = useRef<EChartsInstance>();
  const { width } = useWindowSize();

  const [lastUpdated, setLastUpdated] = useState<Moment>(moment());
  const [lastUpdatedTitle, setLastUpdatedTitle] = useState<string>('');
  const lastUpdatedIntervalRef = useRef<any>(null);

  const [parsedData, setParsedData] = useState<ReturnType<typeof splitData>>(
    { values: [], volumes: [], categoryData: [] }
  );

  useEffect(() => {
    const instance: EChartsInstance = chartRef.current?.getEchartsInstance();

    instance?.resize();
  }, [width]);

  useEffect(() => {
    const values: any = [];
    const volumes: any = [];
    const categoryData: any = [];

    data.map((item, i) => {
      values.push([
        item.token_open_price_all_chains,
        item.token_close_price_all_chains,
        item.token_low_price_all_chains,
        item.token_high_price_all_chains,
        item.window_start_timestamp * 1000,
      ]);

      volumes.push([
        i,
        item.token_volume_all_chains || 0,
        item.token_open_price_all_chains > item.token_close_price_all_chains
          ? 1
          : -1,
      ]);
      categoryData.push(
        moment(item.window_start_timestamp * 1000).format('MMM Do HH:mm A')
      );
    });

    setParsedData({ values, volumes, categoryData });

    const instance: EChartsInstance = chartRef.current?.getEchartsInstance();
    instance.dispatchAction({
      type: 'brush',
      areas: [
        {
          brushType: 'lineX',
          coordRange: ['2016-06-02', '2016-06-20'],
          xAxisIndex: 0,
        },
      ],
    });

  }, [data.length]);

  useEffect(() => {
    setLastUpdated(moment());

    if (lastUpdatedIntervalRef.current) {
      clearInterval(lastUpdatedIntervalRef.current);
    }

    const intervalId = setInterval(() => {
      setLastUpdatedTitle(lastUpdated.fromNow());
    }, 1000);

    lastUpdatedIntervalRef.current = intervalId;
  }, [data.length]);

  const option = {
    animation: false,
    legend: {
      bottom: 10,
      left: 'center',
      data: [`${data?.[0]?.token_symbol}`, 'MA7'],
    },
    title: {
      left: 'center',
      text: 'Last updated: ',
      subtext: lastUpdatedTitle,
      padding: 10,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
      },
      borderWidth: 1,
      borderColor: '#ccc',
      padding: 10,
      textStyle: {
        color: '#000',
      },
      position: function (
        pos: number[],
        params: any,
        el: HTMLElement,
        elRect: any,
        size: any
      ) {
        const obj = {
          top: 10,
        };
        // @ts-ignore
        obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
        return obj;
      },
      extraCssText: 'width: 270px'
    },
    axisPointer: {
      link: [
        {
          xAxisIndex: 'all',
        },
      ],
      label: {
        backgroundColor: '#777',
      },
    },
    toolbox: {
      feature: {
        dataZoom: {
          yAxisIndex: false,
        },
        brush: {
          type: ['lineX', 'clear'],
        },
      },
    },
    brush: {
      xAxisIndex: 'all',
      brushLink: 'all',
      outOfBrush: {
        colorAlpha: 0.1,
      },
    },
    visualMap: {
      show: false,
      seriesIndex: 2,
      dimension: 2,
      pieces: [
        {
          value: 1,
          color: downColor,
        },
        {
          value: -1,
          color: upColor,
        },
      ],
    },
    grid: [
      {
        left: '10%',
        right: '8%',
        height: '50%',
      },
      {
        left: '10%',
        right: '8%',
        top: '63%',
        height: '16%',
      },
    ],
    xAxis: [
      {
        type: 'category',
        data: parsedData.categoryData,
        boundaryGap: false,
        axisLine: { onZero: false },
        splitLine: { show: false },
        min: 'dataMin',
        max: 'dataMax',
        axisPointer: {
          z: 100,
        },
        axisLabel: {
          formatter: {
            year: '{yyyy}',
            month: '{monthDate|{MMM} {yy}}',
            day: '{dayDate|{ee} {d}}',
            hour: '{hourDate|{HH}:{mm}}',
            minute: '{hourDate|{HH}:{mm}}',
            second: '{mm}:{ss}',
            none: '{yyyy}-{MM}-{dd}',
          },
          rich: {
            monthDate: {
              fontWeight: 'bold',
            },
            dayDate: {},
            hourDate: {
              color: darkMode ? theme.colors.creme : 'rgba(0,0,0,0.57)',
            },
          },
          textStyle: {
            color: darkMode ? theme.colors.creme : theme.colors.primary,
          },
          hideOverlap: true,
        },
      },
      {
        type: 'category',
        gridIndex: 1,
        data: parsedData.categoryData,
        boundaryGap: false,
        axisLine: { onZero: false },
        axisTick: { show: false },
        splitLine: { show: false },
        axisLabel: { show: false },
        min: 'dataMin',
        max: 'dataMax',
      },
    ],
    yAxis: [
      {
        scale: true,
        splitArea: {
          show: true,
        },
      },
      {
        scale: true,
        gridIndex: 1,
        splitNumber: 2,
        axisLabel: { show: false },
        axisLine: { show: false },
        axisTick: { show: false },
        splitLine: { show: false },
      },
    ],
    dataZoom: [
      {
        type: 'inside',
        xAxisIndex: [0, 1],
        startValue: data.length - 30,
        endValue: data.length,
      },
      {
        show: true,
        xAxisIndex: [0, 1],
        type: 'slider',
        top: '85%',
        startValue: data.length - 30,
        endValue: data.length,
      },
    ],
    series: [
      {
        name: `${data?.[0]?.token_symbol}`,
        type: 'candlestick',
        data: parsedData.values,
        itemStyle: {
          color: upColor,
          color0: downColor,
          borderColor: undefined,
          borderColor0: undefined,
          borderWidth: 1,
        },
      },
      {
        name: 'MA7',
        type: 'line',
        data: calculateMA(7, parsedData),
        smooth: true,
        lineStyle: {
          opacity: 0.3,
        },
      },
      {
        name: 'Volume',
        type: 'bar',
        xAxisIndex: 1,
        yAxisIndex: 1,
        data: parsedData.volumes,
        itemStyle: {
          color: upColor,
          color0: downColor,
        }
      },
    ],
  };

  return useMemo(
    () => (
      <ReactEChart
        option={option}
        theme={darkMode ? echartThemeDark : echartTheme}
        ref={chartRef}
        style={{ height: 600 }}
        lazyUpdate
      />
    ),
    [parsedData, darkMode]
  );
};

export default ChartCandleStick;
