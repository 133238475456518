import styled from 'styled-components';
import { GenericButton, GenericInput } from '../../shared/styles';
import theme from '../../theme';

interface MobileSidebarProps {
  $open: boolean;
}

export const StyledBurger = styled.button<MobileSidebarProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 101;

  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background: var(--color-white);
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ $open }) => ($open ? 'rotate(45deg)' : 'rotate(0)')};
    }

    :nth-child(2) {
      opacity: ${({ $open }) => ($open ? '0' : '1')};
      transform: ${({ $open }) =>
        $open ? 'translateX(20px)' : 'translateX(0)'};
    }

    :nth-child(3) {
      transform: ${({ $open }) => ($open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`;

export const StyledMenu = styled.nav<MobileSidebarProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: var(--color-primary);
  height: 100vh;
  text-align: left;
  padding: 2rem;
  position: fixed;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  transform: ${({ $open }) => ($open ? 'translateX(0)' : 'translateX(100%)')};
  z-index: 100;

  @media ${theme.mediaQueries.mobile} {
    width: 100%;
  }

  a {
    font-size: 2rem;
    text-transform: uppercase;
    padding: 2rem 0;
    font-weight: bold;
    letter-spacing: 0.5rem;
    color: blue;
    text-decoration: none;
    transition: color 0.3s linear;

    @media ${theme.mediaQueries.mobile} {
      font-size: 1.5rem;
      text-align: center;
    }

    &:hover {
      color: gray;
    }
  }
`;

export const SidebarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  height: 100%;
  margin-top: 2rem;
`;

export const SidebarTitle = styled.div`
  font-size: 2rem;
  font-weight: bold;
  text-transform: uppercase;
  color: var(--color-gray);
  margin-bottom: 1rem;
  cursor: pointer;
`;

export const ClearFiltersButton = styled(GenericButton)`
  margin-left: 1rem;
`;

export const PairSourceLabel = styled.div`
  color: var(--color-primary);

  @media ${theme.mediaQueries.mobile} {
    font-size: 0.75rem;
  }
`;

export const MobileFiltersButton = styled(GenericButton)`
  border: none;
  font-size: 0.875rem;
  padding: 0;
  margin-left: 0.875rem;
  margin-right: 0.25rem;
  height: 2rem;

  svg {
    font-size: 2rem;
  }
`;

export const MobileFilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
  border-radius: 0.25rem;
  background-clip: padding-box;

  && {
    .ant-input-group-wrapper {
      margin: 0;
      padding: 0 0.5rem;
    }

    .ant-divider {
      margin: 1rem 0;
    }

    button {
      width: 100%;
      margin: 0.5rem;
      margin-top: 0rem;
      width: calc(100% - 1rem);
    }
  }
`;

export const MobileFilterTitle = styled.div`
  font-size: 0.875rem;
  font-weight: bold;
  text-transform: uppercase;
  color: var(--color-primary);
  border-bottom: 1px solid var(--color-primary);
  padding: 0.5rem;
  margin-bottom: 1rem;
  width: 100%;
  text-align: center;
`;

export const SwapsInput = styled(GenericInput)`
  padding: 0;
  margin-left: 1rem;

  input,
  .ant-input-group-addon {
    font-weight: bold;
    padding: 6.4px 15px;
  }
`;
