import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import {
  Title,
  Subtitle,
  TitleActions,
  TitleWrapper,
  Wrapper,
  Content,
  ContentBox,
} from './styles';
import { GlobalContext } from '../../context/globalState';
import Treemap from '../../components/Charts/Treemap';
import Insights from '../../components/Insights/Insights';

const InsightsView: React.FC = () => {
  const { darkMode, isMobile, tokens } = useContext(GlobalContext);
  const [loading, setLoading] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (tokens.data?.tokenList?.length === 0) {
      navigate('/tokens/ranking');
    }
  }, [tokens.data?.tokenList]);

  return (
    <Wrapper>
      <TitleWrapper>
        <div>
          <Title>Insights</Title>
          <Subtitle>
            Insights into the crypto market, including exchange market share,
            volume, and more.
          </Subtitle>
        </div>
        <TitleActions>
          <Button size="large" onClick={() => setShowFilters(!showFilters)}>
            Filters
          </Button>
        </TitleActions>
      </TitleWrapper>
      <Content>
        <ContentBox>
          <h2>Breakdown By Market Cap (Total Supply)</h2>
          <Treemap
            data={tokens.data.tokenList}
            ready={!tokens.loading}
            isMobile={isMobile}
            darkMode={darkMode}
            type="marketCap"
          />
        </ContentBox>
        <ContentBox>
          <h2>Breakdown By Volume (24hr)</h2>
          <Treemap
            data={tokens.data.tokenList}
            ready={!tokens.loading}
            isMobile={isMobile}
            darkMode={darkMode}
            type="volume"
          />
        </ContentBox>
      </Content>
      <Insights visible />
    </Wrapper>
  );
};

export default InsightsView;
