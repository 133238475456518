import { useContext, useEffect } from 'react';
import { useYbugApi } from 'ybug-react';
import { GlobalContext } from '../context/globalState';

const useYBugApi = () => {
  const { user, isMobile } = useContext(GlobalContext);
  const YbugContext = useYbugApi();

  useEffect(() => {
    if (YbugContext?.Ybug && !isMobile) {
      YbugContext.init({
        language_override: 'en',
        launcher_position: isMobile ? 'left-middle' : 'bottom-right',
        user,
      });
    }

    return () => {
      if (YbugContext?.Ybug) {
        YbugContext.Ybug.destroy();
      }
    };
  }, [user, YbugContext, isMobile]);
};

export default useYBugApi;
