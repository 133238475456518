import { createContext, useEffect, useReducer } from 'react';
import { ChartTimeFrames, ChartViewTypes } from '../components/PairDetails';
import { IAuth } from '../shared/api';
import { AvailableChains, SupportedChains } from '../shared/constants';
import { saveUserToLocalStorage } from '../shared/helpers';
import {
  getLocalStorage,
  setLocalStorage,
  STATE_LOCAL_STORAGE_KEY,
  STATE_LOCAL_STORAGE_KEY_AUTH,
} from './localStorage';
import reducer, {
  REMOVE_PAIR,
  SELECT_PAIR,
  SET_CHAINS,
  SET_DATA_IS_LOADING,
  SET_IS_MOBILE,
  SET_MIN_SWAPS,
  SET_PAIRS_DATA,
  SET_PAIR_VIEW_STATUS,
  SET_PAIR_VIEW_DATA,
  SET_SEARCH_QUERY,
  SET_SIDEBAR_OPEN,
  SET_WALLET_ADDRESS,
  SORT_PAIRS,
  SET_DARK_MODE,
  SET_USER,
  SET_TOKENS_DATA,
  SET_TOKENS_LOADING,
} from './reducer';

export type SwapPairDataPoint = [
  {
    address: [string];
    block_number: [number];
    creator: [string];
    nonce: [number];
    token0: [string];
    token1: [string];
  }[],
  number
];

export interface SelectedPair {
  chain: SupportedChains;
  address: string;
}

export interface GlobalContextProps {
  selection: {
    chains: SupportedChains[];
    minSwaps: string;
    searchQuery: string;
    selectedPairs: SelectedPair[];
  };
  pairs: {
    [SupportedChains.ETH]: SwapPairDataPoint[];
    [SupportedChains.AVAX]: SwapPairDataPoint[];
    [SupportedChains.BSC]: SwapPairDataPoint[];
    [SupportedChains.OPT]: SwapPairDataPoint[];
  };
  user: {
    username: string;
    password: string;
    walletAddress: string;
  } | null;
  pairViewGlobalState: {
    active: boolean;
    chartViewType: ChartViewTypes;
    chartTimeFrame: ChartTimeFrames;
  };
  tokens: {
    data: {
      tokenList: any[];
      tokenPrices: any[];
    };
    loading: boolean;
  };
  isMobile: boolean;
  isDataLoading: boolean;
  sidebarOpen: boolean;
  darkMode: boolean;

  setChains: (chains: SupportedChains[]) => void;
  setAddress: (address: string) => void;
  setSearchQuery: (searchQuery: string) => void;
  setMinSwaps: (minSwaps: string) => void;
  setIsMobile: (isMobile: boolean) => void;
  setSidebarOpen: (sidebarOpen: boolean) => void;
  setPairsData: (pairs: any, chain: SupportedChains) => void;
  setDataIsLoading: (isDataLoading: boolean) => void;
  selectPair: (chain: string, address: string) => void;
  removePair: (address: string) => void;
  sortPairs: (pairs: SelectedPair[]) => void;
  setUserWalletAddress: (walletAddress: string) => void;
  setPairViewStatus: (active: boolean) => void;
  setPairViewData: (data: any) => void;
  setDarkMode: (darkMode: boolean) => void;
  setUser: (user: IAuth | null) => void;
  setTokensData: (data: any) => void;
  setTokensLoading: (loading: boolean) => void;
}

const initialState = {
  selection: {
    chains: Object.values(AvailableChains) as SupportedChains[],
    minSwaps: '50000',
    searchQuery: '',
    selectedPairs: [
      {
        chain: SupportedChains.ETH,
        address: '0x0d4a11d5eeaac28ec3f61d100daf4d40471f1852',
      },
    ], // WETH - USDT],
  },
  pairs: {
    [SupportedChains.ETH]: [],
    [SupportedChains.AVAX]: [],
    [SupportedChains.BSC]: [],
    [SupportedChains.OPT]: [],
  },
  user: getLocalStorage(STATE_LOCAL_STORAGE_KEY_AUTH, null),
  pairViewGlobalState: {
    active: false,
    chartViewType: ChartViewTypes.CHART,
    chartTimeFrame: ChartTimeFrames.WEEK,
  },
  tokens: {
    data: {
      tokenList: [],
      tokenPrices: [],
    },
    loading: false,
  },
  isDataLoading: false,
  isMobile: false,
  sidebarOpen: false,
  darkMode: false,

  setChains: () => { },
  setAddress: () => { },
  setSearchQuery: () => { },
  setMinSwaps: () => { },
  setIsMobile: () => { },
  setSidebarOpen: () => { },
  setPairsData: () => { },
  setDataIsLoading: () => { },
  selectPair: () => { },
  removePair: () => { },
  sortPairs: () => { },
  setUserWalletAddress: () => { },
  setPairViewStatus: () => { },
  setPairViewData: () => { },
  setDarkMode: () => { },
  setUser: () => { },
  setTokensData: () => { },
  setTokensLoading: () => { },
};

export const GlobalContext = createContext<GlobalContextProps>(initialState);

export const GlobalProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(
    reducer,
    getLocalStorage(STATE_LOCAL_STORAGE_KEY, initialState)
  );

  function setChains(chains: SupportedChains[]) {
    dispatch({
      type: SET_CHAINS,
      payload: chains,
    });
  }

  function setMinSwaps(minSwaps: string) {
    dispatch({
      type: SET_MIN_SWAPS,
      payload: minSwaps,
    });
  }

  function setSearchQuery(searchQuery: string) {
    dispatch({
      type: SET_SEARCH_QUERY,
      payload: searchQuery,
    });
  }

  function setPairsData(pairs: any, chain: SupportedChains) {
    dispatch({
      type: SET_PAIRS_DATA,
      payload: { pairs, chain },
    });
  }

  function setIsMobile(isMobile: boolean) {
    dispatch({
      type: SET_IS_MOBILE,
      payload: isMobile,
    });
  }

  function setSidebarOpen(isOpen: boolean) {
    dispatch({
      type: SET_SIDEBAR_OPEN,
      payload: isOpen,
    });
  }

  function setDataIsLoading(isLoading: boolean) {
    dispatch({
      type: SET_DATA_IS_LOADING,
      payload: isLoading,
    });
  }

  function selectPair(chain: SupportedChains, address: string) {
    dispatch({
      type: SELECT_PAIR,
      payload: { chain, address },
    });
  }

  function removePair(address: string) {
    dispatch({
      type: REMOVE_PAIR,
      payload: address,
    });
  }

  function sortPairs(pairs: SelectedPair[]) {
    dispatch({
      type: SORT_PAIRS,
      payload: pairs,
    });
  }

  function setUserWalletAddress(walletAddress: string) {
    dispatch({
      type: SET_WALLET_ADDRESS,
      payload: walletAddress,
    });
  }

  function setPairViewStatus(active: boolean) {
    dispatch({
      type: SET_PAIR_VIEW_STATUS,
      payload: active,
    });
  }

  function setPairViewData(data: {
    chartViewType?: ChartViewTypes;
    chartTimeFrame?: ChartTimeFrames;
  }) {
    dispatch({
      type: SET_PAIR_VIEW_DATA,
      payload: data,
    });
  }

  function setDarkMode(darkMode: boolean) {
    dispatch({
      type: SET_DARK_MODE,
      payload: darkMode,
    });
  }

  function setUser(user: IAuth | null) {
    dispatch({
      type: SET_USER,
      payload: user,
    });

    saveUserToLocalStorage(user);
  }

  function setTokensData(data: any) {
    dispatch({
      type: SET_TOKENS_DATA,
      payload: data,
    });
  }

  function setTokensLoading(loading: boolean) {
    dispatch({
      type: SET_TOKENS_LOADING,
      payload: loading,
    });
  }

  const providerValue = {
    ...state,
    setChains,
    setIsMobile,
    setSidebarOpen,
    setMinSwaps,
    setSearchQuery,
    setPairsData,
    setDataIsLoading,
    selectPair,
    removePair,
    sortPairs,
    setUserWalletAddress,
    setPairViewStatus,
    setPairViewData,
    setDarkMode,
    setUser,
    setTokensData,
    setTokensLoading,
  };

  useEffect(() => {
    const stateWithoutUser = { ...state };
    delete stateWithoutUser.user;

    setLocalStorage(STATE_LOCAL_STORAGE_KEY, stateWithoutUser);
  }, [state]);

  return (
    <GlobalContext.Provider value={providerValue}>
      {children}
    </GlobalContext.Provider>
  );
};
