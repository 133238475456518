export const STATE_LOCAL_STORAGE_KEY = 'superchain_state';
export const STATE_LOCAL_STORAGE_KEY_AUTH = 'superchain_state_auth';
export const APP_VERSION_LOCAL_STORAGE_KEY = 'superchain_app_version';

export function setLocalStorage(key: string, value: any) {
  try {
    window.localStorage.setItem(key, JSON.stringify(value));

    const now = new Date();
    const item = {
      value,
      expiry: now.getTime() + 30 * 24 * 60 * 60 * 1000, // 30 days
    };

    window.localStorage.setItem(key, JSON.stringify(item));
  } catch (e) {
    // possible errors:
    // https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
  }
}

export function getLocalStorage(key: string, initialValue: any) {
  try {
    const itemStr = window.localStorage.getItem(key);
    if (!itemStr) {
      return initialValue;
    }

    const now = new Date();
    const item = JSON.parse(itemStr);

    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return initialValue;
    }

    return item.value;
  } catch (e) {
    return initialValue;
  }
}

export function clearLocalStorage(key: string) {
  try {
    window.localStorage.removeItem(key);
  } catch (e) {
    // possible errors:
    // https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
  }
}
