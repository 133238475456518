import styled from 'styled-components';
import theme from '../../theme';
import { Breadcrumb, Tag as AntTag } from 'antd';
import { ChartDateControlsWrapper } from '../../components/PairDetails/styles';

export const Wrapper = styled.div`
  display: grid;
  grid-template-rows: auto auto 1fr;
  overflow-y: auto;
  height: calc(100vh - var(--header-height));
  position: relative;
  margin: 0 auto;
  max-width: 100rem;
  width: 100%;
  background-color: var(--color-background);

  @media ${theme.mediaQueries.mobile} {
    height: 100%;
  }
`;

export const Header = styled.div<{ $isTop: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  padding: 2rem 1rem;
  background-color: var(--color-background);
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 0 -6px 10px 5px rgba(0, 0, 0, 0.5);

  ${({ $isTop }) => $isTop && `box-shadow: none;`}

  & > * {
    flex: 100%;
  }

  @media ${theme.mediaQueries.mobile} {
    padding: 1rem 0;
  }
`;

export const HeaderNamePriceContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4rem;

  @media ${theme.mediaQueries.mobile} {
    margin-top: 1.5rem;
  }
`;

export const LoadingOverlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`;

export const Title = styled.h1`
  font-size: 2rem;
  font-weight: 600;
  margin: 0;
  color: var(--color-text);
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .ant-tag {
    font-size: 1rem;
    padding: 0.075rem 0.25rem;
  }

  @media ${theme.mediaQueries.mobile} {
    display: none;
  }
`;

export const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;

  div:first-child {
    font-size: 0.8rem;
    font-weight: normal;
    color: var(--color-help-text);
  }
`;

export const Price = styled.div<any>`
  font-size: 2rem;
  font-weight: 600;
  color: var(--color-text);
  display: flex;
  align-items: center;

  .ant-tag {
    display: flex;
    align-items: center;
    gap: 2px;
    position: relative;
    bottom: 2px;
    margin-left: 0.75rem;
    padding: 0.075rem 0.25rem;
    font-size: 0.8rem;
    background-color: ${({ $isPositive }) =>
      $isPositive ? theme.colors['antd-green'] : theme.colors.secondary};
    border-color: ${({ $isPositive }) =>
      $isPositive ? theme.colors['antd-green'] : theme.colors.secondary};
    color: ${theme.colors.white};
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto auto;
  gap: 8px;
  height: auto;
  margin-bottom: 1rem;
  padding: 0 1rem;
  position: relative;

  & > div {
    outline: 1px solid gray;
  }

  .custom-tooltip {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 0rem 1rem;
    margin: 0rem;

    .tooltip-value {
      font-weight: bold;
      color: var(--color-black);
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
    }

    .tooltip-date,
    .tooltip-hours {
      color: gray;
    }
  }
`;

export const ExtraStatsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  border-top: 0.5px solid var(--color-border);
  border-bottom: 0.5px solid var(--color-border);
  padding: 1rem;
  margin-bottom: 2rem;
  gap: 1rem;
  max-width: 100vw;
  min-height: 8rem;

  @media ${theme.mediaQueries.mobile} {
    border: none;
  }
`;

export const ExtraStatsBlock = styled.div`
  grid-column: span 1 / auto;

  &:not(:last-child) {
    border-right: 0.5px solid var(--color-border);

    @media ${theme.mediaQueries.mobile} {
      border-right: none;
    }
  }
`;

export const StatsLabel = styled.div`
  font-size: 1rem;
  color: var(--color-help-text);
`;

export const StatsValue = styled.div`
  font-size: 1.15rem;
  color: var(--color-tercery);

  @media ${theme.mediaQueries.mobile} {
    font-size: 1rem;
  }
`;

export const Tag = styled(AntTag)`
  margin-left: 0.125rem;
  border-color: #dfe1e4;
  color: var(--color-white);
  border-color: var(--color-primary);
  background-color: var(--color-primary);
  border-radius: 0.25rem;
  white-space: nowrap;
  padding: 0 0.25rem;
`;

export const NavWrapper = styled(Breadcrumb)`
  a,
  .ant-breadcrumb-separator {
    color: var(--color-accent-2);
    opacity: 0.5;

    &:hover {
      opacity: 1;
      color: var(--color-accent-2);
    }
  }

  .ant-breadcrumb-link {
    color: var(--color-accent-2);
  }
`;

export const ChartAsImageWrapper = styled.img<{ $darkMode: boolean }>`
  width: calc(100% - 16px);
  height: auto;
  object-fit: contain;
  position: absolute;
  top: 8px;
  right: 8px;
`;

export const ChartPerspectiveWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const HeaderTagsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column !important;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.25rem;

  & > div {
    font-size: 0.8rem;
    color: var(--color-help-text);
  }
`;

export const TagsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ChartContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const ChartControls = styled(ChartDateControlsWrapper)`
  position: absolute;
  right: 0rem;
  top: 0.5rem;
  z-index: 10;

  @media ${theme.mediaQueries.mobile} {
    margin-top: 0;
    width: auto;
    right: 0.5rem;
  }
`;

export const PoolBar = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
  margin-bottom: 2rem;

  && {
    outline: none;
  }

  .ant-select {
    min-width: 25rem;

    @media ${theme.mediaQueries.mobile} {
      width: 100%;
    }
  }

  .ant-select-selector {
    min-width: 18rem;

    .pool-option-wrapper {
      height: 30px;
      padding: 0;
    }
    .pool-option-title {
      display: none;
    }
  }
`;

export const PoolBarTitle = styled.div`
  font-size: 1rem;
  color: var(--color-text);
`;

export const PoolOptionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 0.5rem;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const PoolOptionTitle = styled.div<{ $reverse?: boolean }>`
  font-size: 0.75rem;
  color: var(--color-primary);

  ${({ $reverse }) =>
    $reverse &&
    `
    align-self: flex-end;
  `}
`;
