import styled from 'styled-components';
import theme from '../../theme';

export const Wrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  padding: 0 1rem;
  overflow-y: auto;
  height: calc(100vh - var(--header-height));
  margin: 0 auto;
  position: relative;
  max-width: 100rem;
  width: 100%;
  background-color: var(--color-background);

  @media ${theme.mediaQueries.mobile} {
    overflow-x: hidden;
    max-width: 100vw;
    height: calc(100% - var(--topbar-height));
  }
`;

export const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
  color: var(--color-text);

  @media ${theme.mediaQueries.mobile} {
    font-size: 1.2rem;
  }
`;

export const Subtitle = styled.h2`
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
  color: var(--color-help-text);
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 0;
`;

export const TitleActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  width: 100%;
  gap: 8px;
  height: auto;
  margin-bottom: 2rem;
  position: relative;
`;

export const ContentBox = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;

  h2 {
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0;
    margin-bottom: 1rem;
    color: var(--color-text);
  }
`;
