import styled from 'styled-components';
import { keyframes } from 'styled-components';
import { LoaderProps } from '.';

const spin = keyframes`
 0% { transform: rotate3d(0, 0, 1, 0deg); }
 25% { transform: rotate3d(0, 0, 1, 90deg); }
 50% { transform: rotate3d(0, 0, 1, 180deg); }
 75% { transform: rotate3d(0, 0, 1, 270deg); }
 100% { transform: rotate3d(0, 0, 1, 360deg); }
`;

export const Wrapper = styled.div<LoaderProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ size }) =>
    size === 'small' ? '2rem' : size === 'medium' ? '4rem' : '6rem'};
  height: ${({ size }) =>
    size === 'small' ? '2rem' : size === 'medium' ? '4rem' : '6rem'};

  svg,
  img {
    animation: ${spin} 3.5s normal linear infinite;

    .cls-1 {
      fill: #ff2c3c;
    }
  }
`;
