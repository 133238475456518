import { Segmented, Select, Tabs } from 'antd';
import styled from 'styled-components';
import theme from '../../theme';

export const Wrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  row-gap: 1rem;
  padding: 0 1rem;
  overflow-y: auto;
  height: calc(100vh - var(--header-height));
  margin: 0 auto;
  padding-bottom: 2rem;
  position: relative;
  max-width: 100rem;
  width: 100%;
  background-color: var(--color-background);
  padding: 0 1.5rem;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 10rem;
  max-width: 60rem;
  width: 100%;
  margin: 0 auto;
  margin-top: 1rem;
`;

export const OverviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 10rem;
  max-width: 60rem;
  width: 100%;
  margin: 0 auto;
`;

export const HeaderPreviewWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1.5rem;
  height: 7rem;
`;

export const HeaderAvatar = styled.img`
  width: 7rem;
  height: 7rem;
  border-radius: 1rem;
`;

export const HeaderPreviewInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.25rem;
  margin-bottom: 1rem;
  color: var(--color-text);

  span:nth-child(1) {
    display: flex;
    align-items: center;
    font-size: 1.25rem;

    svg {
      font-size: 1.125rem;
    }
  }

  span:nth-child(2) {
    font-size: 2.5rem;
    line-height: 2.5rem;
    font-weight: 500;
    letter-spacing: -0.1rem;
  }

  span:nth-child(3) {
    color: var(--color-antd-green);
    font-weight: bold;
    opacity: 0.8;
  }

  span {
    display: flex;
    align-items: center;
    gap: 0.75rem;

    svg {
      font-size: 1.25rem;
      cursor: pointer;
    }
  }
`;

export const HeaderPreviewIconsWrapper = styled.div`
  height: 1.125rem;
  width: 1.125rem;
`;

export const HeaderPreviewActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
`;

export const OverviewTabsWrapper = styled(Tabs)`
  &.ant-tabs {
    width: 100%;
  }
  .ant-tabs-tab {
    font-size: 1.125rem;
  }
  .ant-tabs-nav:before {
    border-bottom: 0.5px solid var(--color-border);
  }
  .ant-tabs-tab-btn {
    color: var(--color-text);
  }
`;

export const OverviewPortfolioBox = styled.div<any>`
  display: flex;
  flex-direction: column;

  h3 {
    font-size: 1.5rem;
    font-weight: 500;
    margin: 0;
    color: var(--color-text);
  }

  & > div:first-child {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      font-size: 1rem;
    }
  }

  & > div:last-child {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    border: 0.5px solid var(--color-border);
    min-height: 250px;
    width: 100%;
  }

  ${({ $fullWidth }) =>
    $fullWidth &&
    `
    grid-column: 1 / 3;
   `}
`;

export const OverviewContentGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  margin-bottom: 1rem;
`;

export const TokenAllocationWrapper = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  width: 100%;
  height: 100%;
  gap: 1rem;
`;

export const TokenAllocationTokenList = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  height: 100%;
  overflow-y: auto;
  padding: 1rem;

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }

    & > span:first-child {
      font-weight: bold;
    }
  }
`;

export const PerformanceWrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 1rem;
  width: 100%;
  height: 100%;
  padding: 1rem;
  position: relative;

  & > div:first-child {
    margin-bottom: 0;
  }
`;

export const PerformanceTimeRange = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  bottom: 1rem;
  left: 1rem;

  .ant-tag {
    text-transform: uppercase;

    &:not(.ant-tag-checkable-checked) {
      background-color: var(--color-background-accent);
      color: var(--color-accent-2);
    }
  }
`;

export const TableWrapper = styled.div`
  &&& {
    .ant-table-wrapper {
      width: 100%;
      height: 100%;
    }

    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td,
    .ant-table tfoot > tr > th,
    .ant-table tfoot > tr > td {
      padding: 0.5rem;
      background-color: var(--color-background-accent);
      color: var(--color-accent-2);

      @media ${theme.mediaQueries.mobile} {
        padding: 0.375rem;
        font-size: 0.75rem;
      }
    }

    .ant-table-thead > tr > th {
      max-width: 10rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: var(--color-primary);
      background-color: var(--color-creme);
    }

    .table-row-custom {
      cursor: pointer;

      &:hover {
        filter: brightness(95%);
      }
    }
  }
`;

export const TableActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    font-size: 1rem;
  }
`;

export const NFTGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1.5rem;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  margin-bottom: 1rem;
`;

export const NFTBox = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 0.5rem;
  width: 100%;
  height: 100%;
  border: 0.5px solid var(--color-border);
  border-radius: 0.75rem;
  padding: 0.5rem;
  cursor: pointer;

  img {
    width: 100%;
    height: 200px
    margin-bottom: 0.5rem;
    border-radius: 0.5rem;
  }
`;

export const NFTBoxInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > span:nth-child(1) {
    display: block;
    font-size: 0.875rem;
    line-height: 1rem;
    font-weight: 400;
    letter-spacing: -0.2px;
    color: var(--color-help-text);
  }

  & > span:nth-child(2) {
    display: block;
    font-family: Graphik, sans-serif;
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: bold;
    letter-spacing: -0.6px;
    color: var(--color-text);
  }

  & > div {
    display: grid;
    grid-template-rows: auto 1fr;
    margin-top: 0.5rem;

    & > span:nth-child(1) {
      display: block;
      font-size: 0.75rem;
      line-height: 1rem;
      font-weight: 400;
      letter-spacing: -0.2px;
      color: var(--color-help-text);
    }

    & > span:nth-child(2) {
      display: block;
      font-size: 1rem;
      line-height: 1.25rem;
      font-weight: bold;
      letter-spacing: -0.6px;
      color: var(--color-text);
    }
  }
`;

export const SegmentedWrapper = styled<any>(Segmented)`
  .ant-segmented-item:not(.ant-segmented-item-selected) {
    background-color: ${({ $isDarkMode }) =>
      $isDarkMode ? 'var(--color-gray-3)' : 'initial'};
    color: ${({ $isDarkMode }) =>
      $isDarkMode ? 'var(--color-gray-2)' : 'initial'};
  }
`;

export const PortfolioFilterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  position: relative;

  .activity-search-bar {
    width: 20rem;
    max-width: 20rem;
    font-size: 1rem;
    border: 0.5px solid var(--color-border);
    border-radius: 0.5rem;
  }
`;

export const NFTSorterWrapper = styled<any>(Select)`
  &.ant-select {
    width: 10rem;
  }
`;

export const ActivityTimelineWrapper = styled.div`
  margin-top: 1rem;

  .ant-divider {
    margin: 0;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
  }

  .ant-divider-inner-text {
    color: var(--color-help-text);
  }

  svg {
    font-size: 2rem;
  }

  .ant-timeline-item {
    cursor: pointer;
  }

  .ant-timeline-item-head {
    background-color: var(--color-background);
  }

  .ant-timeline-item-content {
    width: calc(50% - 1.5rem) !important;
  }
  .ant-timeline-item-tail {
    border-left-color: var(--color-gray-2);
  }
`;
