import React, { useCallback, useEffect, useState } from 'react';
import ReactEChart, { EChartsInstance } from 'echarts-for-react';
import * as echarts from 'echarts';
import theme from '../../../theme';
import axios from 'axios';
import { FetchDataProps } from '..';
import { message } from 'antd';
import { ChartWrapper } from '../styles';
import { getEnvironmentVariable } from '../../../shared/helpers';
interface Instance {
  exchange: string;
  direction: string;
  price_out: string;
  price_out_formatted: number;
}

interface Data {
  [key: string]: Instance[][];
}

interface Route {
  name: string;
  type: string;
  data: any[];
}

interface RouteMap {
  [key: string]: Route[];
}

interface ExtractedData {
  keys: string[];
  unifiedRoutes: Route[];
}

interface Props {
  darkMode: boolean;
  originNodes: FetchDataProps;
  data: null | any[];
  setChartInstance: (instance: EChartsInstance) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  isMobile: boolean;
  isReady: boolean;
  show: boolean;
}

const colorList = [
  '#5470c6',
  '#fac858',
  '#9a60b4',
  '#ea7ccc',
  '#7c0400',
  '#ee6666',
  '#3ba272',
  '#85b6c9',
  '#fc8452',
];

const PriceChangeChart: React.FC<Props> = ({
  darkMode,
  originNodes,
  setChartInstance,
  setLoading,
  loading,
  isMobile,
  isReady,
  show,
}) => {
  const [seriesList, setSeriesList] = useState<any[]>([]);
  const [data, setData] = useState<any>();

  const fetchData = async () => {
    try {
      const response = await axios.post(
        `${getEnvironmentVariable('REACT_APP_BASE_URL')}/prices/sorted-data`,
        {
          amount_in_human: originNodes.amount.toString(),
          in_token: originNodes.address0,
          out_token: originNodes.address1,
          block_start: originNodes.startingBlock,
          block_end: originNodes.endingBlock,
          step: originNodes.step,
        },
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );

      return response.data;
    } catch (error) {
      setLoading(false);
      console.error(error);
      message.error('Failed to fetch data');
    }
  };

  function extractRoutes(data: Data): RouteMap {
    // Master list of routes
    const masterRoutes: { [key: string]: Route } = {};

    // Get all routes first
    for (const key in data) {
      const instances = data[key];
      for (const instance of instances) {
        const routeName = instance.map((item) => item.exchange).join(',');

        if (!(routeName in masterRoutes)) {
          masterRoutes[routeName] = {
            name: routeName,
            type: 'line',
            data: [],
          };
        }
      }
    }

    // Add data to each route in masterRoutes for each block
    for (const key in data) {
      const instances = data[key];

      for (const routeName in masterRoutes) {
        const instance = instances.find(
          (instance) =>
            instance.map((item) => item.exchange).join(',') === routeName
        );

        if (instance) {
          const routeData = [instance[instance.length - 1]].map(
            (item) => item.price_out_formatted
          );
          masterRoutes[routeName].data.push(...routeData);
        } else {
          // Push null if the route doesn't exist in the current block
          masterRoutes[routeName].data.push(null);
        }
      }
    }

    // Convert masterRoutes into the desired output format
    const routes: RouteMap = {};

    for (const key in data) {
      routes[key] = Object.values(masterRoutes);
    }

    return routes;
  }

  function extractKeysAndUnifyRoutes(routeMap: RouteMap): ExtractedData {
    const keys: string[] = Object.keys(routeMap);
    const unifiedRoutes: Route[] = [];

    for (const key of keys) {
      for (const route of routeMap[key]) {
        const existingRoute = unifiedRoutes.find((r) => r.name === route.name);

        if (existingRoute) {
          existingRoute.data.push(...route.data);
        } else {
          // Create a new route and add it to unifiedRoutes
          unifiedRoutes.push({
            name: route.name,
            type: route.type,
            data: [...route.data],
          });
        }
      }
    }

    return {
      keys,
      unifiedRoutes,
    };
  }

  useEffect(() => {
    fetchData().then((data) => {
      if (data === undefined) {
        setLoading(false);
        return;
      }

      const uniqueR = extractRoutes(data);
      const splitData = extractKeysAndUnifyRoutes(uniqueR);
      setData(splitData);

      run(uniqueR, splitData);
    });

    function run(uniqueR: any, splitData: any) {
      const { unifiedRoutes } = splitData;
      const paths: string[] = unifiedRoutes.map((route: any) => route.name);
      const seriesList: echarts.SeriesOption[] = [];

      if (paths.length === 0) {
        message.info('No data found');
      }

      echarts.util.each(paths, function (path: string, index: any) {
        const data = (Object.values(uniqueR)[0] as Route[]).find(
          ({ name }) => name === path
        )?.data;

        seriesList.push({
          type: 'line',
          data,
          color: colorList[index % colorList.length],
          symbolSize: 12,
          name: path,
          endLabel: {
            show: true,
            formatter: function (params: any) {
              return params.value?.toFixed(2);
            },
            fontSize: isMobile ? 10 : 14,
          },
          labelLayout: {
            moveOverlap: 'shiftY',
          },
          emphasis: {
            focus: 'series',
          },
          encode: {
            x: 'Block',
            y: 'Price',
            label: ['Name', 'Price'],
            itemName: 'Block',
            tooltip: ['Price'],
          },
        });

        setSeriesList(seriesList);
      });
      setLoading(false);
    }
  }, []);

  const tooltipFormater = useCallback((params: any) => {
    const { name, value, seriesName, color } = params;

    return `
        <div class="custom-tooltip">
          <div class="tooltip-value">${value?.toFixed(2)}</div>
          <div class="tooltip-date" style="color: ${color};">Path: ${seriesName?.slice(
      0,
      4
    )}...${seriesName?.slice(-4)}</div>
          <div class="tooltip-hours">Block: ${name}</div>
        </div>
      `;
  }, []);

  const option = {
    animationDuration: 3000,
    xAxis: {
      data: data?.keys,
      type: 'category',
      boundaryGap: true,
      axisPointer: {
        snap: true,
      },
      splitLine: {
        show: true,
      },
      axisLabel: {
        textStyle: {
          color: darkMode ? theme.colors.creme : theme.colors.primary,
          fontSize: isMobile ? 10 : 12,
        },
      },
    },
    yAxis: {
      min: (value: any) => Math.floor(value.min * 100) / 100,
      max: (value: any) => Math.ceil(value.max * 100) / 100,
      boundaryGap: false,
      axisLabel: {
        formatter: function (value: any) {
          return `${value}`;
        },
        textStyle: {
          color: darkMode ? theme.colors.creme : theme.colors.primary,
          fontSize: isMobile ? 10 : 12,
        },
      },
    },
    series: seriesList,
    tooltip: {
      trigger: 'item',
      axisPointer: {
        type: 'cross',
        snap: true,
        label: {
          show: false,
          color: darkMode ? theme.colors.black : theme.colors.white,
          backgroundColor: darkMode ? theme.colors.creme : theme.colors.primary,
        },
      },
      formatter: tooltipFormater,
    },
    grid: {
      top: 64,
      left: isMobile ? 60 : 82,
      right: isMobile ? 60 : 116,
      bottom: 32,
    },
    legend: {
      show: false,
    },
    textStyle: {
      fontFamily: 'PolySans Neutral',
    },
  };

  if (loading || !isReady) return null;

  return (
    <ChartWrapper style={{ display: show ? 'grid' : 'none' }}>
      <h2>Price change over time</h2>
      <ReactEChart
        option={option}
        showLoading={loading}
        onChartReady={(instance) => {
          setChartInstance(instance);
        }}
        style={{
          width: '100%',
          height: isMobile ? 280 : 500,
          maxWidth: 900,
          maxHeight: 400,
        }}
      />
    </ChartWrapper>
  );
};

export default PriceChangeChart;
