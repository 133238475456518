const theme = {
  colors: {
    primary: '#3a0623',
    secondary: '#ff2c3b',
    tercery: '#fe945d',
    black: '#0d1016',
    black2: '#28282a',
    creme: '#f7e6d0',
    gray: '#f4f4f4',
    gray2: '#DFE1E4',
    gray3: '#6c6f73',
    blue: '#3fb1e3',
    'light-gray': 'rgba(0, 0, 0, 0.55)',
    'antd-green': '#52c41a',
    white: '#fff',
  },
  mediaQueries: {
    mobile: `(max-width: 768px)`,
    laptop: `(max-width: 1600px)`,
  },
};

export default theme;
