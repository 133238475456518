import availableChainsJSON from '../supportedChains.json';

export const SWAP_LABEL_PAIRS = {
  /* ETH */
  '0x5c69bee701ef814a2b6a3edd4b1652cb9cc5aa6f': 'Uniswap V2',
  '0xc0aee478e3658e2610c5f7a4a2e1777ce9e4f2ac': 'SushiSwap V2',
  '0x96ff042f8c6757fce515d171f194b5816cafee11': 'Sumswap V2',
  '0x115934131916c8b277dd010ee02de363c09d037c': 'ShibaSwap',
  '0x26023843814cff92b8d75311d64d1c032b8b29f2': 'Safe Swap',
  '0x75e48c954594d64ef9613aeef97ad85370f13807': 'SakeSwap',
  '0x696708db871b77355d6c2be7290b27cf0bb9b24b': 'LinkSwap',
  '0xd34971bab6e5e356fd250715f5de0492bb070452': 'DXSwap',
  '0xa40ec8a93293a3179d4b544239916c1b68cb47b6': 'SunflowerSwap',
  '0x9deb29c9a4c7a88a3c0257393b7f3335338d9a9d': 'CroDefiSwap',
  '0x2294577031f113df4782b881cf0b140e94209a6f': 'Miniswap',
  '0x4eef5746ed22a2fd368629c1852365bf5dcb79f1': 'Future Lithium',
  /* AVAX */
  '0x58c8cd291fa36130119e6deb9e520fbb6aca1c3a': 'YetiSwap',
  '0xefa94de7a4656d787667c749f7e1223d71e9fd88': 'Pangolin',
  '0xc35dadb65012ec5796536bd9864ed8773abc74c4': 'SushiSwap V2',
  '0x091d35d7f63487909c863001ddca481c6de47091': 'Elk',
  '0xc7e37a28bb17edb59e99d5485dc8c51bc87ae699': 'PandaSwap',
  '0x4fe4d8b01a56706bc6cad26e8c59d0c7169976b3': 'OliveCash',
  '0xe0c1bb6df4851feeedc3e14bd509feaf428f7655': 'Lydia Finance',
  '0x2ef422f30cdb7c5f1f7267ab5cf567a88974b308': 'Zero',
  '0x3587b8c0136c2c3605a9e5b03ab54da3e4044b50': 'Baguette',
  '0xcfba329d49c24b70f3a8b9cc0853493d4645436b': 'Canary',
  '0x9ad6c38be94206ca50bb0d90783181662f0cfa10': 'Trader Joe',
  '0x58a08bc28f3e8dab8fb2773d8f243bc740398b09': 'PartySwap',
  '0x8e6f4af0b6c26d16febdd6f28fa7c694bd49c6bf': 'HCSwap',
  '0x5c02e78a3969d0e64aa2cfa765acc1d671914ac0': 'Complus',
  '0x7009b3619d5ee60d0665ba27cf85edf95fd8ad01': 'HurricaneSwap V2',
  '0xa98ea6356a316b44bf710d5f9b6b4ea0081409ef': 'WaultSwap',
  '0x9a89fa373186ecc1ccb3b9fe08335ffd9cdf35d8': 'Ruggy',
  '0xe5e970fe3a90f314977a9fd41e349486a9e8c4fe': 'Thors Mead V2',
  '0x2db46feb38c57a6621bca4d97820e1fc1de40f41': 'HakuSwap',
  /* BSC */
  '0xbcfccbde45ce874adcb698cc183debcf17952812': 'PancakeSwap',
  '0x8a1e9d3aebbbd5ba2a64d3355a48dd5e9b511256': 'BurgerSwap',
  '0xf38d202723a9376c034ed5b8cecb4efe8f249836': 'NyanSwop',
  '0x553990f2cba90272390f62c5bdb1681ffc899675': 'BSCSwap',
  '0x32ce36f6ea8d97f9fc19aab83b9c6d2f52d74470': 'Unisave V2',
  '0x0841bd0b734e4f5853f0dd8d7ea041c241fb0da6': 'Apeswap',
  '0xcbac17919f7aad11e623af4fea98b10b84802eac': 'Mochi',
  '0x7810d4b7bc4f7faee9deec3242238a39c4f1197d': 'SwipeSwap',
  '0x3657952d7ba5a0a4799809b5b6fdff9ec5b46293': 'WardenSwap V1.5',
  '0x918d7e714243f7d9d463c37e106235dcde294ffc': 'StableXSwap',
  '0xdd538e4fd1b69b7863e1f741213276a6cf1efb3b': 'CheeseSwap',
  '0x9ad32bf5dafe152cbe027398219611db4e8753b3': 'Bao Finance',
  '0x3cd1c46068daea5ebb0d3f55f6915b10648062b8': 'MdexSwap',
  '0xca143ce32fe78f1f7019d7d551a6402fc5350c73': 'PancakeSwap V2',
  '0x0a376ee063184b444ff66a9a22ad91525285fe1c': 'YieldFields',
  '0x858e3312ed3a876947ea49d572a7c42de08af7ee': 'Biswap',
  '0xe52ccf7b6ce4817449f2e6fa7efd7b567803e4b4': 'Orion',
  '0x3165d94dd2f71381495cb897832de02710a0dce5': 'Venom LP',
  '0xc7a506ab3ac668eab6bf9ecf971433d6cfef05d9': 'AlitaSwap LP',
  '0x86407bea2078ea5f5eb5a52b2caa963bc1f889da': 'BabySwap',
  '0xf3e9f12df29ccd921c0143b98d32a26202f78d80': 'Edda',
  '0xf0bc2e21a76513aa7cc2730c7a1d6dee0790751f': 'KnightSwap',
  '0x86a859773cf6df9c8117f20b0b950ada84e7644d': 'SafeSwap',
  '0xe0636f192a88de6f1c9ed1a6a0f265c9775c8596': 'Niob Finance',
  '0x9a272d734c5a0d7d84e0a892e891a553e8066dce': 'FSTSwap',
  '0x087e6071164e6fdc0d931ad38cb38dbfa64b081c': 'DankSwap',
  '0x01bf7c66c6bd861915cdaae475042d3c4bae16a7': 'BakerySwap',
  '0x2c39801cc496e01b163cd3314295c30a98a26ef3': 'Uranium',
  '0xd76d8c2a7ca0a1609aea0b9b5017b3f7782891bf': 'OpenOcean LP',
  '0x4e66fda7820c53c1a2f601f84918c375205eac3e': 'Dopple Finance',
  '0xe759dd4b9f99392be64f1050a6a8018f73b53a13': 'AutoShark',
  '0x73d9f93d53505cb8c4c7f952ae42450d9e859d10': 'Dinosaur Eggs',
  '0x3cc0fc3b82af5ea222d3545249f81644159eec8a': 'RastaFinance',
  '0xb42e3fe71b7e0673335b3331b3e1053bd9822570': 'WaultSwap',
  '0x8b6ca4b3e08c9f80209e66436187088c99c9c2ac': 'BSCSwap',
  '0x670f55c6284c629c23bae99f585e3f17e8b9fc31': 'PantherSwap',
  '0x81bb3e7b2448786a82fec9fe49e311af683f6723': 'GreenTeaSwap',
  '0xd6faa863be0795d5aab2ba7ef44e3e7521fa62ae': 'TukTuk Finance',
  '0x43ebb0cb9bd53a3ed928dd662095ace1cef92d19': 'Definix',
  '0xdb984fd8371d07db9cbf4a48eb9676b09b12161d': 'BoxSwap',
  '0xf238d267b3b1c85f2a95354251c20626bb7bc2a1': 'BeGlobal',
  '0xcbe7425662bf0edf164abf12c881ced6fdafe75e': 'Slime',
  '0xc3187eb9ab08eb845a12da6a93c3ab8655c5af80': 'SparkSwap',
  '0x7d2ce25c28334e40f37b2a068ec8d5a59f11ea54': 'PinkSwap',
  '0x4ded9d6013a708d1eb743086e7d8cad436ff560d': 'CaramelSwap',
  '0xae52c26976e56e9f8829396489a4b7ffebe8aae9': 'ManySwap',
  '0x979c5d4665b5531254c811fa55c85f270e4e7fc7': 'BerrySwap',
  '0x3e708fdbe3ada63fc94f8f61811196f1302137ad': 'CafeSwap',
  '0x137f34df5bcdb30f5e858fc77cb7ab60f8f7a09a': 'YouSwap',
  '0xaba5ca9729294c2a49cb6e741f2b97b988e80407': 'FatAnimal',
  '0x7897c32cbda1935e97c0b59f244747562d4d97c1': 'BXH',
  '0xd404b033aca6621c76cbfed666c98088a822a78a': 'FreeFinance',
  '0xac653ce27e04c6ac565fd87f18128ad33ca03ba2': 'HyperSwap',
  '0xa265535863305ce0a2a8ec330c2cec972aca3004': 'Kalata',
  '0xd6715a8be3944ec72738f0bfdc739d48c3c29349': 'Nominex',
  '0xa053582601214feb3778031a002135cbbb7dba18': 'Planet Finance',
  '0xcc5414e7ce73b717a14e682e9899785a13002db9': 'Egon',
  '0xce8fd65646f2a2a897755a1188c04ace94d2b8d0': 'BSCSwap',
  '0x94b4188d143b9dd6bd7083ae38a461fcc6aad07e': 'PureSwap',
  '0x4cbaf01d645a233d11cd5a19939387a94d7f2f02': 'PactSwap',
  '0x731d91dd835330cb2beeafb9a1672e3177b7168f': 'AleSwap',
  '0x59da12bdc470c8e85ca26661ee3dcd9b85247c88': 'FastSwap',
  '0x40903ab5c9591699bd3d4d312a9e1156b639dc66': 'PYESwap',
  '0x8fea6f6524b60c7464090d755ec28ffb828e706b': 'PokéMoon',
  '0xc801c7980c8c7900bc898b1f38392b235ff64097': 'Foodcourt',
  '0x4d05d0045df5562d6d52937e93de6ec1fecdad21': 'SafeSwap',
  '0x0eb58e5c8aa63314ff5547289185cc4583dfcbd5': 'JetSwap',
  '0x1ba94c0851d96b2c0a01382bf895b5b25361ccb2': 'ButterSwap',
  '0xe8a1c3cba77fa70ff3936d3106c3f6803b145cef': 'Mocktail Finance',
  '0x092ee062baa0440b6df6bbb7db7e66d8902bfdf7': 'Shibance',
  '0x6d29ae56e3dce38531c100b3a5e7ff61ca30a534': 'NaverSwap',
  '0xf964b1b0c64ccfb0854e77fd2dbed68d0aadd26c': 'CoinSwap V1',
  '0xc2d8d27f3196d9989abf366230a47384010440c0': 'CoinSwap',
  '0x971a5f6ef792ba565cdf61c904982419aa77989f': 'KokomoSwap',
  '0x6f12482d9869303b998c54d91bcd8bccba81f3be': 'MarsSwap',
  '0x97a256514a0d1f61356afd97188cd1dda443e4a0': 'LegendSwap',
  '0xb836017acf10b8a7c6c6c9e99efe0f5b0250fc45': 'Toad Network',
  '0xcb4ee9910811edb5ff3fe0e3ce3a8ced25e24079': 'CBD Cash Protocol',
  '0x782536abe989570211419352fbbf98a083380217': 'STMSwap',
  '0xd5da6ea22ef4c69ed0bea50fc03df6c561c5573f': 'BambooSwap',
  '0xac59b7af8a548f4f0bef8c582c1c0a1a3a53d5ce': 'FarmSwap',
  '0x720767aea828a9730123679f3ce3de01369b4575': 'StormSwap',
  '0x14a19edcb52bcb2c94fcf34c6bbaf28a0a328912': 'SwapKing',
  '0x97bcd9bb482144291d77ee53bfa99317a82066e8': 'GIBX Swap',
  '0x4dce5bdb81b8d5edb66ca1b8b2616a8e0dd5f807': 'LatteSwap',
  '0xbcae2c6cb2477fccf75d0b0c4e6059da00c4086a': 'SoftDrink Swap',
  '0x2ba75f08fae6fa6c2b09c7f2d3cfe9a9e6d3167f': 'Oxy',
  '0xe49350294c38919495d648c49eb1e36c52391ef5': 'Moonlift',
  '0x14a16d070d0b273574562180c84a875ead1d35f2': 'Shib Cake',
  '0x31affd875e9f68cd6cd12cee8943566c9a4bba13': 'Elk',
  '0xc4b171aa0905c16cc32dff1c9a69e2f19912b8f6': 'WoonklySwap',
  '0xde82ce2a2f30a68b533ff99ca8f80f4210408179': 'SwapTuna',
  '0x60581ae482ca1f787a561ed73bdcd93a9008c448': 'BnEX',
  '0xc8c9ab92ab70e954af23c49f98aacc1f94ebeed7': 'IceCream Swap',
  '0x5a67d6d1e1c36dca265ca9b9bda0e3eb5af9b634': 'MoonDoge',
  '0x71f843bd057d2ee39ae52186a33c3afd1124805a': 'Unicornx',
  '0xa74f588d6cbccc5586d7d8b3f85fff574f10193e': 'GrapeSwap',
  '0x590a402a8fc80e23e6ea4f5a81674df66fb689c5': 'Bafi',
  '0x381fefadab5466bff0e8e96842e8e76a143e8f73': 'AmpleSwap',
  '0xd654cbf99f2907f06c88399ae123606121247d5c': 'Jswap',
  '0xa5ba037ec16c45f8ae09e013c1849554c01385f5': 'Unifi',
  '0xa943ea143cd7e79806d670f4a7cf08f8922a454f': 'Uranium Finance',
  '0xeb10f4fe2a57383215646b4ac0da70f8edc69d4f': 'LeonicorpSwap',
  '0x542b6524abf0bd47dc191504e38400ec14d0290c': 'Yoshi.exchange',
  '0x52abdb3536a3a966056e096f2572b2755df26eac': 'ZERO',
  /* OPT */
  '0x8bcedd62dd46f1a76f8a1633d4f5b76e0cda521e': 'ZipSwap',
};

export const DragItemTypes = {
  CHART: 'chart',
};

export const CONTACT_EMAIL = 'contact@superchain.network';

export enum SupportedChains {
  ETH = 'eth',
  AVAX = 'avax',
  BSC = 'bsc',
  OPT = 'opt',
}

export const AvailableChains = Object.entries(availableChainsJSON)
  .filter(([_, value]) => value)
  .reduce<{ [index: string]: string }>((acc, [key, _]) => {
    acc[key] = key.toLowerCase();
    return acc;
  }, {}) as unknown as SupportedChains;

export const yBugId = 'y2cpb037dy38g9zmg843';

export const TOP_20_ETH_TOKENS = [
  '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2', // WETH
  '0xdac17f958d2ee523a2206206994597c13d831ec7', // USDT
  '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48', // USDC
  '0x6b175474e89094c44da98b954eedeac495271d0f', // DAI
  '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599', // WBTC
  '0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce', // SHIB
  '0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0', // MATIC
  '0x912CE59144191C1204E64559FE8253a0e49E6548', // ARB
  '0x514910771af9ca656af840dff83e8264ecf986ca', // LINK
  '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984', // UNI
  '0x2b591e99afe9f32eaa6214f7b7629768c40eeb39', // HEX
  '0x75231f58b43240c9718dd58b4967c5114342a86c', // OKB
  '0x0000000000085d4780B73119b644AE5ecd22b376', // TUSD
  '0x6de037ef9ad2725eb40118bb1702ebb27e4aeb24', // RNDR
  '0x3845badAde8e6dFF049820680d1F14bD3903a5d0', // SAND
  '0x69af81e73a73b40adf4f3d4223cd9b1ece623074', // MASK
  '0xbbbbca6a901c926f240b89eacb641d8aec7aeafd', // LRC
  '0xf629cbd94d3791c9250152bd8dfbdf380e2a3b9c', // ENJ
  '0x7420B4b9a0110cdC71fB720908340C03F9Bc03EC', // JASMY
  '0xa117000000f279d81a1d3cc75430faa017fa5a2e', // ANT
  '0x467719aD09025FcC6cF6F8311755809d45a5E5f3', // AXL
  '0x4f9254c83eb525f9fcf346490bbb3ed28a81c667', // CELR
  '0x853d955acef822db058eb8505911ed77f175b99e', // FRAX
  '0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2', // MKR
  '0x5a98fcbea516cf06857215779fd812ca3bef1b32', // LDO
  '0x6982508145454ce325ddbe47a25d4ec3d2311933', // PEPE
  '0xc669928185dbce49d2230cc9b0979be6dc797957', // BTT
];

export const TOP_20_UNISWAP_POOLS = [
  '0x88e6a0c2ddd26feeb64f039a2c41296fcb3f5640', // USDC-ETH
  '0xcbcdf9626bc03e24f779434178a73a0b4bad62ed', // WBTC-ETH
  '0x5777d92f208679db4b9778590fa3cab3ac9e2168', // DAI-USDC
  '0x4585fe77225b41b697c938b018e2ac67ac5a20c0', // WBTC-ETH
  '0xc63b0708e2f7e69cb8a1df0e1389a98c35a76d52', // FRAX-USDC
  '0x8ad599c3a0ff1de082011efddc58f1908eb6e6d8', // USDC-ETH
  '0x11b815efb8f581194ae79006d24e0d814b7697f6', // ETH-USDT
  '0x3416cf6c708da44db2624d63ea0aaef7113527c6', // USDC-USDT
  '0x7379e81228514a1d2a6cf7559203998e20598346', // ETH-sETH2
  '0x6c6bc977e13df9b0de53b251522280bb72383700', // DAI-USDC
  '0x4e68ccd3e89f51c3074ca5072bbac773960dfa36', // ETH-USDT
  '0x99ac8ca7087fa4a2a1fb6357269965a2014abc35', // WBTC-USDC
  '0xc5af84701f98fa483ece78af83f11b6c38aca71d', // ETH-USDT
  '0x7bea39867e4169dbe237d55c8242a8f2fcdcc387', // USDC-ETH
  '0x1d42064fc4beb5f8aaf85f4617ae8b3b5b8bd801', // UNI-ETH
  '0x5c128d25a21f681e678cb050e551a895c9309945', // BIT-ETH
  '0x97e7d56a0408570ba1a7852de36350f7713906ec', // DAI-FRAX
  '0x40e629a26d96baa6d81fae5f97205c2ab2c1ff29', // ETH-ETHM
  '0xf56d08221b5942c428acc5de8f78489a97fc5599', // GNO-ETH
  '0xa6cc3c2531fdaa6ae1a3ca84c2855806728693e8', // LINK-ETH
];

export const CHAINS_MAP = {
  1: 'ETH',
};

export const MARKET_CAP_TOOLTIP =
  "The total market value of a cryptocurrency's circulating supply. It is analogous to the free-float capitalization in the stock market. Market Cap = Current Price x Circulating Supply.";
export const CIRCULATING_SUPPLY_TOOLTIP =
  'The amount of coins that are circulating in the market and are in public hands. It is analogous to the flowing shares in the stock market.';

export const SUPERCHAIN_HOMEPAGE = 'https://www.superchain.network';

export const WINDOW_TYPE_DECIMAL_TO_TIMESPAN = {
  lastInfo: 16777216,
  day30: 1966080, // current day
  day29: 1900544, // previous day
  day23: 1507328, // 7 days ago
};
