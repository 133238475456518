import { DownloadOutlined } from '@ant-design/icons';
import { Button, Divider, message, Radio, Tag, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { getFetchInstance, axios } from '../../shared/api';
import { AiOutlineAreaChart, AiOutlineUnorderedList } from 'react-icons/ai';

import {
  ActionBar,
  ChartControlsWrapper,
  ChartDateControlsWrapper,
  ChartExtraActions,
  ChartSectionContainer,
  ChartViewTypeContainer,
  CheckboxInvert,
  ConfirmedIcon,
  CopyIcon,
  DownloadCSVButton,
  ExchangeRateWrapper,
  ExtraStatsBlock,
  ExtraStatsWrapper,
  FactoryLabel,
  InvertPriceContainer,
  LoadingOverlay,
  PageTitle,
  PageTitleWrapper,
  PairContainer,
  PairContractAddressActions,
  PairContractAddressLabel,
  PairContractAddressWrapper,
  PairLabelTooltipWrapper,
  StatsLabel,
  StatsValue,
  TitleWrapper,
  TokenPrice,
  VisitIcon,
  Wrapper,
} from './styles';

import Chart from './Chart';
import {
  calculateNumberOfDecimalPlaces,
  getCoinGeckoAPIUrl,
  getCoinGeckoTokenUrl,
  getScanUrl,
  openExternalLink,
  renderFractionUnitOfETHOrBTC,
} from '../../shared/helpers';
import { GlobalContextProps, SelectedPair } from '../../context/globalState';
import useWebSocket from '../../hooks/useWebSocket';
import Loader from '../Loader';
import { buttonIcon } from '../Sidebar/ResultList';
import { IoIosMove } from 'react-icons/io';
import { RiDeleteBin2Fill } from 'react-icons/ri';
import useDragAndDrop from '../../hooks/useDragAndDrop';
import SwapTable from './SwapTable';
import { SWAP_LABEL_PAIRS } from '../../shared/constants';

export interface Metadata {
  address: [string];
  block_number: [number];
  creator: [string];
  nonce: [number];
  name?: [string];
  decimals?: [number];
  symbol?: [string];
  token0: [string];
  token1: [string];
  total_supply?: [number];
}

type DataPriceFull = [number, string, number, number, number, number, number];

export enum ChartViewTypes {
  CHART = 'chartView',
  TRANSACTIONS = 'transactionsView',
}

export enum ChartTimeFrames {
  HOUR = 'hour',
  TWELWE_HOURS = '12hours',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  THREE_MONTHS = '3months',
  YEAR = 'year',
  YEAR_TO_DATE = 'yearToDate',
}

export interface DataZoomProps {
  startValue: number;
  endValue: number;
}

export interface ParsedPairData {
  date: Date;
  timestamp: number;
  price: number;
  volume: number;
  txId: string;
  blockNumber: number;
  reserve0: number;
  reserve1: number;
  pool?: string;
  uniswapVersion?: string;
}
export interface PairDetailsProps extends SelectedPair {
  removePair: (address: string) => void;
  isMobile: boolean;
  darkMode: boolean;
  index: number;
  pairViewGlobalState: GlobalContextProps['pairViewGlobalState'];
  setUser: GlobalContextProps['setUser'];
}

export interface SelectedLabels {
  Price: boolean;
  Volume: boolean;
  K: boolean;
}

export const timeFrameToDays = {
  hour: 1 / 24,
  '12hours': 1 / 2,
  day: 1,
  week: 7,
  month: 30,
  '3months': 90,
  year: 365,
  yearToDate: moment().dayOfYear(),
};

export const daysToFormat = (days: number) => {
  if (days <= 1) return 'h:mma';
  if (days <= 7) return 'MMM D';
  if (days <= 30) return 'MMM D';
  if (days <= 90) return 'MMM Do YY';
  if (days <= 365) return 'MMM Do YY';
  return 'MMM Do YY';
};

const shortenContractAddress = (address: string = '', isMobile: boolean) => {
  const maxLength = isMobile ? 6 : 10;
  return address.slice(0, maxLength) + '...' + address.slice(-maxLength);
};

const PairDetails: React.FC<PairDetailsProps> = ({
  address,
  chain,
  removePair,
  index,
  isMobile,
  darkMode,
  pairViewGlobalState,
  setUser,
}) => {
  const navigate = useNavigate();
  const [data, setData] = useState<ParsedPairData[]>([]);
  const [dataInverted, setDataInverted] = useState<ParsedPairData[]>([]);
  const [metadata, setMetadata] = useState<Metadata[]>([]);
  const [priceInverted, setPriceInverted] = useState(false);
  const [ready, setReady] = useState(false);
  const [chartTimeframe, setChartTimeframe] = useState(ChartTimeFrames.DAY);
  const [dateAxisLabelFormat, setDateAxisLabelFormat] = useState(
    daysToFormat(7)
  );
  const [dataZoom, setDataZoom] = useState<DataZoomProps>({
    startValue: 0,
    endValue: moment().unix() * 1000,
  });

  const [view, setView] = useState<ChartViewTypes>(ChartViewTypes.CHART);
  const [selectedLegends, setSelectedLegends] = useState<
    Partial<SelectedLabels>
  >({
    Price: true,
    Volume: false,
    K: false,
  });
  const { lastMessage, connectionStatus } = useWebSocket({ address, chain });
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);
  const [token1Price, setToken1Price] = useState(0);
  const [token2Price, setToken2Price] = useState(0);
  const [volume24hr, setVolume24hr] = useState(0);

  const { ref, isOver, isDragging, drag, handlerId } = useDragAndDrop({
    id: address,
    index,
  });

  async function getPriceData(
    chain: string,
    address: string
  ): Promise<DataPriceFull[]> {
    const result = await getFetchInstance({
      url: `/api/${chain}/price-full/${address}`,
      setUser,
    });
    return result.data as DataPriceFull[];
  }

  const fetchMetadata = async (chain: string, address: string) => {
    const metadata = await getFetchInstance({
      url: `/api/${chain}/metadata/${address}`,
      setUser,
    });
    setMetadata(metadata.data as Metadata[]);
  };

  const getPairExchangeRate = async (
    address: string,
    token: 'token1' | 'token2'
  ) => {
    const result: any = await axios(getCoinGeckoAPIUrl(chain, address));
    const price = result.data[address].usd as number;

    if (token === 'token1') {
      setToken1Price(price);
    }
    if (token === 'token2') {
      setToken2Price(price);
    }
  };

  const fetchCollection = async (chain: string, address: string) => {
    const data = await getPriceData(chain, address);
    if (!data) {
      navigate('/home');
    } else {
      const chartData = data.map((swap: DataPriceFull) => ({
        date: new Date(swap[2] * 1000),
        timestamp: swap[2],
        price: swap[3],
        volume: swap[4],
        txId: swap[1],
        blockNumber: swap[0],
        reserve0: swap[5],
        reserve1: swap[6],
      }));

      const chartDataInverted = chartData.map((swap: ParsedPairData) => ({
        ...swap,
        price: 1 / swap.price,
      }));

      setData(chartData);
      setDataInverted(chartDataInverted);
      setReady(true);
    }
  };

  const setZoomOnDaysChange = (timeFrame: string) => {
    const timeSpan = timeFrameToDays[timeFrame as keyof typeof timeFrameToDays];
    const lastElement = data[data.length - 1];
    const endValue = lastElement?.timestamp || 0;
    const startValue =
      moment(endValue * 1000)
        .subtract(timeSpan, 'days')
        .unix() || 0;

    setDataZoom({
      startValue: startValue * 1000,
      endValue: endValue * 1000,
    });
  };

  useEffect(() => {
    fetchCollection(chain, address);
    fetchMetadata(chain, address);
  }, [chain, address]);

  useEffect(() => {
    const address1 = metadata[1]?.address?.[0];
    const address2 = metadata[2]?.address?.[0];

    if (address1) {
      getPairExchangeRate(address1, 'token1');
    }
    if (address2) {
      getPairExchangeRate(address2, 'token2');
    }
  }, [metadata]);

  useEffect(() => {
    const volume24hr = data.reduce((acc: any, swap: any) => {
      const yesterday = moment().subtract(1, 'days');
      if (moment(swap.date).isAfter(yesterday)) {
        return acc + swap.volume;
      }

      return acc;
    }, 0);

    setVolume24hr(volume24hr);
  }, [data]);

  useEffect(() => {
    if (ready) {
      setZoomOnDaysChange(chartTimeframe);
    }
  }, [ready]);

  useEffect(() => {
    if (lastMessage.subscription?.address === address && lastMessage.data) {
      const message = lastMessage.data.full[0];

      const newDataPoint = {
        date: new Date(message.timestamp * 1000),
        timestamp: message.timestamp,
        price: message.price,
        txId: message.txId,
        volume: message.volume,
        blockNumber: message.blockNumber,
        reserve0: message.reserve0,
        reserve1: message.reserve1,
      };

      const newDataPointInverted = {
        ...newDataPoint,
        price: 1 / newDataPoint.price,
      };

      let newData = [...data];
      newData.shift();
      newData.push(newDataPoint);
      setData(newData);

      let newDataInverted = [...dataInverted];
      newDataInverted.shift();
      newDataInverted.push(newDataPointInverted);
      setDataInverted(newDataInverted);
    }
  }, [lastMessage, priceInverted]);

  useEffect(() => {
    const format = daysToFormat(
      timeFrameToDays[chartTimeframe as keyof typeof timeFrameToDays]
    );
    setDateAxisLabelFormat(format);
  }, [chartTimeframe]);

  useEffect(() => {
    if (pairViewGlobalState.active) {
      if (pairViewGlobalState.chartViewType !== view) {
        setView(pairViewGlobalState.chartViewType);
      }
      if (pairViewGlobalState.chartTimeFrame !== chartTimeframe) {
        setChartTimeframe(pairViewGlobalState.chartTimeFrame);
        setZoomOnDaysChange(pairViewGlobalState.chartTimeFrame);
      }
    }
  }, [pairViewGlobalState]);

  const onChartClick = (params: any) => {
    if (isMobile) return;

    const { txId } = data[params.dataIndex];

    const url = getScanUrl(chain, txId);
    openExternalLink(url);
  };

  const onControlsClick = (e: any) => {
    const timeFrame = e.target.value;

    setChartTimeframe(timeFrame);
    setZoomOnDaysChange(timeFrame);
  };

  const onChartTypeClick = (e: any) => {
    const viewType = e.target.value as ChartViewTypes;

    setView(viewType);
  };

  const onLegendSelect = ({ selected }: any) => {
    setSelectedLegends(selected);
  };

  const onChartTitleClick = (contractAddress: string) => {
    const url = getScanUrl(chain, contractAddress, 'address');
    openExternalLink(url);
  };

  const onAddressCopyClick = async () => {
    const contractAddress = metadata[0]?.address[0];

    await navigator.clipboard.writeText(contractAddress);
    setCopiedToClipboard(true);
  };

  const onTokenPriceClick = (token: 'token1' | 'token2') => {
    const address1 = metadata[1]?.address?.[0];
    const address2 = metadata[2]?.address?.[0];
    const url = getCoinGeckoTokenUrl(token === 'token1' ? address1 : address2);

    openExternalLink(url);
  };

  const token1Name = metadata?.[1]?.name?.[0] || 'Loading';
  const token2Name = metadata?.[2]?.name?.[0] || 'Loading';
  const token1Symbol = metadata?.[1]?.symbol?.[0] || '';
  const token2Symbol = metadata?.[2]?.symbol?.[0] || '';
  const creator =
    SWAP_LABEL_PAIRS[
    metadata?.[0]?.creator?.[0] as keyof typeof SWAP_LABEL_PAIRS
    ] || '';

  return (
    <Wrapper
      ref={ref}
      role="Chart bucket"
      data-handler-id={handlerId}
      $isDragging={isDragging}
      $isOver={isOver}
    >
      <PairContainer $isChartView={[ChartViewTypes.CHART].includes(view)}>
        <TitleWrapper>
          <PageTitleWrapper>
            <PairContractAddressWrapper>
              <Tooltip
                title={
                  <PairLabelTooltipWrapper>
                    <div>view on block explorer</div>
                    <VisitIcon />
                  </PairLabelTooltipWrapper>
                }
              >
                <PairContractAddressLabel
                  onClick={() => onChartTitleClick(metadata[0]?.address[0])}
                >
                  {shortenContractAddress(metadata[0]?.address[0], isMobile)}
                </PairContractAddressLabel>
              </Tooltip>
              <PairContractAddressActions>
                <Tooltip
                  title={copiedToClipboard ? 'Copied!' : 'Copy to clipboard'}
                >
                  <div>
                    {copiedToClipboard ? (
                      <ConfirmedIcon />
                    ) : (
                      <CopyIcon onClick={onAddressCopyClick} />
                    )}
                  </div>
                </Tooltip>
                {isMobile && (
                  <>
                    <Divider type="vertical" />
                    <PairContractAddressActions
                      onClick={() => onChartTitleClick(metadata[0]?.creator[0])}
                      style={{ marginLeft: 0 }}
                    >
                      {creator}
                    </PairContractAddressActions>
                  </>
                )}
              </PairContractAddressActions>
            </PairContractAddressWrapper>
            <PageTitle>
              {isMobile ? (
                <>
                  <Tooltip title={token1Name}>
                    <div>{token1Symbol}</div>
                  </Tooltip>
                  <div>{'-'}</div>
                  <Tooltip title={token2Name}>
                    <div>{token2Symbol}</div>
                  </Tooltip>
                </>
              ) : (
                <>
                  <div>{token1Name}</div>
                  <div>{'-'}</div>
                  <div>{token2Name}</div>
                </>
              )}
              <Divider type="vertical" />
              <div>{buttonIcon[chain]}</div>
              {!isMobile && (
                <>
                  <Divider type="vertical" />
                  <Tooltip
                    title={
                      <PairLabelTooltipWrapper>
                        <div>factory contract</div>
                        <VisitIcon />
                      </PairLabelTooltipWrapper>
                    }
                  >
                    <FactoryLabel
                      onClick={() => onChartTitleClick(metadata[0]?.creator[0])}
                    >
                      {creator}
                    </FactoryLabel>
                  </Tooltip>
                </>
              )}

              <ExchangeRateWrapper>
                <TokenPrice onClick={() => onTokenPriceClick('token1')}>
                  <div>USD/{token1Symbol}</div>
                  <div className="price">${token1Price}</div>
                </TokenPrice>
                <div />
                <TokenPrice onClick={() => onTokenPriceClick('token2')}>
                  <div>USD/{token2Symbol} </div>
                  <div className="price">${token2Price}</div>
                </TokenPrice>
              </ExchangeRateWrapper>
            </PageTitle>
          </PageTitleWrapper>
          <ActionBar>
            {!isMobile && (
              <Tooltip title="Move the chart">
                <Button
                  type="link"
                  icon={<IoIosMove />}
                  ref={drag}
                  role="Handle"
                />
              </Tooltip>
            )}
            <Tooltip title="Remove pair">
              <div>
                <RiDeleteBin2Fill onClick={() => removePair(address)} />
              </div>
            </Tooltip>
          </ActionBar>
        </TitleWrapper>
        <ExtraStatsWrapper>
          <ExtraStatsBlock>
            <StatsLabel>
              {token1Symbol} Price{' '}
              <Tag visible={connectionStatus === 'Open'}>Live</Tag>
            </StatsLabel>
            <StatsValue>
              {calculateNumberOfDecimalPlaces(
                data[data.length - 1]?.price,
                token2Symbol
              )}{' '}
              {renderFractionUnitOfETHOrBTC(
                data[data.length - 1]?.price,
                token2Symbol
              )}
            </StatsValue>
          </ExtraStatsBlock>
          <ExtraStatsBlock>
            <StatsLabel>
              Volume <Tag>24h</Tag>
            </StatsLabel>
            <StatsValue>
              {calculateNumberOfDecimalPlaces(volume24hr)} {token2Symbol}
            </StatsValue>
          </ExtraStatsBlock>
          <ExtraStatsBlock>
            <StatsLabel>{token1Symbol} Reserve</StatsLabel>
            <StatsValue>
              {calculateNumberOfDecimalPlaces(data[data.length - 1]?.reserve0)}{' '}
              {token1Symbol}
            </StatsValue>
          </ExtraStatsBlock>
          <ExtraStatsBlock>
            <StatsLabel>{token2Symbol} Reserve</StatsLabel>
            <StatsValue>
              {calculateNumberOfDecimalPlaces(data[data.length - 1]?.reserve1)}{' '}
              {token2Symbol}
            </StatsValue>
          </ExtraStatsBlock>
        </ExtraStatsWrapper>
        <ChartViewTypeContainer>
          <Radio.Group
            value={view}
            onChange={onChartTypeClick}
            buttonStyle="solid"
            disabled={!ready}
          >
            <Radio.Button value={ChartViewTypes.CHART}>
              {isMobile ? <AiOutlineAreaChart /> : 'Chart View'}
            </Radio.Button>
            <Radio.Button value={ChartViewTypes.TRANSACTIONS}>
              {isMobile ? <AiOutlineUnorderedList /> : 'Transactions View'}
            </Radio.Button>
          </Radio.Group>

          {isMobile && view === ChartViewTypes.CHART && (
            <ChartExtraActions>
              <InvertPriceContainer>
                <CheckboxInvert
                  checked={priceInverted}
                  onClick={() => setPriceInverted(!priceInverted)}
                >
                  Invert Price
                </CheckboxInvert>
              </InvertPriceContainer>
              <DownloadCSVButton
                href={`/api/${chain}/price-csv/${address}.csv`}
                onClick={() => message.success('Download has started...')}
                icon={<DownloadOutlined />}
                disabled={!ready}
                type="primary"
                download
              >
                CSV
              </DownloadCSVButton>
            </ChartExtraActions>
          )}
        </ChartViewTypeContainer>
        <ChartSectionContainer $visible={view === ChartViewTypes.CHART}>
          <ChartControlsWrapper>
            <ChartDateControlsWrapper>
              <Radio.Group
                value={chartTimeframe}
                onChange={onControlsClick}
                buttonStyle="solid"
                disabled={!ready}
              >
                <Radio.Button value={ChartTimeFrames.DAY}>1D</Radio.Button>
                <Radio.Button value={ChartTimeFrames.WEEK}>1W</Radio.Button>
                <Radio.Button value={ChartTimeFrames.MONTH}>1M</Radio.Button>
                <Radio.Button value={ChartTimeFrames.THREE_MONTHS}>
                  3M
                </Radio.Button>
                <Radio.Button value={ChartTimeFrames.YEAR}>1Y</Radio.Button>
                <Radio.Button value={ChartTimeFrames.YEAR_TO_DATE}>
                  YTD
                </Radio.Button>
              </Radio.Group>
            </ChartDateControlsWrapper>
            {!isMobile && (
              <ChartExtraActions>
                <InvertPriceContainer>
                  <CheckboxInvert
                    checked={priceInverted}
                    onClick={() => setPriceInverted(!priceInverted)}
                  >
                    Invert Price
                  </CheckboxInvert>
                </InvertPriceContainer>
                <DownloadCSVButton
                  href={`/api/${chain}/price-csv/${address}.csv`}
                  onClick={() => message.success('Download has started...')}
                  icon={<DownloadOutlined />}
                  disabled={!ready}
                  type="primary"
                  download
                >
                  Download CSV
                </DownloadCSVButton>
              </ChartExtraActions>
            )}
          </ChartControlsWrapper>
          <Chart
            data={priceInverted ? dataInverted : data}
            metadata={metadata}
            dataZoom={dataZoom}
            dateAxisLabelFormat={dateAxisLabelFormat}
            priceInverted={priceInverted}
            ready={ready}
            onChartClick={onChartClick}
            onLegendSelect={onLegendSelect}
            selectedLegends={selectedLegends}
            mode={view}
            isMobile={isMobile}
            darkMode={darkMode}
          />
          {!ready && (
            <LoadingOverlay>
              <Loader size="large" />
            </LoadingOverlay>
          )}
        </ChartSectionContainer>
        <SwapTable
          visible={view === ChartViewTypes.TRANSACTIONS}
          token0={metadata[1]}
          token1={metadata[2]}
          data={priceInverted ? dataInverted : data}
          chain={chain}
        />
      </PairContainer>
    </Wrapper>
  );
};

export default PairDetails;
