import { Tooltip } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../context/globalState';
import useWebSocket from '../../hooks/useWebSocket';
import { SupportedChains } from '../../shared/constants';
import { getBlockNumberUrl, openExternalLink } from '../../shared/helpers';
import { LiveBlockWrapper } from './styles';

interface BlockNotificationProps {
  chain: SupportedChains;
}

const BlockNotification: React.FC<BlockNotificationProps> = ({ chain }) => {
  const { lastMessage, connectionStatus } = useWebSocket({ chain });
  const { isMobile } = useContext(GlobalContext);
  const [latestBlock, setLatestBlock] = useState<null | number>(null);
  const [blockUpdated, setBlockUpdated] = useState(false);
  const isLive = connectionStatus === 'Open';

  useEffect(() => {
    if (lastMessage.from && lastMessage.to) {
      setLatestBlock(lastMessage.to);
    }
  }, [lastMessage]);

  useEffect(() => {
    if (latestBlock) {
      setBlockUpdated(true);
      setTimeout(() => {
        setBlockUpdated(false);
      }, 1000);
    }
  }, [latestBlock]);

  useEffect(() => {
    if (!isLive) {
      setLatestBlock(null);
    }
  }, [isLive]);

  const onBlockNumberClick = () => {
    if (latestBlock) {
      const url = getBlockNumberUrl(chain, latestBlock);
      openExternalLink(url);
    }
  };

  if (isMobile) return null;

  const tooltipTitle = () => {
    if (!isLive) return 'Connecting...';
    if (!latestBlock) return 'Fetching most recent block number...';
    return 'The most recent block number on this network. Prices update on every block';
  };

  return (
    <Tooltip title={tooltipTitle()} placement="bottomRight">
      <LiveBlockWrapper
        $isLive={isLive}
        $isUpdated={blockUpdated}
        $hasBlock={!!latestBlock}
        onClick={onBlockNumberClick}
      >
        {latestBlock && <div>{latestBlock}</div>}
        <div />
      </LiveBlockWrapper>
    </Tooltip>
  );
};

export default BlockNotification;
