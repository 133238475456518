import { ReactComponent as CombinationSVG } from '../../../assets/images/SC_Assets_Combination_04.svg';
import {
  SwapDescriptionSubtitle,
  SwapDescriptionTitle,
  SwapDescriptionWrapper,
} from '../styles';

const SwapDescription = () => {
  return (
    <SwapDescriptionWrapper>
      <CombinationSVG />
      <SwapDescriptionTitle>Superswap</SwapDescriptionTitle>
      <SwapDescriptionSubtitle>
        Get the best overall exchange or liquidity source to make a trade with.
      </SwapDescriptionSubtitle>
    </SwapDescriptionWrapper>
  );
};

export default SwapDescription;
