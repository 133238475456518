import React, { useContext, useState } from 'react';
import {
  HeaderAvatar,
  HeaderPreviewActions,
  HeaderPreviewIconsWrapper,
  HeaderPreviewInfo,
  HeaderPreviewWrapper,
  HeaderWrapper,
} from '../styles';
import avatarUrl from '../../../assets/images/avatar.png';
import { GlobalContext } from '../../../context/globalState';
import Button from '../../../components/Button/Button';
import { BiShare } from 'react-icons/bi';
import { Tooltip, message } from 'antd';
import loginWithMetamask from '../../../components/Header/loginWithMetamask';
import {
  ConfirmedIcon,
  CopyIcon,
} from '../../../components/PairDetails/styles';
import { useNavigate } from 'react-router-dom';

interface Props { }

const Header: React.FC<Props> = () => {
  const { user, setUserWalletAddress } = useContext(GlobalContext);
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);
  const navigate = useNavigate();

  const onConnectWallet = async () => {
    if (user?.walletAddress) {
      setUserWalletAddress('');
    } else {
      const address = await loginWithMetamask();

      if (address) {
        setUserWalletAddress(address);
      }
    }
  };

  const onAddressCopyClick = async () => {
    const contractAddress = user?.walletAddress || '';

    await navigator.clipboard.writeText(contractAddress);
    setCopiedToClipboard(true);
  };

  const onShareProfileClick = () => {
    navigator.clipboard.writeText(window.location.href);
    message.success('Profile URL copied to clipboard');
  };

  if (!user?.walletAddress) {
    navigate('/');
    return null;
  }

  return (
    <HeaderWrapper>
      <HeaderPreviewWrapper>
        <HeaderAvatar src={avatarUrl} alt="avatar" />
        <HeaderPreviewInfo>
          <span>
            {user?.walletAddress.slice(0, 6)}...{user?.walletAddress.slice(-4)}
            <Tooltip
              title={copiedToClipboard ? 'Copied!' : 'Copy to clipboard'}
            >
              <HeaderPreviewIconsWrapper>
                {copiedToClipboard ? (
                  <ConfirmedIcon />
                ) : (
                  <CopyIcon onClick={onAddressCopyClick} />
                )}
              </HeaderPreviewIconsWrapper>
            </Tooltip>
          </span>
          <span>$2151.20</span>
          <span>+4% ($79.17)</span>
        </HeaderPreviewInfo>
      </HeaderPreviewWrapper>
      <HeaderPreviewActions>
        <Tooltip title="Share profile">
          <Button size="large" onClick={onShareProfileClick} shape="circle">
            <BiShare />
          </Button>
        </Tooltip>
        <Button size="large" type="default" onClick={onConnectWallet}>
          {user?.walletAddress ? 'Disconnect Wallet' : 'Connect Wallet'}
        </Button>
      </HeaderPreviewActions>
    </HeaderWrapper>
  );
};

export default Header;
