import moment from 'moment';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ReactEChart, { EChartsInstance } from 'echarts-for-react';
import echartTheme from '../../../assets/echartTheme.json';
import echartThemeDark from '../../../assets/echartThemeDark.json';
import theme from '../../../theme';
import {
  chartNumberFormatter,
  currencyFormatter,
} from '../../../shared/helpers';
import useWindowSize from '../../../hooks/useWindowSize';

interface GridProps {
  left: number;
  right: number;
  top: number;
  bottom: number;
}

interface ChartProps {
  data: {
    rawData: any[];
    totalSupply: number;
    marketCap: number;
    symbol: string;
  };
  extraData?: any[];
  ready: boolean;
  isMobile: boolean;
  darkMode: boolean;
  grid?: GridProps;
}

const VolumeDominanceChart: React.FC<ChartProps> = ({
  data,
  ready,
  isMobile,
  darkMode,
  grid: gridProps = {
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
}) => {
  const chartRef = useRef<EChartsInstance>();
  const { width } = useWindowSize();
  const [grid, setGrid] = useState<GridProps>(gridProps);

  useEffect(() => {
    const instance: EChartsInstance = chartRef.current?.getEchartsInstance();

    instance?.resize();
  }, [width]);

  const tooltipFormater = useCallback(
    (params: any) => {
      const { data } = params;

      const timestamp = data[0];
      const value = data[1];

      const date = moment(new Date(timestamp)).format('MMM D');
      const hours = moment(new Date(timestamp)).format('h:mma');
      const symbol = 'USD';

      const valueParsed = currencyFormatter({
        notation: 'compact',
        maximumFractionDigits: 2,
      }).format(value);

      return `
        <div class="custom-tooltip">
          <div class="tooltip-value">${valueParsed} ${symbol ? symbol : ''
        }</div>
          <div class="tooltip-date">${date}</div>
          <div class="tooltip-hours">${hours}</div>
        </div>
      `;
    },
    [data]
  );

  const formattedData = data?.rawData.map((d: any) => {
    const isInverted = data.symbol !== d.token0_symbol;
    const price = isInverted ? d.t0_tgt_price : d.t1_tgt_price;
    const volume = isInverted ? d.t0_tgt_volume : d.t1_tgt_volume;

    return [d.time_stamp * 1000, price * volume];
  });

  const option = {
    grid,
    xAxis: {
      type: 'time',
      min: 'dataMin',
      max: 'dataMax',
      axisLabel: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: false,
      },
      ...(isMobile ? { splitNumber: 3 } : {}),
    },
    yAxis: [
      {
        type: 'value',
        min: 'dataMin',
        max: 'dataMax',
        boundaryGap: false,
        axisLabel: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
          lineStyle: {
            color: theme.colors.gray,
          },
        },
      },
    ],
    dataset: {
      source: formattedData,
      dimensions: [
        {
          name: 'timestamp',
          type: 'time',
        },
        {
          name: 'MarketCap',
          type: 'float',
        },
      ],
    },
    series: [
      {
        name: 'MarketCap',
        type: 'line',
        encode: {
          x: 'timestamp',
          y: 'MarketCap',
        },
        areaStyle: {
          opacity: 0.2,
        },
      },
    ].map((s) => ({
      ...s,
      ...{
        animation: true,
        smooth: false,
        symbolSize: 8,
        showAllSymbol: true,
        itemStyle: {
          borderWidth: 3,
          opacity: 0,
        },
        emphasis: {
          itemStyle: {
            opacity: 1,
          },
        },
        lineStyle: {
          normal: {
            width: 2,
          },
        },
      },
    })),
    tooltip: {
      trigger: 'item',
      formatter: tooltipFormater,
      axisPointer: {
        type: 'cross',
        snap: true,
        label: {
          show: true,
          formatter: function ({
            value,
            axisDimension,
          }: {
            value: number;
            axisDimension: 'x' | 'y';
          }) {
            if (axisDimension === 'y') {
              return chartNumberFormatter(value);
            }
            return moment(new Date(value)).format('MMM D');
          },
          color: darkMode ? theme.colors.black : theme.colors.white,
          backgroundColor: darkMode ? theme.colors.creme : theme.colors.primary,
        },
      },
    },
    textStyle: {
      fontFamily: 'PolySans Neutral',
    },
  };

  return useMemo(
    () => (
      <>
        <ReactEChart
          option={option}
          theme={darkMode ? echartThemeDark : echartTheme}
          style={{
            height: 150,
            padding: 4,
          }}
          ref={chartRef}
          lazyUpdate
        />
      </>
    ),
    [ready, data, darkMode]
  );
};

export default VolumeDominanceChart;
