import React, { useContext, useEffect, useState } from 'react';
import {
  ChartsContainer,
  ContentWrapper,
  SectionWrapper,
  Subtitle,
  Title,
  TitleWrapper,
  Wrapper,
} from './styles';
import { EChartsInstance } from 'echarts-for-react';
import PriceChangeChart from './components/PriceChangeChart';
import { GlobalContext } from '../../context/globalState';
import TokenInput from './components/TokenInput';
import BestPathChart from './components/BestPathChart';
import { Radio } from 'antd';
import SwapDescription from './components/SwapDescription';
import FAQ from './components/FAQ';

export interface PathColorsProps {
  index: number;
  id: number;
  name: string;
  color: string;
}

export interface FetchDataProps {
  token0: string;
  token1: string;
  amount: number;
  address0?: string;
  address1?: string;
  startingBlock?: number;
  endingBlock?: number;
  step?: number;
}

const Superswap: React.FC = () => {
  const { darkMode, isMobile } = useContext(GlobalContext);
  const [data, setData] = useState<null | any[]>(null);
  const [originData, setOriginData] = useState<FetchDataProps | undefined>();
  const [key, setKey] = useState(0);
  const [chartInstance, setChartInstance] = useState<EChartsInstance>();
  const [pathColors, setPathColors] = useState<PathColorsProps[]>([]);
  const [activeTab, setActiveTab] = useState<'bestPath' | 'priceChange'>();

  const [loading, setLoading] = useState(false);
  const [bestPathChartIsReady, setBestPathChartIsReady] = useState(false);

  const dataHasBeenLoaded = !!chartInstance && !!data && !loading;

  const resetSearch = () => {
    setData([]);
    setBestPathChartIsReady(false);
    setChartInstance(undefined);
  };

  const onFetchData = async ({
    token0,
    token1,
    amount,
    address0,
    address1,
    startingBlock,
    endingBlock,
    step,
  }: FetchDataProps) => {
    resetSearch();
    setLoading(true);
    setOriginData({
      token0,
      token1,
      amount,
      address0,
      address1,
      startingBlock,
      endingBlock,
      step,
    });
    setKey((prevKey) => prevKey + 1);
  };

  useEffect(() => {
    if (chartInstance && !loading) {
      const model = chartInstance.getModel();
      const series = model?.getSeries();
      if (!series) return;

      const pathColors = series?.map((s: any) => {
        return {
          index: s.seriesIndex,
          name: s.name,
          color: chartInstance.getVisual(
            { seriesIndex: s.seriesIndex },
            'color'
          ),
        };
      });
      setPathColors(pathColors);
    }
  }, [originData, chartInstance, loading]);

  useEffect(() => {
    const chartsAreRendered = !!chartInstance && !!data && !loading;
    if (chartsAreRendered) {
      document
        .getElementById('charts-container')
        ?.scrollIntoView({ behavior: 'smooth' });

      setActiveTab('bestPath');
    }
  }, [loading, data, chartInstance]);

  return (
    <Wrapper>
      <TitleWrapper>
        <div>
          <Title>Superswap</Title>
          <Subtitle>
            Get the best overall exchange or liquidity source to make a trade
            with.
          </Subtitle>
        </div>
      </TitleWrapper>
      <ContentWrapper>
        <SectionWrapper>
          <TokenInput onFetchData={onFetchData} loading={loading} />
        </SectionWrapper>
        <SectionWrapper>
          <ChartsContainer $visible key={key} id="charts-container">
            {dataHasBeenLoaded ? (
              <Radio.Group
                value={activeTab}
                onChange={(e) => setActiveTab(e.target.value)}
                buttonStyle="solid"
              >
                <Radio.Button value="bestPath">Best Path</Radio.Button>
                <Radio.Button value="priceChange">Price Change</Radio.Button>
              </Radio.Group>
            ) : (
              <SwapDescription />
            )}
            {data && originData && (
              <>
                <BestPathChart
                  originNodes={originData}
                  pathColors={pathColors}
                  setLoading={setLoading}
                  setBestPathChartIsReady={setBestPathChartIsReady}
                  isMobile={isMobile}
                  show={!dataHasBeenLoaded ? true : activeTab === 'bestPath'}
                />
                <PriceChangeChart
                  darkMode={darkMode}
                  originNodes={originData}
                  data={data}
                  setChartInstance={setChartInstance}
                  loading={loading}
                  setLoading={setLoading}
                  isMobile={isMobile}
                  isReady={bestPathChartIsReady}
                  show={!dataHasBeenLoaded ? true : activeTab === 'priceChange'}
                />
              </>
            )}
          </ChartsContainer>
        </SectionWrapper>
      </ContentWrapper>
      <FAQ />
    </Wrapper>
  );
};

export default Superswap;
